import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi, postApi, putApi, stringifyQueryParams } from '../../helpers/api'

function* fetchIcons(action) {
  try {
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/icons.json?${stringifyQueryParams(action.params)}`,
      );

    yield put({ type: actions.GET_ICONS_SUCCESS, icons: data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* listIcons() {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/icons/list.json`,
      );

    yield put({ type: actions.LIST_ICONS_SUCCESS, icons: data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* createIcon(action) {
  try {
    yield call(postApi, `${API.url}/icons`, action.form);
    yield put({ type: actions.GET_ICONS });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error && error.response && error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* editIcon(action) {
  try {
    yield call(putApi, `${API.url}/icons/${action.id}`, action.form);
    yield put({ type: actions.GET_ICONS });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error && error.response && error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ICONS, fetchIcons),
    takeEvery(actions.LIST_ICONS, listIcons),
    takeEvery(actions.CREATE_ICON, createIcon),
    takeEvery(actions.UPDATE_ICON, editIcon),
  ]);
}
