import { all, call, takeEvery, put, select, takeLatest } from 'redux-saga/effects';
import { reset } from 'redux-form';
import actions from './actions';
import { API } from '../../settings';
import {
  fetchApi,
  postApi,
  putApi,
  stringifyQueryParams,
} from '../../helpers/api';
import { notification } from '../../components';

function* fetchSubjects(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/subjects.json?${stringifyQueryParams(action.params)}`,
      {},
      { subject_type: localStorage.getItem('currentMode') },
    );

    yield put({ type: actions.FETCH_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* fetchSubjectsList(action) {
  try {
    const userType = yield select(state => state.session.user.data.type);
    const institutionId = userType === 'externalUser' ?
      yield select(state => state.Ombudsman.ombudsman.id) :
      yield select(state => state.Dashboard.currentInstitution);

    const { data } = yield call(
      fetchApi,
      `${API.url}/resources/institutions/${institutionId}/subjects.json`,
      {},
      { subject_type: localStorage.getItem('currentMode') },
    );

    yield put({ type: actions.FETCH_LIST_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* fetchResources(action) {
  try {
    const { institutionId } = action;

    const sessionInstitutionId = yield select(
      state => state.session.user.data.attributes.institutionId
    );

    const id = institutionId || sessionInstitutionId;

    const { data } = yield call(
      fetchApi,
      `${API.url}/resources/institutions/${id}/sectors.json`,
      {},
      { sector_type: localStorage.getItem('currentMode') }
    );

    yield put({
      type: actions.FETCH_RESOURCES_SUCCESS,
      sectors: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  }
}

function* createSubject(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/subjects/`,
      { subject: action.form },
    );

    notification('success', 'Assunto cadastrado com sucesso');
    yield put(reset('SubjectForm'));
    yield put({ type: actions.CREATE_SUCCESS, data });
  } catch (error) {
    notification('error', `${error.response.data.errors.full_messages}`);
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages,
    });
  }
}

function* updateSubject(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/subjects/${action.form.id}`,
      {subject: action.form}
    );
    notification(
      'success',
      `Assunto ${action.form.name} atualizado com sucesso`
    );
    yield put({ type: actions.UPDATED_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.FETCH_REQUEST, fetchSubjects),
    yield takeEvery(actions.CREATE, createSubject),
    yield takeEvery(actions.UPDATE, updateSubject),
    yield takeEvery(actions.FETCH_RESOURCES, fetchResources),
    yield takeEvery(actions.FETCH_LIST, fetchSubjectsList),
  ]);
}
