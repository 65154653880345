import createMyReducer from '../createMyReducer';
import actions from './actions';

const initState = {
  drafts: [],
  currentDraft: null,
  draftId: null,
  draftLoading: false,
};

const listDrafts = state => ({
  ...state,
  draftLoading: true,
});

const listDraftSuccess = (state, action) => {
  return {
  ...state,
  drafts: action.data,
  draftLoading: false,
  };
}

const fetchDraftSuccess = (state, action) => ({
  ...state,
  currentDraft: action.data,
  draftId: action.uuid,
});

const updateDraft = state => ({
  ...state,
  draftLoading: true,
});

const updateDraftSuccess = state => ({
  ...state,
  draftLoading: false,
});

const removeDraft = state => ({
  ...state,
  draftLoading: true,
});

const removeDraftSuccess = (state, action) => {
  const index = state.drafts.findIndex(el => el.uuid === action.uuid);
  const drafts = state.drafts.splice(index, 1);
  return {
    ...state,
    drafts,
    draftLoading: false,
  };
};

const resetCurrentDraft = state => ({
  ...state,
  currentDraft: null,
  draftId: null,
});

const draftFinally = state => ({
  ...state,
  draftLoading: false,
});

const HANDLERS = {
  [actions.LIST_DRAFTS]: listDrafts,
  [actions.LIST_DRAFTS_SUCCESS]: listDraftSuccess,
  [actions.FETCH_DRAFT_SUCCESS]: fetchDraftSuccess,
  [actions.UPDATE_DRAFT]: updateDraft,
  [actions.UPDATE_DRAFT_SUCCESS]: updateDraftSuccess,
  [actions.REMOVE_DRAFT]: removeDraft,
  [actions.REMOTE_DRAFT_SUCCESS]: removeDraftSuccess,
  [actions.RESET_CURRENT_DRAFT]: resetCurrentDraft,
  [actions.DRAFT_FINALLY]: draftFinally,
};

const draftReducer = createMyReducer(initState, HANDLERS);

export default draftReducer;
