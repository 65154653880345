import React from 'react';
import asyncComponent from '../../helpers/AsyncFunc';
import Route from '../../components/utility/customRoute';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../SimpleDashboard')),
  },
  {
    path: 'admins',
    component: asyncComponent(() => import('../Admin')),
  },
  {
    path: 'admins/:id',
    component: asyncComponent(() => import('../Admin/Show')),
  },
  {
    path: 'notifications',
    component: asyncComponent(() => import('../UserNotification')),
  },
  {
    path: 'backups',
    component: asyncComponent(() => import('../Backup')),
  },
  {
    path: 'cities',
    component: asyncComponent(() => import('../Cities')),
  },
  {
    path: 'branch-activities',
    component: asyncComponent(() => import('../BranchActivity')),
  },
  {
    path: 'neighborhoods',
    component: asyncComponent(() => import('../Neighborhood')),
  },
  {
    path: 'icons',
    component: asyncComponent(() => import('../Icon')),
  },
  {
    path: 'institutions',
    component: asyncComponent(() => import('../Institution')),
  },
  {
    path: 'institutions/:id',
    component: asyncComponent(() => import('../Institution/Show')),
  },
  {
    path: 'storage',
    component: asyncComponent(() => import('../Institution/storage')),
  },
  {
    path: 'sectors',
    component: asyncComponent(() => import('../Sector')),
  },
  {
    path: 'sectors/:id',
    component: asyncComponent(() => import('../Sector/Show')),
  },
  {
    path: 'subjects',
    component: asyncComponent(() => import('../Subject')),
  },
  {
    path: 'categories',
    component: asyncComponent(() => import('../Category')),
  },
  {
    path: 'todo-lists',
    component: asyncComponent(() => import('../UserTodoList')),
  },
  {
    path: 'tickets-sent/',
    component: asyncComponent(() => import('../Ticket')),
  },
  {
    path: 'tickets-received',
    component: asyncComponent(() => import('../TicketsReceived')),
  },
  {
    path: 'tickets-relative',
    component: asyncComponent(() => import('../TicketsRelative')),
  },
  {
    path: 'tickets/:id',
    component: asyncComponent(() => import('../Ticket/Show')),
  },
  {
    path: 'users',
    component: asyncComponent(() => import('../User')),
  },
  {
    path: 'users/:id',
    component: asyncComponent(() => import('../User/Show')),
  },
  {
    path: 'users-ouvidoria',
    component: asyncComponent(() => import('../User/Ouvidoria')),
  },
  {
    path: 'announcements',
    component: asyncComponent(() => import('../Announcement')),
  },
  {
    path: 'notices',
    component: asyncComponent(() => import('../Notice')),
  },
  {
    path: 'notices/:id',
    component: asyncComponent(() => import('../Notice/Show')),
  },
  {
    path: 'announcements/:id',
    component: asyncComponent(() => import('../Announcement/Show')),
  },
  {
    path: 'profile',
    component: asyncComponent(() => import('../Profile')),
  },
  {
    path: 'locker',
    component: asyncComponent(() => import('../Locker')),
  },
];

const AppRouter = ({ url, style }) => {
  localStorage.setItem('currentMode', 'ci_digital');
  return (
    <div style={style}>
      {routes.map(singleRoute => {
        const { path, exact, ...otherProps } = singleRoute;
        return (
          <Route
            exact={exact !== false}
            key={singleRoute.path}
            path={`${url}/${path}`}
            {...otherProps}
          />
        );
      })}
    </div>
  );
};

export default AppRouter;
