import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import {
  createMuiTheme,
  Hidden,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ExpandMore } from '@material-ui/icons';
import {
  IconButtons,
  TopbarDropdown,
  UserInformation,
  SettingsList,
  Icon,
  TopbarDropdownWrapper,
} from './topbarDropdown.style';
import authAction from '../../redux/auth/actions';
import dashboardActions from '../../redux/dashboard/actions';
import Avatar from '../../components/Avatar';
import LimitBar from '../../components/limitbar';
import './style.scss';
import roleOptions from '../../helpers/roleOptions'

const theme = createMuiTheme({
  overrides: {
    MuiModal: {
      root: {
        zIndex: 1800,
      },
    },
    MuiPopover: {
      paper: {
        maxWidth: 350,
      },
    },
  },
});

class TopbarUser extends Component {
  state = {
    visible: false,
    anchorEl: null,
    offset: window.innerWidth,
  };

  hide = () => {
    this.setState({ visible: false });
  };

  handleVisibleChange = () => {
    this.setState({
      visible: !this.state.visible,
      anchorEl: findDOMNode(this.button),
      offset: window.innerWidth - (window.innerWidth * 0.2),
    });
  };

  handleLogout = () => {
    const { logout, clearInstitution } = this.props;
    logout();
    clearInstitution();
  };

  renderLimitBar = () => {
    const { user } = this.props;
    const { attributes: { storageLimit, storageUsed } } = user;
    return (
      <LimitBar
        percentage={((storageUsed / storageLimit) * 100).toFixed(2)}
        label={`${storageUsed}/${storageLimit} GB`}
      />
    );
  }

  render() {
    const { isLoggedIn, user } = this.props;
    const currentMode = localStorage.getItem('currentMode');

    const content = (
      <TopbarDropdown>
        <UserInformation>
          {isLoggedIn && user && (
            <React.Fragment>
              <Hidden lgUp>
                <div className="userImage">
                  <Avatar user={user} />
                </div>
                <div className="userDetails">
                  <h3>{user.attributes.name}</h3>
                  <p>{roleOptions[user.attributes.role]}</p>
                  <p>
                    <strong>Acesso: </strong>
                    {user.attributes.currentSignInAt}
                  </p>
                </div>
              </Hidden>
              <Hidden mdDown>
                <div className="userDetails">
                  <p><strong>{roleOptions[user.attributes.role]}</strong></p>
                  {user.attributes.role !== 'admin' && this.renderLimitBar()}
                </div>
              </Hidden>
            </React.Fragment>
          )}
        </UserInformation>
        <SettingsList>
          <Link
            to={currentMode === 'ombudsman' ? '/ouvidoria/dashboard/profile' : '/dashboard/profile'}
            className="dropdownLink"
          >
            <Icon>settings</Icon>
            Editar perfil
          </Link>
          <Link to="/admin" onClick={this.handleLogout} className="dropdownLink">
            <Icon>input</Icon>
            Sair
          </Link>
        </SettingsList>
      </TopbarDropdown>
    );

    return (
      <div id="topbarUserIcon"
        onClick={this.handleVisibleChange}>
          { user && (
            <Hidden mdDown>
              <div className="topbarUserInfo">
                <span className="topbarUserName">Olá {user.attributes.name}!</span>
                <br />
                <span className="topbarLastLogin">Seu último acesso foi em {user.attributes.lastSignInAt} <ExpandMore /></span>
              </div>
            </Hidden>
          )}
        <IconButtons
          ref={node => {

            this.button = node;
          }}
          onClick={this.handleVisibleChange}
        >
          <div className="userImgWrapper">
            {isLoggedIn && user && (
              <div className="userImage">
                <Avatar user={user} />
              </div>
            )}
          </div>
        </IconButtons>

        <MuiThemeProvider theme={theme}>
          <TopbarDropdownWrapper
            open={this.state.visible}
            anchorEl={this.state.anchorEl}
            onClose={this.hide}
            // marginThreshold={66}
            className="userPopover"
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
          >
            {content}
          </TopbarDropdownWrapper>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    isLoggedIn: state.session.authenticated,
    user: state.session.user.data,
    customizedTheme: state.ThemeSwitcher.topbarTheme,
  }),
  {
    logout: authAction.logout,
    clearInstitution: dashboardActions.clearInstitution,
  },
)(TopbarUser);
