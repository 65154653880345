const actions = {
  GET_SLIDES: 'GET_SLIDES/new-slides',
  GET_SLIDES_SUCCESS: 'GET_SLIDES_SUCCESS/new-slides',
  LIST_SLIDES: 'LIST_SLIDES/new-slides',
  LIST_SLIDES_SUCCESS: 'LIST_SLIDES_SUCCESS/new-slides',
  OPEN_MODAL_NEW: 'OPEN_MODAL_NEW/new-slides',
  CLOSE_MODAL_NEW: 'CLOSE_MODAL_NEW/new-slides',
  CREATE_SLIDES: 'CREATE_SLIDES/new-slides',
  CREATE_SLIDES_SUCCESS: 'CREATE_SLIDES_SUCCESS/new-slides',
  EDIT_SLIDES: 'EDIT_SLIDES/new-slides',
  OPEN_MODAL_EDIT: 'OPEN_MODAL_EDIT/new-slides',
  OPEN_MODAL_EDIT_SUCCESS: 'OPEN_MODAL_EDIT_SUCCESS/new-slides',
  CLOSE_MODAL_EDIT: 'CLOSE_MODAL_EDIT/new-slides',
  EDIT_SLIDES_SUCCESS: 'EDIT_SLIDES_SUCCESS/new-slides',
  UPDATE_SLIDES: 'UPDATE_SLIDES/new-slides',
  SAVE_FILTER: 'SAVE_FILTER/new-slides',
  ERROR: 'ERROR/STATES',
  getSlides: (params) => ({ type: actions.GET_SLIDES, params }),
  listSlides: () => ({ type: actions.LIST_SLIDES }),
  createSlides: form => ({ type: actions.CREATE_SLIDES, form }),
  editSlides: (id, form) => ({ type: actions.UPDATE_SLIDES, id, form }),
  openNewModal: () => ({ type: actions.OPEN_MODAL_NEW }),
  openEditModal: (slide) => ({ type: actions.OPEN_MODAL_EDIT, slide }),
  closeNewModal: () => ({ type: actions.CLOSE_MODAL_NEW }),
  closeEditModal: () => ({ type: actions.CLOSE_MODAL_EDIT }),
  saveFilter: params => ({ type: actions.SAVE_FILTER, params }),
};

export default actions;
