import { createStore, applyMiddleware, compose } from 'redux';
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from 'history';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { sessionReducer } from 'redux-react-session';
import createSagaMiddleware from 'redux-saga';
import { reducer as reduxFormReducer } from 'redux-form';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import logger from 'redux-logger';

import errorInterceptor from './middlewares/errorInterceptor';
import reducers from './reducers';
import rootSaga from './sagas';


const history = createBrowserHistory({ forceRefresh: true });
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [
  sagaMiddleware,
  routeMiddleware,
  errorInterceptor,
  // logger,
];
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['session']
};
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});
let composeEnhancers = compose;

if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
  /* eslint-disable no-underscore-dangle */
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }
  /* eslint-enable */
}

const persistedReducer = persistCombineReducers(persistConfig, {
  ...reducers,
  session: sessionReducer,
  router: routerReducer,
  form: reduxFormReducer,
});


const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
export { store, history, persistor };
