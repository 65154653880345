import axios from 'axios';
import qs from 'qs';
import { extractHeaders } from './request';

const cancelTokenSource = axios.CancelToken.source();

export const fetchApi = (url, params, headers = {}) =>
  new Promise((resolve, reject) => {
    axios
      .get(url, {
        params,
        cancelToken: cancelTokenSource.token,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
      })
      .then(response => {
        const { data, included } = response.data;
        const {
          currentPage,
          totalItems,
          itemsPerPage,
          nextPage,
          pages,
        } = extractHeaders(response);
        resolve({
          data,
          included,
          pagination: {
            currentPage,
            totalItems,
            itemsPerPage,
            nextPage,
            pages,
          },
        });
      })
      .catch(error => {
        cancelTokenSource.cancel();
        reject(error);
      });
  });

export const postApi = (url, data, onProgress, headers) =>
  new Promise((resolve, reject) =>
    axios
      .post(url, data, {
        headers,
        maxContentLength: Infinity,
        onUploadProgress: onProgress,
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error)));

export const putApi = (url, params) =>
  new Promise((resolve, reject) =>
    axios
      .put(url, params)
      .then(response => resolve(response.data))
      .catch(error => reject(error)));

export const downloadApi = url => new Promise((resolve, reject) =>
  axios
    .get(url, {
      responseType: 'blob',
    })
    .then(response => resolve(new Blob([response.data])))
    .catch(error => reject(error)))

export const deleteApi = url => new Promise((resolve, reject) =>
  axios.delete(url).then(resolve(true)).catch(error => reject(error)));

export const stringifyQueryParams = params => {
  if (params === undefined || Object.keys(params).length === 0) return '';

  const newParams = {};
  newParams.q = {};

  Object.keys(params).forEach(key => {
    if (['per_page', 'page'].includes(key)) {
      newParams[key] = params[key];
    } else {
      newParams.q[key] = params[key];
    }
  });

  return qs.stringify(newParams);
};


export const fetchLocalMapBox = (local) =>
new Promise((resolve, reject) => {
  axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${local}.json?`, {
      params: {
        access_token: process.env.REACT_APP_MAP_API_KEY,
      },
    })
  // .then(response => response.json())
  .then(({ data }) => resolve(data))
  .catch(error => reject(error));
});
