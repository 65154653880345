import { strip, isValid as isValidCpf } from "@fnando/cpf";
import { isValid as isValidCnpj } from "@fnando/cnpj";

export const required = value => (value ? undefined : 'Campo obrigatório')

export const passwordsMatch = (value, allValues) =>
  value !== allValues.password ? 'As senhas não correspondem' : undefined;

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'E-mail inválido'
    : undefined

export const minLength = min => value =>
  value && value.length < min ? `Devemos ter no mínimo ${min} caracteres ou mais` : undefined

export const maxLength = max => value =>
  (value && value.length > max) ? `Tamanho máximo de ${max} caracteres excedido` : undefined;

export const minValue = min => value =>
  value && value < min ? `Número deve ser maior/igual a ${min}` : undefined
export const minValueZero = minValue(0);
export const minValueOne = minValue(1);

export const phoneNumber = value =>
  value &&
  !/^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/i.test(value)
    ? 'Número de telefone inválido'
    : undefined

export const phone = value =>
  value &&
  !/^\(\d{2}\)\s\d{4}-\d{4}$/i.test(value)
    ? 'Número de telefone inválido'
    : undefined

export const number = value =>
  // eslint-disable-next-line no-restricted-globals
  value && isNaN(Number(value)) ? 'Deve ser um número' : undefined

export const passwordsMustMatch = (value, allValues) =>
  value !== allValues.password
  ? 'As senhas não coincidem'
  : undefined

export const validateCpf = (value) => {
  return value && isValidCpf(value)
    ? undefined
    : 'Número de CPF inválido';
};

export const validateCnpj = value => value && isValidCnpj(value)
  ? undefined
  : 'Número de CNPJ inválido';

export const maskCpfCnpj = document =>{
  const text = document.length === 11
  ? document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
  : document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
  return text;
}

export const validateDocument = (value) => {
  if (!value) return;

  const size = strip(value).length;
  return size <= 11 ? validateCpf(value) : validateCnpj(value);
};

export const validateUrl = value => {
  if (!value) return undefined;
  return value && value.match(/^(http(s)?:\/\/)/) ? undefined : "Padrão de url inválido."
}
