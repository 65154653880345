import { all, call, takeEvery, put, select } from 'redux-saga/effects';
import { reset } from 'redux-form';
import actions from './actions';
import { API } from '../../settings';
import {
  fetchApi,
  postApi,
  putApi,
  stringifyQueryParams,
} from '../../helpers/api';
import { notification } from '../../components';


const defaultMessages = {
  400: 'Há erros no formulário',
  404: 'Não encontrado',
  500: 'Erro interno. Tente novamente mais tarde',
  403: 'Você não possui acesso',
};

function* fetchUsers(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/users.json?${stringifyQueryParams(action.params)}`
    );

    yield put({ type: actions.FETCH_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response && error.response.status,
      errors: (error.response && error.response.data && error.response.data
        .errors) || defaultMessages[error.response.status],
    });
  }
}

function* fetchAdmins(action) {
  try {
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/admins?${stringifyQueryParams(action.params)}`,
    );

    yield put({ type: actions.FETCH_ADMINS_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response && error.response.status,
      errors: (error.response && error.response.data && error.response.data
        .errors) || defaultMessages[error.response.status],
    });
  }
}

function* getUser(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data, included } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/users/${action.id}`
    );

    yield put({ type: actions.GET_SUCCESS, data, included });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      errorstatus: error.response && error.response.status,
      errors: (error.response && error.response.data && error.response.data
        .error) || defaultMessages[error.response.status],
    });
  }
}

function* sendConfirmation(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data: { confirmed } } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/users/${action.id}/confirmation_instructions`
      );
    const message = confirmed ? 'E-mail já confirmado' : 'E-mail de confirmação enviado';
    notification('success', message);
  } catch (error) {
    yield put({
      type: actions.ERROR,
      errorstatus: error.response && error.response.status,
      errors: defaultMessages[error.response.status],
    });
  }
}


function* createUser(action) {
  try {
    const { institutionId, form } = action
    const { data } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/users/`,
      form,
    );

    notification('success', 'Usuário cadastrado com sucesso');
    yield put(reset('UserForm'));
    yield put({ type: actions.CREATE_SUCCESS, data });
  } catch (error) {
    const { response: { data, status } } = error;
    const errors = (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorstatus: error.response && error.response.status,
      errors,
    });
  }
}

function* createAdmin(action) {
  try {
    const { form } = action
    const { data } = yield call(
      postApi,
      `${API.url}/admins`,
      form,
    );

    notification('success', 'Administrador cadastrado com sucesso');
    yield put(reset('AdminForm'));
    yield put({ type: actions.CREATE_SUCCESS, data });
  } catch (error) {
    const { response: { data, status } } = error;
    const errors = (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorstatus: error.response && error.response.status,
      errors,
    });
  }
}

function* updateUser(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data, included } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/users/${action.id}`,
      action.form
    );
    notification('success', `Usuário atualizado com sucesso`);
    yield put(reset('UserForm'));
    yield put({ type: actions.GET, id: action.id });
    // yield put({ type: actions.UPDATED_SUCCESS, data });
  } catch (error) {
    const { response: { data, status } } = error;
    const errors = (data.errors && data.errors.full_messages) || defaultMessages[status];

    yield put({
      type: actions.ERROR,
      errorstatus: error.response && error.response.status,
      errors,
    });
  }
}

function* updateAdmin(action) {
  try {
    const { data } = yield call(
      putApi,
      `${API.url}/admins/${action.id}`,
      action.form
    );
    notification('success', `Administrador atualizado com sucesso`);
    yield put({ type: actions.UPDATED_SUCCESS, data });
  } catch (error) {
    const { response: { data, status } } = error;
    const errors = (data.errors && data.errors.full_messages) || defaultMessages[status];

    yield put({
      type: actions.ERROR,
      errorstatus: error.response && error.response.status,
      errors,
    });
  }
}

function* activateUser(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data, included } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/users/${action.id}/activate`,
      action.form
    );
    notification('success', `Usuário ativado com sucesso`);
    yield put({ type: actions.ACTIVATE_SUCCESS, data, included });
  } catch (error) {
    const { response: { data, status } } = error;
    const errors = (data.errors && data.errors.full_messages) || defaultMessages[status];

    yield put({
      type: actions.ERROR,
      errorstatus: error.response && error.response.status,
      errors,
    });
  }
}

function* changeEmail(action) {
  try {
    yield call(
      fetchApi,
      `${API.url}/confirm-email?token=${action.token}`,
    );
    notification('success', 'E-mail alterado com sucesso');
  } catch (error) {
    const message = error.response.status === 404
      ? 'Token não encontrado'
      : 'Houve um erro. Tente novamente mais tarde';
    
    notification('error', message);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.FETCH_REQUEST, fetchUsers),
    yield takeEvery(actions.FETCH_ADMINS_REQUEST, fetchAdmins),
    yield takeEvery(actions.GET, getUser),
    yield takeEvery(actions.CREATE, createUser),
    yield takeEvery(actions.CREATE_ADMIN, createAdmin),
    yield takeEvery(actions.UPDATE, updateUser),
    yield takeEvery(actions.UPDATE_ADMIN, updateAdmin),
    yield takeEvery(actions.ACTIVATE, activateUser),
    yield takeEvery(actions.CHANGE_EMAIL_REQUEST, changeEmail),
    yield takeEvery(actions.SEND_INSTRUCTIONS, sendConfirmation),
  ]);
}
