import React, { useState } from 'react'
import InputMask from "react-input-mask";
import {
  FormControl,
  FormHelperText,
  InputLabel,
} from '../../uielements/form'
import TextField from '../../uielements/textfield';
import { FullColumn, Row } from '../../utility/rowColumn';

const NumberMaskField = ({
  input,
  label,

  meta: { touched, error },
  ...custom
}) => {
  // eslint-disable-next-line no-unused-vars
  const [mask, setActiveMask] = useState('(99) 99999-9999');

  return (
    <Row>
      <FullColumn xs={12} lg={12}>
        <FormControl value={input.value}>
          <InputMask
            mask={mask}
            label={label}
            {...input}
            {...custom}
          >
            <TextField />
          </InputMask>
          {touched && error ? <FormHelperText>{error}</FormHelperText> : ''}
        </FormControl>
      </FullColumn>
    </Row>
  );
};

export default NumberMaskField;
