import { PURGE } from "redux-persist";
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initForm = {
  id: null,
  title: '',
  description: '',
  kind: 'trade',
  status: 'pending',
  expires_at: '',
  internal: false,
  customer_name: '',
  customer_identification: '',
  send_sector_users: false,
}

const initReplyForm = {
  id: null,
  message: '',
  internal: false,
}

const filterForm = {
  title_cont: '',
  status_eq: undefined,
};

const initForwardForm = {
  id: null,
  email: '',
  subject: '',
  comment: '',
  send_replies: false,
}

const initState = {
  tickets: [],
  categories: [],
  sectors: [],
  subjects: [],
  users: [],
  institutions: [],
  formValues: initForm,
  replyFormValues: initReplyForm,
  filterFormValues: filterForm,
  forwardFormValues: initForwardForm,
  current: {},
  editable: {},
  loadingResources: false,
  loading: true,
  loadingModal: false,
  modalNew: false,
  modalEdit: false,
  modalFilter: false,
  modalComplete: false,
  replyModal: false,
  forwardModal: false,
  enableEdit: false,
  errorStatus: null,
  errors: null,
};

const fetchInstitutions = state => ({
  ...state,
  loadingModal: true,
});

const fetchInstitutionsSuccess = (state, action) => ({
  ...state,
  institutions: action.data,
  loadingModal: false
});

const fetchRequest = state => ({
  ...state,
  loading: true,
});

const fetchSuccess = (state, action) => ({
  ...state,
  tickets: action.data,
  pagination: action.pagination,
  loading: false,
});

const openEditModal = state => ({
  ...state,
  loading: true,
  formValues: {
    sector_id: state.current.included.find(
      sector =>
        sector.type === 'sector' &&
        sector.attributes.name === state.current.attributes.sector
    ).attributes.id,
    responsible_id: state.current.included.find(
      user =>
        user.type === 'user' &&
        user.attributes.name === state.current.attributes.responsible
    ).attributes.id,
  },
  modalEdit: true,
});

const fetchError = (state, action) => ({
  ...state,
  loading: false,
  sending: false,
  errors: action.errors,
});

const toogleModalEdit = state => ({
  ...state,
  modalEdit: false,
  formValues: initForm,
});

const transferSuccess = (state, action) => ({
  ...state,
  current: {
    ...action.data,
    included: action.included,
  },
  modalEdit: false,
});

const setTicket = state => ({
  ...state,
  loading: true,
});

const setTicketSuccess = (state, action) => ({
  ...state,
  current: {
    ...action.data,
    included: action.included,
  },
  loading: false,
});

const toogleModalNew = state => ({
  ...state,
  modalNew: !state.modalNew,
});

const createTicket = state => ({
  ...state,
  sending: true,
});

const createSuccess = (state, action) => ({
  ...state,
  tickets: [action.data, ...state.tickets],
  formValues: initForm,
  modalNew: false,
  sending: false,
});

const toogleReplyModal = state => ({
  ...state,
  replyModal: !state.replyModal,
});

const openReplyModal = state => ({
  ...state,
  replyModal: true,
});

const createReply = state => ({
  ...state,
  loadingModal: true,
  sending: true,
});

const createReplySuccess = (state, action) => ({
  ...state,
  current: {
    ...state.current,
    attributes: {
      ...state.current.attributes,
      replies: {
        data: [...state.current.attributes.replies.data, action.data],
        included: [
          ...action.included,
          ...state.current.attributes.replies.included,
        ],
      },
    },
  },
  loadingModal: false,
  replyModal: false,
  sending: false,
});

const forwardTicket = state => ({
  ...state,
  loadingModal: true,
});

const forwardTicketSuccess = state => ({
  ...state,
  loadingModal: false,
  forwardModal: false,
});

const toogleForwardModal = state => ({
  ...state,
  forwardModal: !state.forwardModal,
});

const openForwardModal = state => ({
  ...state,
  forwardModal: true,
});

const changeStatusTicket = state => ({
  ...state,
  loadingModal: false,
});

const changeStatusTicketSuccess = (state, action) => ({
  ...state,
  current: {
    ...action.data,
    included: action.included,
  },
  loadingModal: false,
  modalStart: false,
  modalFinish: false,
});

const toogleModalFilter = state => ({
  ...state,
  modalFilter: !state.modalFilter,
});

const saveFilterForm = (state, action) => ({
  ...state,
  filterFormValues: action.params,
});

const openModalFilter = state => ({
  ...state,
  modalFilter: true,
});

const toogleModalStart = state => ({
  ...state,
  modalStart: !state.modalStart,
});

const fetchResources = state => ({
  ...state,
  loadingModal: true,
});

const fetchResourcesSuccess = (state, action) => ({
  ...state,
  subjects: action.subjects,
  categories: action.categories,
  sectors: action.sectors,
  loadingModal: false,
});

const actionError = (state, action) => ({
  ...state,
  loading: false,
  sending: false,
  errorStatus: action.errorStatus,
  errors: action.errors,
});

const fetchUsersBySector = (state, action) => ({
  ...state,
  users: action.data
});

const purgeState = () => initState;

const HANDLERS = {
  [actions.FETCH_INSTITUTIONS]: fetchInstitutions,
  [actions.FETCH_INSTITUTIONS_SUCCESS]: fetchInstitutionsSuccess,
  [actions.FETCH_REQUEST]: fetchRequest,
  [actions.FETCH_SUCCESS]: fetchSuccess,
  [actions.SET]: setTicket,
  [actions.SET_SUCCESS]: setTicketSuccess,
  [actions.TOGGLE_MODAL_NEW]: toogleModalNew,
  [actions.CREATE]: createTicket,
  [actions.CREATE_SUCCESS]: createSuccess,
  [actions.TOGGLE_EDIT_MODAL]: toogleModalEdit,
  [actions.OPEN_EDIT_MODAL]: openEditModal,
  [actions.TRANSFER_SUCCESS]: transferSuccess,
  [actions.TOGGLE_REPLY_MODAL]: toogleReplyModal,
  [actions.OPEN_REPLY_MODAL]: openReplyModal,
  [actions.CREATE_REPLY]: createReply,
  [actions.CREATE_REPLY_SUCCESS]: createReplySuccess,
  [actions.FORWARD_TICKET]: forwardTicket,
  [actions.FORWARD_TICKET_SUCCESS]: forwardTicketSuccess,
  [actions.TOGGLE_FORWARD_MODAL]: toogleForwardModal,
  [actions.OPEN_FORWARD_MODAL]: openForwardModal,
  [actions.CHANGE_STATUS_TICKET_SUCCESS]: changeStatusTicketSuccess,
  [actions.CHANGE_STATUS_TICKET]: changeStatusTicket,
  [actions.SAVE_FILTER_FORM]: saveFilterForm,
  [actions.TOGGLE_MODAL_FILTER]: toogleModalFilter,
  [actions.OPEN_MODAL_FILTER]: openModalFilter,
  [actions.TOGGLE_MODAL_START]: toogleModalStart,
  [actions.FETCH_RESOURCES_SUCCESS]: fetchResourcesSuccess,
  [actions.FETCH_RESOURCES]: fetchResources,
  [actions.FETCH_USERS_BY_SECTION_SUCCESS]: fetchUsersBySector,
  [actions.FETCH_ERROR]: fetchError,
  [actions.ERROR]: actionError,
  [PURGE]: purgeState,
}

const tradeRecucer = createMyReducer(initState, HANDLERS)

export default tradeRecucer;
