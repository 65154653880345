import { PURGE } from "redux-persist";
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initForm = {
  id: null,
  name: '',
  email: null,
  role: null,
  documentNumber: '',
  institution_id: null,
  status: false,
  backupAllowed: false,
  announcementByInstitution: false,
  announcementBySector: false,
  photo: null,
  document: null,
  sector_ids: [],
};

const initFilter = {};

const initState = {
  users: [],
  formValues: initForm,
  filterFormValues: initFilter,
  current: {},
  editable: {},
  pagination: {
    itemsPerPage: 10,
  },
  sending: false,
  loading: true,
  modalNew: false,
  modalEdit: false,
  modalFilter: false,
  enableEdit: false,
  errorStatus: null,
  errors: null,
};

const fetchRequest = state => ({
  ...state,
  loading: true,
  errorStatus: null,
});

const fetchSuccess = (state, action) => ({
  ...state,
  users: action.data,
  pagination: action.pagination,
  loading: false,
});

const setUser = (state, action) => {
  return {
    ...state,
    editable: {
      ...action.user.attributes,
      role: action.user.attributes.role,
      cidigital: action.user.attributes.modules.includes('ci_digital'),
      ombudsman: action.user.attributes.modules.includes('ombudsman'),
      report: action.user.attributes.modules.includes('report'),
      announcementByInstitution: action.user.attributes.announcementByInstitution || false,
      announcementBySector: action.user.attributes.announcementBySector || false,
      ombudsmanPermission: { value: action.user.attributes.ombudsmanPermission },
      sector_ids: action.user.attributes.sectorsIds.map((id, index) => ({
        value: parseInt(id, 10),
        label: action.user.attributes.sectorsName[index],
      })),
      ombudsman_sector_ids: action.user.attributes.ombudsmanSectorIds.map((id, index) => ({
        value: parseInt(id, 10),
        label: action.user.attributes.ombudsmanSectors[index],
      })),
    },
    modalEdit: true,
  }
};

const getUser = state => {
  return {
    ...state,
    current: {},
    loading: true,
    errorStatus: null,
  }
}

const getSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    sending: false,
    current: {
      ...action.data,
      included: action.included,
    },
  }
}

const fetchAdminsRequest = state => ({
  ...state,
  loading: true,
  errorStatus: null,
});

const fetchAdminsSuccess = (state, action) => ({
  ...state,
  users: action.data,
  pagination: action.pagination,
  loading: false,
});

const clearUsers = state => ({
  ...state,
  users: [],
  pagination: initState.pagination,
});

const toogleModalNew = state => ({
  ...state,
  modalNew: !state.modalNew,
});

const toogleModalEdit = state => ({
  ...state,
  modalEdit: !state.modalEdit,
});

const createUser = state => ({
  ...state,
  sending: true,
});

const createUserSuccess = (state, action) => ({
  ...state,
  users: [action.data, ...state.users],
  formValues: initForm,
  modalNew: false,
  sending: false,
});

const updateUser = state => ({
  ...state,
  sending: true,
});

const updateUserSuccess = (state, action) => ({
  ...state,
  users: state.users.map(user =>
    user.id === action.data.id ? { ...action.data } : user
  ),
  sending: false,
});

const toggleModalFilter = state => ({
  ...state,
  modalFilter: !state.modalFilter,
});

const saveFilterForm = (state, action) => ({
  ...state,
  filterFormValues: action.params,
});

const activateSuccess = (state, action) => ({
  ...state,
  current: {
    ...action.data,
    included: action.included,
  },
});

const fetchError = (state, action) => ({
  ...state,
  loading: false,
  sending: false,
  errorStatus: action.errorStatus,
  errors: action.errors,
});

const purgeState = () => initState;

const HANDLERS = {
  [actions.FETCH_REQUEST]: fetchRequest,
  [actions.FETCH_ADMINS_REQUEST]: fetchAdminsRequest,
  [actions.FETCH_ADMINS_SUCCESS]: fetchAdminsSuccess,
  [actions.FETCH_SUCCESS]: fetchSuccess,
  [actions.SET]: setUser,
  [actions.GET]: getUser,
  [actions.GET_SUCCESS]: getSuccess,
  [actions.TOGGLE_MODAL_NEW]: toogleModalNew,
  [actions.TOGGLE_MODAL_EDIT]: toogleModalEdit,
  [actions.CREATE]: createUser,
  [actions.CREATE_ADMIN]: createUser,
  [actions.CREATE_SUCCESS]: createUserSuccess,
  [actions.UPDATE]: updateUser,
  [actions.UPDATE_ADMIN]: updateUser,
  [actions.UPDATED_SUCCESS]: updateUserSuccess,
  [actions.CLEAR_USERS]: clearUsers,
  [actions.TOGGLE_MODAL_FILTER]: toggleModalFilter,
  [actions.SAVE_FILTER_FORM]: saveFilterForm,
  [actions.ACTIVATE_SUCCESS]: activateSuccess,
  [actions.ERROR]: fetchError,
  [PURGE]: purgeState,
};

const userRecucer = createMyReducer(initState, HANDLERS);

export default userRecucer;
