import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi, postApi, putApi, downloadApi, stringifyQueryParams } from '../../helpers/api'

function* fetchSlides(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );

    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/slides.json?${stringifyQueryParams(action.params)}`,
      );

    yield put({ type: actions.GET_SLIDES_SUCCESS, slides: data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* listSlides() {
  try {
    const institutionId = yield select(
      state => state.Ombudsman.ombudsman.id
    );

    const { data } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/slides/list.json`,
      );

    yield put({ type: actions.LIST_SLIDES_SUCCESS, slides: data });
  } catch (error) {

    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* createSlides(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );

    yield call(postApi, `${API.url}/institutions/${institutionId}/slides`, action.form);
    yield put({ type: actions.GET_SLIDES });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error && error.response && error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* editSlides(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );

    yield call(putApi, `${API.url}/institutions/${institutionId}/slides/${action.id}`, action.form);
    yield put({ type: actions.GET_SLIDES });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error && error.response && error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* downloadSlide(action) {
  try {
    yield put({ type: actions.OPEN_MODAL_EDIT_SUCCESS, slide: action.slide});
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error && error.response && error.response.status,
      error_message: "Não foi possível baixar a imagem.",
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SLIDES, fetchSlides),
    takeEvery(actions.LIST_SLIDES, listSlides),
    takeEvery(actions.CREATE_SLIDES, createSlides),
    takeEvery(actions.UPDATE_SLIDES, editSlides),
    takeEvery(actions.OPEN_MODAL_EDIT, downloadSlide),
  ]);
}

