const actions = {
  GET_OMBUDSMAN: 'GET_OMBUDSMAN/ombudsman',
  GET_OMBUDSMAN_SUCCESS: 'GET_OMBUDSMAN_SUCCESS/ombudsman',
  GET_SECTORS: 'GET_SECTORS/ombudsman',
  GET_SECTORS_SUCCESS: 'GET_SECTORS_SUCCESS/ombudsman',
  GET_GROUPINGS: 'GET_GROUPINGS/ombudsman',
  GET_GROUPINGS_SUCCESS: 'GET_GROUPINGS_SUCCESS/ombudsman',
  GET_CATEGORIES: 'GET_CATEGORIES/ombudsman',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS/ombudsman',
  GET_SUBJECTS: 'GET_SUBJECTS/ombudsman',
  GET_GRAPHS: 'GET_GRAPHS/ombudsman',
  GET_GRAPHS_SUCCESS: 'GET_GRAPHS_SUCCESS/ombudsman',
  GET_SUBJECTS_SUCCESS: 'GET_SUBJECTS_SUCCESS/ombudsman',
  GET_SATISFACTION_SURVEY: 'GET_SATISFACTION_SURVEY/ombudsman',
  GET_SATISFACTION_SURVEY_SUCCESS: 'GET_SATISFACTION_SURVEY_SUCCESS/ombudsman',
  TOGGLE_GRAPH_MODAL: 'TOGGLE_GRAPH_MODAL/ombudsman',
  TOGGLE_LAW_MODAL: 'TOGGLE_LAW_MODAL/ombudsman',
  TOGGLE_MODAL_PRIVACITY_TERM: 'TOGGLE_MODAL_PRIVACITY_TERM/ombudsman',
  TOGGLE_MODAL_INFOS: 'TOGGLE_MODAL_INFOS/ombudsman',
  TOGGLE_MODAL_LOGIN: 'TOGGLE_MODAL_LOGIN/ombudsman',
  CREATE_EXTERNAL_USER: 'CREATE_EXTERNAL_USER/ombudsman',
  CREATE_EXTERNAL_USER_SUCCESS: 'CREATE_EXTERNAL_USER_SUCCESS/ombudsman',
  ERROR: 'ERROR/ombudsman',
  getOmbudsman: slug => ({ type: actions.GET_OMBUDSMAN, slug }),
  getGroupings: id => ({ type: actions.GET_GROUPINGS, id }),
  getSectors: id => ({ type: actions.GET_SECTORS, id }),
  getCategories: () => ({ type: actions.GET_CATEGORIES }),
  getSubjects: id => ({ type: actions.GET_SUBJECTS, id }),
  getGraphs: slug => ({ type: actions.GET_GRAPHS, slug }),
  toggleGraphModal: () => ({ type: actions.TOGGLE_GRAPH_MODAL }),
  toggleLawModal: () => ({ type: actions.TOGGLE_LAW_MODAL }),
  toggleModalPrivacityTerm: () => ({ type: actions.TOGGLE_MODAL_PRIVACITY_TERM }),
  toggleModalInfos: () => ({ type: actions.TOGGLE_MODAL_INFOS }),
  toggleModalLogin: () => ({ type: actions.TOGGLE_MODAL_LOGIN }),
  createExternalUser: form => ({ type: actions.CREATE_EXTERNAL_USER, form }),
  getSatisfactionSurvey: id => ({ type: actions.GET_SATISFACTION_SURVEY, id }),
};

export default actions;
