const actions = {
    FETCH_FOLDERS: 'FETCH_FOLDERS',
    FETCH_FOLDERS_SUCCESS: 'FETCH_SUCCESS',
    GET_FOLDER: 'GET_FOLDER',
    ERROR_FOLDER: 'ERROR_FOLDER',
    SET_CURRENT_FOLDER_ID: 'SET_CURRENT_FOLDER_ID',
    getAllFolders: () => ({ type: actions.FETCH_FOLDERS }),
    getFolder: id => ({ type: actions.GET_FOLDER, id }),
    setCurrentFolderId: folderId => ({ type: actions.SET_CURRENT_FOLDER_ID, folderId }),
  };
  
  export default actions;
  