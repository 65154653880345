const actions = {
  GET_BRANCH_ACTIVITIES: 'GET_BRANCH_ACTIVITIES/branchActivities',
  GET_BRANCH_ACTIVITIES_SUCCESS: 'GET_BRANCH_ACTIVITIES_SUCCESS/branchActivities',
  GET_BRANCH_ACTIVITIES_LIST: 'GET_BRANCH_ACTIVITIES_LIST/branchActivities',
  GET_BRANCH_ACTIVITIES_LIST_SUCCESS: 'GET_BRANCH_ACTIVITIES_LIST_SUCCESS/branchActivities',
  OPEN_MODAL_NEW: 'OPEN_MODAL_NEW/branchActivities',
  CLOSE_MODAL_NEW: 'CLOSE_MODAL_NEW/branchActivities',
  CREATE_BRANCH_ACTIVITY: 'CREATE_BRANCH_ACTIVITY/branchActivities',
  CREATE_BRANCH_ACTIVITY_SUCCESS: 'CREATE_BRANCH_ACTIVITY_SUCCESS/branchActivities',
  EDIT_BRANCH_ACTIVITY: 'EDIT_BRANCH_ACTIVITY/branchActivities',
  OPEN_MODAL_EDIT: 'OPEN_MODAL_EDIT/branchActivities',
  CLOSE_MODAL_EDIT: 'CLOSE_MODAL_EDIT/branchActivities',
  EDIT_BRANCH_ACTIVITY_SUCCESS: 'EDIT_BRANCH_ACTIVITY_SUCCESS/branchActivities',
  UPDATE_BRANCH_ACTIVITY: 'UPDATE_BRANCH_ACTIVITY/branchActivities',
  SAVE_FILTER: 'SAVE_FILTER/branchActivities',
  ERROR: 'ERROR/STATES',
  getBranchActivities: (params) => ({ type: actions.GET_BRANCH_ACTIVITIES, params }),
  getBranchActivitiesList: () => ({ type: actions.GET_BRANCH_ACTIVITIES_LIST }),
  createBranchActivity: form => ({ type: actions.CREATE_BRANCH_ACTIVITY, form }),
  editBranchActivity: (id, form) => ({ type: actions.UPDATE_BRANCH_ACTIVITY, id, form }),
  openNewModal: () => ({ type: actions.OPEN_MODAL_NEW }),
  openEditModal: (branchActivity) => ({ type: actions.OPEN_MODAL_EDIT, branchActivity }),
  closeNewModal: () => ({ type: actions.CLOSE_MODAL_NEW }),
  closeEditModal: () => ({ type: actions.CLOSE_MODAL_EDIT }),
  saveFilter: params => ({ type: actions.SAVE_FILTER, params }),
};

export default actions;
