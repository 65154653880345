import React, { Component } from 'react';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import Button from '../../../../components/uielements/button';

import { FullColumn, Row } from '../../../../components/utility/rowColumn';
import RenderSelectFieldWithSearch from '../../../../components/renderform/selectFieldWithSearch';
import RenderTextField from '../../../../components/renderform/textField';
import RenderDropZone from '../../../../components/renderform/RenderDropZone';
import RenderSwitchField from '../../../../components/renderform/switchField/index';

import MapForm from './mapForm';

import { required } from '../../../../helpers/validate';

import ombudsmanActions from '../../../../redux/ombudsman/actions';
import protocolActions from '../../../../redux/protocol/actions';
import authActions from '../../../../redux/auth/actions';
import addressActions from '../../../../redux/address/actions';

import '../../../UserDashboard/style.scss';

class CreateProtocol extends Component {
  componentDidMount() {
    const {
      getGroupings,
      getSectors,
      ombudsman,
      getCategories,
      getStates,
      getCities,
      getNeighborhoods,
      listAddressesExternalUsers,
      current,
      institution,
    } = this.props;

    const { showGrouping } = institution.attributes;

    if (showGrouping) {
      getGroupings();
    } else {
      getSectors();
    }
    
    getCategories();
    listAddressesExternalUsers(current);
    
    if (institution.attributes.coverage === 'national') {
      getStates();
    }

    if (institution.attributes.coverage === 'estadual') {
      getCities(institution.attributes.stateId);
    }

    if (institution.attributes.coverage === 'regional') {
      getNeighborhoods(institution.attributes.cityId);
    }
  }

  formattedGroupings = () => {
    const { ombudsman: { groupings } } = this.props;
    return groupings.map(({ attributes }) => ({
      label: attributes.name,
      value: String(attributes.id),
    }));
  }

  formattedSectors = () => {
    const { ombudsman: { sectors } } = this.props;

    return sectors.map(({ attributes }) => ({
      label: attributes.name,
      value: String(attributes.id),
    }));
  }

  formattedCategories = () => {
    const { ombudsman: { categories } } = this.props;

    return categories.map(({ attributes }) => ({
      label: attributes.name,
      value: String(attributes.id),
    }));
  }

  formattedSubjects = () => {
    const { ombudsman: { subjects } } = this.props;
    
    return subjects.map(({ attributes }) => ({
      label: attributes.name,
      value: String(attributes.id),
    }));
  }

  render() {
    const {
      getSubjects,
      getCities,
      getNeighborhoods,
      states = [],
      cities = [],
      neighborhoods = [],
      addressesListExternalUsers,
      currentProtocol,
      formState,
      onClose,
      sending,
      handleSubmit,
      institution,
      getSectors,
    } = this.props;

    const responseTypes = [
      { value: 'phone', label: 'Telefone', icon: 'phone' },
      { value: 'email', label: 'E-mail', icon: 'alternate_email' },
      // { value: 'mail', label: 'Carta', icon: 'email' },
      { value: 'in_person', label: 'Presencialmente', icon: 'person' },
    ];

    const { showGrouping } = institution.attributes;

    return(
      <>
        <FullColumn>
          <form onSubmit={handleSubmit} className="mainFormsWrapper CreateProtocol">
            <div className="mainFormsInfoWrapper">
              {showGrouping && (
                <div className="mainFormsInfoField">
                  <Field
                    className="grouping"
                    name="grouping_id"
                    label="Agrupamento"
                    component={RenderSelectFieldWithSearch}
                    validate={[required]}
                    style={{ marginBottom: 10 }}
                    props={{
                      options: this.formattedGroupings(),
                      isSearchable: true,
                    }}
                    onChange={(data) => getSectors(data.value)}
                  />
                </div>
              )}

              <div className="mainFormsInfoField">
                <Field
                  className="sector"
                  name="sector_id"
                  label="Setor"
                  component={RenderSelectFieldWithSearch}
                  validate={[required]}
                  style={{ marginBottom: 10 }}
                  props={{
                    options: this.formattedSectors(),
                    isSearchable: true,
                  }}
                  onChange={(data) => getSubjects(data.value)}
                />
              </div>

              <div className="mainFormsInfoField">
                <Row>
                  <FullColumn>
                    <Field
                      className="category"
                      name="category_id"
                      label="Categoria"
                      component={RenderSelectFieldWithSearch}
                      validate={[required]}
                      style={{ marginBottom: 10 }}
                      props={{
                        options: this.formattedCategories(),
                        isSearchable: true,
                      }}
                    />
                    <Field
                      className="subject"
                      name="subject_id"
                      style={{ marginBottom: 10 }}
                      label="Assunto"
                      component={RenderSelectFieldWithSearch}
                      props={{
                        options: this.formattedSubjects(),
                        isSearchable: true,
                      }}
                      validate={[required]}
                    />
                    <Field
                      name="relative_protocol"
                      component={RenderTextField}
                      label="Protocolo relacionado"
                    />
                    <Field
                      name="secret"
                      label="Sigilo"
                      component={RenderSwitchField}
                    />
                  </FullColumn>
                  <FullColumn>
                    <Field
                      name="description"
                      component={RenderTextField}
                      label="Descrição"
                      placeholder="Descreva sua solicitação"
                      validate={[required]}
                      style={{ marginBottom: 10 }}
                      variant='outlined'
                      multiline
                      rows={6}
                    />
                    <Field
                      name="attachments"
                      component={RenderDropZone}
                      label="Envio de arquivos"
                      props={{
                        filesLimit: 5,
                        acceptedFiles: [
                          'image/jpeg', 'image/png', '.pdf', '.docx',
                          'text/plain', '.doc', '.odt', '.csv', '.xlsx', '.ret', '.rem', '.REM', '.RET',
                        ],
                        sizeMb: '100MB',
                        showButton: false,
                        maxSize: 100000000,
                        initFiles: [],
                        text: 'Clique aqui para adicionar uma imagem ou documento',
                      }}
                      type="file"
                    />
                  </FullColumn>
                </Row>
              </div>

              <div className="mainFormsInfoField">
                <Row>
                  <FullColumn>
                    <Field
                      className="kind_response"
                      name="kind_response"
                      style={{ marginBottom: 10 }}
                      label="Tipo de resposta"
                      component={RenderSelectFieldWithSearch}
                      props={{
                        options: responseTypes,
                        isSearchable: true,
                      }}
                      validate={[required]}
                    />
                  </FullColumn>
                  
                  {formState && formState.kind_response && formState.kind_response.value === 'mail' && (
                    <FullColumn>
                      <Field
                        name="address_id"
                        label="Endereço para envio"
                        component={RenderSelectFieldWithSearch}
                        validate={[required]}
                        props={{
                          options: addressesListExternalUsers.map(({ attributes }) => ({
                            label: attributes.addressFormatted,
                            value: String(attributes.id),
                          })),
                          isDisabled: false,
                          isSearchable: true,
                          isLoading: false,
                          isMulti: false,
                        }}
                      />
                    </FullColumn>
                  )}
                </Row>
              </div>
              <div className="mainFormsInfoField">
                <MapForm 
                  coverage={institution.attributes.coverage}
                  states={states}
                  cities={cities}
                  neighborhoods={neighborhoods}
                  getCities={getCities}
                  initialValues={currentProtocol}
                  getNeighborhoods={getNeighborhoods} 
                />
              </div>
              <div className="mateFormsFooter" style={{ marginTop: 20 }}>
                <div className="mateFormsSubmit">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="mateFormsSubmitBtn"
                    disabled={sending}
                  >
                    Salvar
                  </Button>
                  <Button 
                    disabled={sending} 
                    onClick={onClose} 
                    color="primary" 
                    style={{ marginLeft: 10 }}
                  >
                    Fechar
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </FullColumn>
      </>
    );
  }
}

const mapStateToProps = state => {
  const formState = getFormValues('CreateProtocol')(state);
  return {
    auth: state.Auth,
    ombudsman: state.Ombudsman,
    sector: state.Sector,
    category: state.Category,
    subject: state.Subject,
    currentProtocol: state.Protocol.currentProtocol,
    isUser: state.session.user.data && state.session.user.data.type === 'user',
    institution: state.session.user.data.attributes.institution.data,
    states: state.Address.states,
    cities: state.Address.cities,
    neighborhoods: state.Address.neighborhoods,
    addressesListExternalUsers: state.Address.addressesListExternalUsers,
    formState,
  };
};

const createProtocolForm = reduxForm({
  form: 'CreateProtocol',
  enableReinitialize: true,
})(CreateProtocol);

const Connect = connect(
  mapStateToProps,
  {
    ...ombudsmanActions,
    logoutUser: authActions.logout,
    createProtocol: protocolActions.createProtocol,
    createProtocolExternalUser: protocolActions.createProtocolExternalUser,
    createCurrentProtocol: protocolActions.createCurrentProtocol,
    clearCurrentProtocol: protocolActions.clearCurrentProtocol,
    getStates: addressActions.getStates,
    getCities: addressActions.getCities,
    listAddressesExternalUsers: addressActions.listAddressesExternalUsers,
    getNeighborhoods: addressActions.getNeiborhoods,
  }
)(createProtocolForm);

export default Connect;
