import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import UserRouter from './userRouter';
import Sidebar from '../Sidebar';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import ombudsmanActions from '../../redux/ombudsman/actions';
import themeActions from '../../redux/themeSwitcher/actions';
import MUIPProvider from '../../components/uielements/materialUiPicker/momentProvider';
import { rtl } from '../../settings/withDirection';
import Main, { Root, AppFrame } from './style';
import Navigation from './Navigation';
// import './global.css';

const { logout } = authAction;
const { toggleAll } = appActions;
const { switchActivation } = themeActions;

class App extends Component {
  componentDidMount() {
    const {
      getOmbudsman,
      match: {
        params: {
          slug,
        }
      }
    } = this.props;

    localStorage.setItem('currentMode', 'ombudsman');
    getOmbudsman(slug);
  }

  render() {
    const anchor = rtl === 'rtl' ? 'right' : 'left';
  
    const {
      classes,
      theme,
      match,
      scrollHeight,
      ombudsman,
    } = this.props;
  
    const { url } = match;
    const options = { url, classes, theme };
    return (
      <Root>
        <Debounce time="1000" handler="onResize">
          <WindowResizeListener
            onResize={windowSize =>
              toggleAll(windowSize.windowWidth, windowSize.windowHeight)
            }
          />
        </Debounce>
        <AppFrame>
          <Navigation {...options} />
          <Main>
            <MUIPProvider>
              <UserRouter
                style={{ height: scrollHeight, overflowY: 'auto' }}
                url={url}
              />
            </MUIPProvider>
          </Main>
  
          {anchor === 'right' ? <Sidebar {...options} anchor={anchor} /> : ''}
        </AppFrame>
      </Root>
    );
  }
}


const mapStateToProps = state => {
  return {
    auth: state.Auth,
    ombudsman: state.Ombudsman,
    scrollHeight: state.App.scrollHeight,
    view: state.App.view,
  };
};
const appConect = connect(
  mapStateToProps,
  {
    logout,
    toggleAll,
    switchActivation,
    ...ombudsmanActions,
  }
)(App);
export default appConect;
