const actions = {
  SET_PROFILE: 'SET_PROFILE/profile',
  UPDATE_REQUEST: 'UPDATE_REQUEST/profile',
  UPDATED_SUCCESS: 'UPDATED_SUCCESS/profile',
  SET_PROFILE_EXTERNAL_USER: 'SET_PROFILE_EXTERNAL_USER/profile',
  UPDATE_REQUEST_EXTERNAL_USER: 'UPDATE_REQUEST_EXTERNAL_USER/profile',
  UPDATED_SUCCESS_EXTERNAL_USER: 'UPDATED_SUCCESS_EXTERNAL_USER/profile',
  setProfile: user => ({ type: actions.SET_PROFILE, user }),
  updateProfile: (id, form) => ({ type: actions.UPDATE_REQUEST, id, form }),
  setProfileExternalUser: user => ({ type: actions.SET_PROFILE_EXTERNAL_USER, user }),
  updateProfileExternalUser: (id, form) => ({ type: actions.UPDATE_REQUEST_EXTERNAL_USER, id, form }),
}

export default actions;
