const actions = {
  FETCH_CARDS: "FETCH_CARDS/dashboard",
  FETCH_CARDS_SUCCESS: "FETCH_CARDS_SUCCESS/dashboard",
  GET_OMBUDSMAN_CARDS: "GET_OMBUDSMAN_CARDS/dashboard",
  GET_OMBUDSMAN_CARDS_SUCCESS: "GET_OMBUDSMAN_CARDS_SUCCESS/dashboard",
  GET_OMBUDSMAN_MONTHLY_CARDS: "GET_OMBUDSMAN_MONTHLY_CARDS/dashboard",
  GET_OMBUDSMAN_MONTHLY_CARDS_SUCCESS: "GET_OMBUDSMAN_MONTHLY_CARDS_SUCCESS/dashboard",
  GET_OMBUDSMAN_STATUS_CATEGORY: "GET_OMBUDSMAN_STATUS_CATEGORY/dashboard",
  GET_OMBUDSMAN_STATUS_CATEGORY_SUCCESS: "GET_OMBUDSMAN_STATUS_CATEGORY_SUCCESS/dashboard",
  FETCH_MONTHLY_SITUATION: "FETCH_MONTHLY_SITUATION/dashboard",
  FETCH_MONTHLY_SITUATION_SUCCESS: "FETCH_MONTHLY_SITUATION_SUCCESS/dashboard",
  FETCH_TODO_LIST: "FETCH_TODO_LIST/dashboard",
  FETCH_TODO_LIST_SUCCESS: "FETCH_TODO_LIST_SUCCESS/dashboard",
  FETCH_INTERNAL_COMMUNICATIONS: 'FETCH_INTERNAL_COMMUNICATIONS/dashboard',
  FETCH_INTERNAL_COMMUNICATIONS_SUCCESS: 'FETCH_INTERNAL_COMMUNICATIONS_SUCCESS/dashboard',
  FETCH_INSTITUTION_REQUEST: "FETCH_INSTITUTION_REQUEST/dashboard",
  FETCH_INSTITUTION_SUCCESS: "FETCH_INSTITUTION_SUCCESS/dashboard",
  CHANGE_INSTITUTION_REQUEST: "CHANGE_INSTITUTION_REQUEST/dashboard",
  CHANGE_INSTITUTION_SUCCESS: "CHANGE_INSTITUTION_SUCCESS/dashboard",
  ERROR: "ERROR/dashboard",
  CHECK_TODO_LIST_REQUEST_DASHBOARD: "CHECK_TODO_LIST_REQUEST/dashboard",
  CHECK_TODO_LIST_SUCCESS_DASHBOARD: "CHECK_TODO_LIST_SUCCESS/dashboard",
  CLEAR_INSTITUTION_ID: 'CLEAR_INSTITUTION_ID/dashboard',
  CLEAR_INSTITUTION_ID_SUCCESS: 'CLEAR_INSTITUTION_ID_SUCCESS/dashboard',
  fetchCards: () => ({ type: actions.FETCH_CARDS }),
  getOmbudsmanCards: () => ({ type: actions.GET_OMBUDSMAN_CARDS }),
  getOmbudsMonthlyCards: () => ({ type: actions.GET_OMBUDSMAN_MONTHLY_CARDS }),
  getStatusCategories: () => ({ type: actions.GET_OMBUDSMAN_STATUS_CATEGORY }),
  fetchMonthlySituation: () => ({ type: actions.FETCH_MONTHLY_SITUATION }),
  fetchTodoList: () => ({ type: actions.FETCH_TODO_LIST }),
  fetchInternalCommunications: params => ({ type: actions.FETCH_INTERNAL_COMMUNICATIONS, params }),
  fetchInstitutionsByRoles: () => ({ type: actions.FETCH_INSTITUTION_REQUEST }),
  changeInstitution: (id, isAdmin = false) => ({ type: actions.CHANGE_INSTITUTION_REQUEST, id, isAdmin }),
  checkTodoList: (id, form) => ({
    type: actions.CHECK_TODO_LIST_REQUEST_DASHBOARD,
    id,
    form,
  }),
  clearInstitution: () => ({ type: actions.CLEAR_INSTITUTION_ID }),
};

export default actions;
