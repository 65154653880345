const options = [
  {
    label: 'Dashboard (Ouvidoria)',
    icon: 'dashboard',
    key: '',
    allow: (user) => {
      return user.attributes.modules.includes('ombudsman') || user.attributes.role === 'admin';
    }
  },
  {
    label: 'Protocolos',
    icon: 'confirmation_number',
    key: 'protocols',
    allow: (user) => {
      return !['admin', 'auditor'].includes(user.attributes.role) // user.attributes.role !== 'admin';
    }
  },
  {
    label: 'Relatórios',
    icon: 'assignment',
    key: 'ombudsman-report',
    allow: (user) => {
      return ['full_access'].includes(user.attributes.ombudsmanPermission) ||
        ['auditor'].includes(user.attributes.role)
    },
  },
  {
    label: 'Agenda',
    icon: 'date_range',
    key: 'todo-lists',
    allow: (user) => {
      return ['data_manager', 'master_one', 'master_two', 'sector_manager_one', 'sector_manager_two', 'auditor'].includes(user.attributes.role);
    }
  },
  {
    label: 'Agrupamentos',
    icon: 'group_work',
    key: 'grouping',
    allow: (user) => {
      return ['data_manager', 'admin', 'master_one', 'auditor'].includes(user.attributes.role) || 
        ['full_access', 'limited_access'].includes(user.attributes.ombudsmanPermission)
    },
  },
  {
    label: 'Setores',
    icon: 'auto_awesome_mosaic',
    key: 'sectors',
    allow: (user) => {
      return ['data_manager', 'admin', 'master_one', 'auditor'].includes(user.attributes.role) ||
        ['full_access', 'limited_access'].includes(user.attributes.ombudsmanPermission)
    }
  },
  {
    label: 'Categorias',
    icon: 'view_carousel',
    key: 'categories',
    allow: (user) => {
      return ['data_manager', 'admin', 'master_one', 'auditor'].includes(user.attributes.role) ||
        ['full_access', 'limited_access'].includes(user.attributes.ombudsmanPermission)
    }
  },
  {
    label: 'Assuntos',
    icon: 'subject',
    key: 'subjects',
    allow: (user) => {
      return ['data_manager', 'admin', 'master_one', 'auditor'].includes(user.attributes.role) ||
        ['full_access', 'limited_access'].includes(user.attributes.ombudsmanPermission)
    }
  },
  {
    label: 'Slides',
    icon: 'slideshow',
    key: 'new-slides',
    allow: (user) => {
      return ['admin'].includes(user.attributes.role) ||
        ['full_access', 'limited_access'].includes(user.attributes.ombudsmanPermission)
    },
  },
  {
    label: 'Usuários Setoriais',
    icon: 'people',
    key: 'users',
    allow: (user) => {
      return ['admin', 'data_manager', 'auditor'].includes(user.attributes.role);
    }
  },
  {
    label: 'Usuários Externos',
    icon: 'groups',
    key: 'users-ouvidoria',
    allow: (user) => {
      return user.attributes.modules.includes('ombudsman') &&
      user.attributes.ombudsmanAccess;
    }
  },
  {
    label: 'Importar',
    icon: 'settings_backup_restore',
    key: 'restore',
    allow: (user) => {
      return ['admin'].includes(user.attributes.role);
    }
  },
  {
    label: 'Perguntas',
    icon: 'quiz',
    key: 'questions',
    allow: (user) => {;
      return ['admin'].includes(user.attributes.role) ||
        ['full_access', 'limited_access'].includes(user.attributes.ombudsmanPermission)
    }
  },
  {
    label: 'Configurar Instituição',
    icon: 'tune',
    key: 'manager-config',
    allow: (user) => {
      return ['data_manager'].includes(user.attributes.role);
    },
  },
  {
    label: 'CI Digital',
    icon: 'settings',
    key: '../../dashboard',
    allow: (user) => {
      return user.attributes.modules.includes('ci_digital');
    },
  },
];

export default options;
