import { PURGE } from "redux-persist";
import actions from './actions';

const initState = {
  cards: [],
  ombudsmanCards: {},
  errors: [],
  todoList: [],
  loading: true,
  pagination: {
    itemsPerPage: 10,
  },
  currentInstitutionAttributes: {},
  institutions: [],
  loadingMonth: true,
  monthlySituation: [],
  ombudsmanMonthlySituation: [],
  ombudsmanStatusCategories: [],
  internalCommunications: [],
  currentInstitution: JSON.parse(localStorage.getItem('institutionId') || null),
};

export default function (state = initState, action) {
  switch (action.type) {

    case actions.CHECK_TODO_LIST_SUCCESS_DASHBOARD: {
      return {
        ...state,
        todoList: state.todoList.map((todoList) =>
          todoList.id === action.data.id ? { ...action.data } : todoList
        ),
        sending: false,
      };
    }
    case actions.CHECK_TODO_LIST_REQUEST_DASHBOARD: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.FETCH_CARDS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_OMBUDSMAN_CARDS:
      return { ...state, loading: true };
    case actions.GET_OMBUDSMAN_CARDS_SUCCESS:
      return {
        ...state,
        ombudsmanCards: action.data,
        loading: false
      };
    case actions.GET_OMBUDSMAN_MONTHLY_CARDS:
      return { ...state, loading: true };
    case actions.GET_OMBUDSMAN_MONTHLY_CARDS_SUCCESS:
      return {
        ...state,
        ombudsmanMonthlySituation: action.data,
        loading: false
      };
    case actions.GET_OMBUDSMAN_STATUS_CATEGORY_SUCCESS:
      return {
        ...state,
        ombudsmanStatusCategories: action.data,
      };
    case actions.CHANGE_INSTITUTION_SUCCESS:
      // FIXME Antes de deslogar esse método é executado.
      return {
        ...state,
        currentInstitution: JSON.parse(localStorage.getItem('institutionId')),
      }
    case actions.CLEAR_INSTITUTION_ID_SUCCESS:
      localStorage.removeItem('institutionId');
      return {
        ...state,
        currentInstitution: null,
      };
    case actions.FETCH_INSTITUTION_SUCCESS:
      return {
        ...state,
        institutions: action.data,
      };
    case actions.FETCH_CARDS_SUCCESS:
      return {
        ...state,
        cards: action.data,
        loading: false,
      };
    case actions.FETCH_MONTHLY_SITUATION:
      return {
        ...state,
        loadingMonth: true,
      };
    case actions.FETCH_MONTHLY_SITUATION_SUCCESS:
      return {
        ...state,
        monthlySituation: action.data,
        loadingMonth: false,
      };
    case actions.FETCH_TODO_LIST:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_TODO_LIST_SUCCESS:
      return {
        ...state,
        todoList: action.data,
        loading: false,
      };
    case actions.FETCH_INTERNAL_COMMUNICATIONS:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_INTERNAL_COMMUNICATIONS_SUCCESS:
      return {
        ...state,
        internalCommunications: action.data,
        pagination: action.pagination,
        loading: false,
      };
    case PURGE:
      return initState;
    case actions.ERROR:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    default:
      return state;
  }
}
