const actions = {
  FETCH_REQUEST: 'FETCH_REQUEST/external_users',
  FETCH_SUCCESS: 'FETCH_SUCCESS/external_users',
  GET: 'GET/external_users',
  GET_SUCCESS: 'GET_SUCCESS/external_users',
  CHANGE_USER_ID: 'CHANGE_USER_ID/external_users',
  SET: 'SET/external_users',
  CREATE: 'ADD_ITEM/external_users',
  CREATE_SUCCESS: 'CREATE_SUCCESS/external_users',
  UPDATE: 'UPDATE/external_users',
  UPDATED_SUCCESS: 'UPDATED_SUCCESS/external_users',
  SELECT_CURRENT: 'SELECT_CURRENT/external_users',
  TOGGLE_VIEW: 'TOGGLE_VIEW/external_users',
  EDIT: 'EDIT/external_users',
  CHANGE_STATUS: 'CHANGE_STATUS/external_users',
  OPEN_MODAL_NEW: 'OPEN_MODAL_NEW/external_users',
  TOGGLE_MODAL_NEW: 'TOGGLE_MODAL_NEW/external_users',
  TOGGLE_MODAL_EDIT: 'TOGGLE_MODAL_EDIT/external_users',
  TOGGLE_MODAL_CREATE_PROTOCOL: 'TOGGLE_MODAL_CREATE_PROTOCOL/external_users',
  TOGGLE_MODAL_CHANGE_EMAIL: 'TOGGLE_MODAL_CHANGE_EMAIL/protocol',
  SAVE_FILTER_FORM: 'SAVE_FILTER_FORM/external_users',
  TOGGLE_MODAL_FILTER: 'TOGGLE_MODAL_FILTER/external_users',
  ACTIVATE: 'ACTIVATE/external_users',
  ACTIVATE_SUCCESS: 'ACTIVATE_SUCCESS/external_users',
  CLEAR_USERS_OUVIDORIA: 'CLEAR_USERS_OUVIDORIA/external_users',
  CHANGE_EMAIL_REQUEST: 'CHANGE_EMAIL_REQUEST/external_users',
  SEND_INSTRUCTIONS: 'SEND_INSTRUCTIONS/external_users',
  UPDATE_EMAIL_EXTERNAL_USER: 'UPDATE_EMAIL_EXTERNAL_USER/external_users',
  UPDATE_EMAIL_EXTERNAL_USER_SUCCESS: 'UPDATE_EMAIL_EXTERNAL_USER_SUCCESS/external_users',
  GET_DOCUMENT_TYPE_EXTERNAL_USER: 'GET_DOCUMENT_TYPE_EXTERNAL_USER/external-users',
  ERROR: 'ERROR/external_users',
  fetchRequest: params => ({ type: actions.FETCH_REQUEST, params }),
  getUser: id => ({type: actions.GET, id}),
  changeUserId: id => ({type: actions.CHANGE_USER_ID, id}),
  openModalNew: () => ({ type: actions.OPEN_MODAL_NEW }),
  closeModalNew: () => ({ type: actions.CLOSE_MODAL_NEW }),
  toggleModalNew: () => ({ type: actions.TOGGLE_MODAL_NEW }),
  closeModalEdit: () => ({ type: actions.CLOSE_MODAL_EDIT }),
  toggleModalEdit: () => ({ type: actions.TOGGLE_MODAL_EDIT }),
  toggleModalCreateProtocol: () => ({ type: actions.TOGGLE_MODAL_CREATE_PROTOCOL }),
  toggleModalFilter: () => ({ type: actions.TOGGLE_MODAL_FILTER }),
  toggleModalChangeEmail: () => ({ type: actions.TOGGLE_MODAL_CHANGE_EMAIL }),
  addUser: (institutionId, form) => ({ type: actions.CREATE, institutionId, form }),
  setUser: user => ({ type: actions.SET, user }), // Open modal edit
  changeUser: (id, form) => ({ type: actions.UPDATE, id, form }),
  saveFilter: params => ({ type: actions.SAVE_FILTER_FORM, params }),
  activateUser: id => ({type: actions.ACTIVATE, id}),
  clearUsersOuvidoria: () => ({ type: actions.CLEAR_USERS_OUVIDORIA }),
  confirmEmailChange: token => ({ type: actions.CHANGE_EMAIL_REQUEST, token }),
  sendConfirmation: id => ({ type: actions.SEND_INSTRUCTIONS, id}),
  changeEmailExternalUser: (id, form) => ({ type: actions.UPDATE_EMAIL_EXTERNAL_USER, id, form }),
  getDocumentTypeExternalUser: documentType => ({ type: actions.GET_DOCUMENT_TYPE_EXTERNAL_USER, documentType }),
};

export default actions;
