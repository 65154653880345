import { PURGE } from "redux-persist";
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initState = {
  foldersMap: {},
  files: [],
  fileMap: [{
    root: {
      id: 'root',
      name: 'Raíz',
      isDir: true,
      childrenIds: [],
      childrenCount: 0,
    },
  }],
  currentFolderId: '',
  baseFileMap: [{
    root: {
      id: 'root',
      name: 'Raíz',
      isDir: true,
      childrenIds: [],
      childrenCount: 0,
    },
  }],
  rootFolderId: 'root',
  currentFolderIdRef: 'root',
  folderChain: [],
};
// FIXME
// eslint-disable-next-line no-unused-vars
const useFolderChain = (fileMap, currentFolderId) => {
  const currentFolder = fileMap[currentFolderId];

  const folderChain = [currentFolder];

  let {parentId} = currentFolder;
  while (parentId) {
    const parentFile = fileMap[parentId];
    if (parentFile) {
      folderChain.unshift(parentFile);
      // eslint-disable-next-line prefer-destructuring
      parentId = parentFile.parentId;
    } else {
      break;
    }
  }

  return folderChain;
};

const fetchFolders = (state, action) => {
  const { folders } = action;
    // rootFolderId: 0,
  const rootFolderId = 0;
  const fileMap = {...folders.map(({attributes}) => attributes)};
  const { childrenIds } = fileMap[rootFolderId];
  // const folderChain = useFolderChain(fileMap, rootFolderId);
  return {
    ...state,
    baseFileMap: fileMap,
    fileMap,
    rootFolderId,
    currentFolderIdRef: rootFolderId,
    // use files
    childrenIds,
    files: childrenIds.map(fileId => fileMap[fileId]),
    folderChain: [],
    currentFolderId: rootFolderId,
  };
};

const setCurrentFolderId = (state, action) => {
  const { folderId } = action;
  return {
    ...state,
    currentFolderId: folderId,
    currentFolderIdRef: folderId,
  }
};

const purgeState = () => initState;

const HANDLERS = {
  [actions.FETCH_FOLDERS_SUCCESS]: fetchFolders,
  [actions.SET_CURRENT_FOLDER_ID]: setCurrentFolderId,
  [PURGE]: purgeState,
};

export default createMyReducer(initState, HANDLERS);
