import React from 'react';
import { maskCpfCnpj } from '../../../../helpers/validate';

const columns = [
	{
		title: 'Nome',
		dataIndex: 'name',
		rowKey: 'name',
		render: text => <strong>{text}</strong>,
  },
  {
		title: 'CPF/CNPJ',
		dataIndex: 'document',
		rowKey: 'document',
		render: text => {
      return <strong>{maskCpfCnpj(text)}</strong>
    },
  },
];

export default columns;
