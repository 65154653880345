import React from 'react'
import {
  FormControl,
  FormHelperText,
} from '../../uielements/form'
import TextField from '../../uielements/textfield';

const RenderTextField = ({
  input,
  label,

  meta: { touched, error },
  ...custom
}) => {
  return (
    <FormControl value={input.value}>
      <TextField
        error={!!(touched && error)}
        label={label}
        {...input}
        {...custom}
      />
      {touched && error ? <FormHelperText>{error}</FormHelperText> : ''}
    </FormControl>
  );
};

export default RenderTextField;