import { PURGE } from "redux-persist";
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initForm = {
  password: '',
  passwordConfirmation: '',
  documentNumber: '',
  document: null,
  aggre: false,
}

const initState = {
  formValues: initForm,
  sending: false,
  current: {},
  showMessage: false,
  modalAgree: false,
  errorStatus: null,
  errors: null,
};

const confirmation = state => ({
  ...state,
  current: {},
  showMessage: false,
  sending: true,
});

const confirmationSuccess = (state, action) => ({
  ...state,
  sending: false,
  current: action.current,
  showMessage: true,
});

const confirmationExternalUser = (state) => ({
  ...state,
  showMessage: true,
});

const toogleModal = (state) => ({
  ...state,
  modalAgree: !state.modalAgree,
});

const actionError = (state, action) => ({
  ...state,
  sending: false,
  errorStatus: action.errorStatus,
  errors: action.errors,
});

const purgeState = () => initState;

const HANDLERS = {
  [actions.CONFIRMATION_ACCOUNT_REQUEST]: confirmation,
  [actions.CONFIRMATION_ACCOUNT_SUCCESS]: confirmationSuccess,
  [actions.CONFIRMATION_EXTERNAL_USER_DONE]: confirmationExternalUser,
  [actions.TOOGLE_MODAL]: toogleModal,
  [actions.ERROR]: actionError,
  [PURGE]: purgeState,
};

const confirmationRecucer = createMyReducer(initState, HANDLERS);

export default confirmationRecucer;
