const actions = {
  FETCH_REQUEST: 'FETCH_REQUEST/users',
  FETCH_SUCCESS: 'FETCH_SUCCESS/users',
  FETCH_ADMINS_REQUEST: 'FETCH_ADMINS_REQUEST/users',
  FETCH_ADMINS_SUCCESS: 'FETCH_ADMINS_SUCCESS/users',
  CREATE_ADMIN: 'CREATE_ADMIN/users',
  UPDATE_ADMIN: 'UPDATE_ADMIN/users',
  GET: 'GET/users',
  GET_SUCCESS: 'GET_SUCCESS/users',
  SET: 'SET/users',
  CREATE: 'ADD_ITEM/users',
  CREATE_SUCCESS: 'CREATE_SUCCESS/users',
  UPDATE: 'UPDATE/users',
  UPDATED_SUCCESS: 'UPDATED_SUCCESS/users',
  SELECT_CURRENT: 'SELECT_CURRENT/users',
  TOGGLE_VIEW: 'TOGGLE_VIEW/users',
  EDIT: 'EDIT/users',
  CHANGE_STATUS: 'CHANGE_STATUS/users',
  OPEN_MODAL_NEW: 'OPEN_MODAL_NEW/users',
  TOGGLE_MODAL_NEW: 'TOGGLE_MODAL_NEW/users',
  TOGGLE_MODAL_EDIT: 'TOGGLE_MODAL_EDIT/users',
  SAVE_FILTER_FORM: 'SAVE_FILTER_FORM/users',
  TOGGLE_MODAL_FILTER: 'TOGGLE_MODAL_FILTER/users',
  ACTIVATE: 'ACTIVATE/users',
  ACTIVATE_SUCCESS: 'ACTIVATE_SUCCESS/users',
  CLEAR_USERS: 'CLEAR_USERS/users',
  CHANGE_EMAIL_REQUEST: 'CHANGE_EMAIL_REQUEST/users',
  SEND_INSTRUCTIONS: 'SEND_INSTRUCTIONS/users',
  ERROR: 'ERROR/users',
  fetchRequest: params => ({ type: actions.FETCH_REQUEST, params }),
  fetchAdmins: params => ({ type: actions.FETCH_ADMINS_REQUEST, params }),
  getUser: id => ({type: actions.GET, id}),
  openModalNew: () => ({ type: actions.OPEN_MODAL_NEW }),
  closeModalNew: () => ({ type: actions.CLOSE_MODAL_NEW }),
  toggleModalNew: () => ({ type: actions.TOGGLE_MODAL_NEW }),
  closeModalEdit: () => ({ type: actions.CLOSE_MODAL_EDIT }),
  toggleModalEdit: () => ({ type: actions.TOGGLE_MODAL_EDIT }),
  toggleModalFilter: () => ({ type: actions.TOGGLE_MODAL_FILTER }),
  addUser: (institutionId, form) => ({ type: actions.CREATE, institutionId, form }),
  createAdmin: form => ({ type: actions.CREATE_ADMIN, form }),
  updateAdmin: (id, form) => ({ type: actions.UPDATE_ADMIN, id, form }),
  setUser: user => ({ type: actions.SET, user }), // Open modal edit
  changeUser: (id, form) => ({ type: actions.UPDATE, id, form }),
  saveFilter: params => ({ type: actions.SAVE_FILTER_FORM, params }),
  activateUser: id => ({type: actions.ACTIVATE, id}),
  clearUsers: () => ({ type: actions.CLEAR_USERS }),
  confirmEmailChange: token => ({ type: actions.CHANGE_EMAIL_REQUEST, token }),
  sendConfirmation: id => ({ type: actions.SEND_INSTRUCTIONS, id}),
};

export default actions;
