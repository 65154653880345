const actions = {
  GET_PROTOCOL: 'GET_PROTOCOL/protocol',
  GET_PROTOCOL_SUCCESS: 'GET_PROTOCOL_SUCCESS/protocol',
  GET_PROTOCOLS: 'GET_PROTOCOLS/protocol',
  GET_PROTOCOLS_SUCCESS: 'GET_PROTOCOLS_SUCCESS/protocol',
  CREATE_PROTOCOL: 'CREATE_PROTOCOL/protocol',
  CREATE_PROTOCOL_SUCCESS: 'CREATE_PROTOCOL_SUCCESS/protocol',
  CREATE_PROTOCOL_EXTERNAL_USER: 'CREATE_PROTOCOL_EXTERNAL_USER/protocol',
  CREATE_PROTOCOL_SUCCESS_EXTERNAL_USER: 'CREATE_PROTOCOL_SUCCESS_EXTERNAL_USER/protocol',
  CREATE_CURRENT_PROTOCOL: 'CREATE_CURRENT_PROTOCOL/protocol',
  CLEAR_CURRENT_PROTOCOL: 'CLEAR_CURRENT_PROTOCOL/protocol',
  CHANGE_STATUS_PROTOCOL: 'CHANGE_STATUS_PROTOCOL/protocol',
  CHANGE_STATUS_PROTOCOL_SUCCESS: 'CHANGE_STATUS_PROTOCOL_SUCCESS/protocol',
  OPEN_TRANSFER_MODAL: 'OPEN_TRANSFER_MODAL/protocol',
  CLOSE_TRANSFER_MODAL: 'CLOSE_TRANSFER_MODAL/protocol',
  ANSWER_PROTOCOL: 'ANSWER_PROTOCOL/protocols',
  OPEN_SEARCH_MODAL: 'OPEN_SEARCH_MODAL/protocol',
  CLOSE_SEARCH_MODAL: 'CLOSE_SEARCH_MODAL/protocol',
  TRANSFER_PROTOCOL: 'TRANSFER_PROTOCOL/protocol',
  TRANSFER_PROTOCOL_SUCCESS: 'TRANSFER_PROTOCOL_SUCCESS/protocol',
  TOGGLE_MODAL_START: 'TOGGLE_MODAL_STARAT/protocol',
  TOGGLE_FINISH_MODAL: 'TOGGLE_FINISH_MODAL/protocol',
  TOGGLE_EXTEND_MODAL: 'TOGGLE_EXTEND_MODAL/protocol',
  TOGGLE_MODAL_NEW_PROTOCOL: 'TOGGLE_MODAL_NEW_PROTOCOL/protocol',
  FETCH_MULTIPLE_USERS: 'FETCH_MULTIPLE_USERS/protocol',
  FETCH_MULTIPLE_USERS_SUCCESS: 'FETCH_MULTIPLE_USERS_SUCCESS/protocol',
  COMPLETE_PROTOCOL: 'COMPLETE_PROTOCOL/protocol',
  COMPLETE_PROTOCOL_SUCCESS: 'COMPLETE_PROTOCOL_SUCCESS/protocol',
  EXTEND_PROTOCOL: 'EXTEND_PROTOCOL/protocol',
  CREATE_REPLY: 'CREATE_REPLY/protocol',
  SAVE_FILTER: 'SAVE_FILTER/protocol',
  REPORT_FIELDS: 'REPORT_FIELDS/protocol',
  REPORT_FIELD_SUCCESS: 'REPORT_FIELD_SUCCESS/protocol',
  DOWNLOAD_PDF_REPORT_FIELDS: 'DOWNLOAD_PDF_REPORT_FIELDS/protocol',
  DOWNLOAD_PDF_REPORT_FIELD_SUCCESS: 'DOWNLOAD_PDF_REPORT_FIELD_SUCCESS/protocol',
  DOWNLOAD_XLS_REPORT_FIELDS: 'DOWNLOAD_XLS_REPORT_FIELDS/protocol',
  DOWNLOAD_XLS_REPORT_FIELD_SUCCESS: 'DOWNLOAD_XLS_REPORT_FIELD_SUCCESS/protocol',
  DOWNLOAD_BASIC_REPORT: 'DOWNLOAD_BASIC_REPORT/protocol',
  DOWNLOAD_BASIC_REPORT_SUCCESS: 'DOWNLOAD_BASIC_REPORT_SUCCESS/protocol',
  DOWNLOAD_REPORT: 'DOWNLOAD_REPORT/protocol',
  DOWNLOAD_REPORT_SUCCESS: 'DOWNLOAD_REPORT_SUCCESS/protocol',
  OPEN_FORWARD_MODAL: 'OPEN_FORWARD_MODAL/protocol',
  CLOSE_FORWARD_MODAL: 'CLOSE_FORWARD_MODAL/protocol',
  FORWARD_PROTOCOL: 'FORWARD_PROTOCOL/protocol',
  FORWARD_PROTOCOL_SUCCESS: 'FORWARD_PROTOCOL_SUCCESS/protocol',
  FINISHED_LOADING: 'FINISHED_LOADING/protocol',
  PROTOCOL_DETAIL: 'PROTOCOL_DETAIL/protocol',
  CHANGE_PRIORITY: 'CHANGE_PRIORITY/protocol',
  CHANGE_RESPONSIBLE: 'CHANGE_RESPONSIBLE/protocol',
  LIST_RESPONSIBLES: 'LIST_RESPONSIBLES/protocol',
  LIST_RESPONSIBLES_SUCCESS: 'LIST_RESPONSIBLES_SUCCESS/protocol',
  DISPATCH_PROTOCOL: 'DISPATCH_PROTOCOL/protocol',
  TOGGLE_MODALS: 'TOGGLE_MODALS/protocol',
  TOGGLE_CREATE_USER_NEW_PROTOCOL_MODAL: 'TOGGLE_CREATE_USER_NEW_PROTOCOL_MODAL/protocol',
  TOGGLE_NEW_PROTOCOL_MODAL: 'TOGGLE_NEW_PROTOCOL_MODAL/protocol',
  SAVE_POSITION_CREATE_PROTOCOL: 'SAVE_POSITION_CREATE_PROTOCOL/protocol',
  TOGGLE_READ_REPLY: 'TOGGLE_READ_REPLY/protocol',
  ERROR: 'ERROR/protocol',
  getProtocol: id => ({ type: actions.GET_PROTOCOL, id }),
  getProtocols: (params) => ({ type: actions.GET_PROTOCOLS, params }),
  createProtocol: form => ({ type: actions.CREATE_PROTOCOL, form }),
  listResponsibles: () => ({ type: actions.LIST_RESPONSIBLES }),
  createProtocolExternalUser: (form, id) =>({ type: actions.CREATE_PROTOCOL_EXTERNAL_USER, form, id }),
  saveFilter: params => ({ type: actions.SAVE_FILTER, params }),
  toggleStartComplete: () => ({ type: actions.TOGGLE_MODAL_START }),
  toggleModalFinish: () => ({ type: actions.TOGGLE_FINISH_MODAL }),
  toggleModalExtend: () => ({ type: actions.TOGGLE_EXTEND_MODAL }),
  toggleModalNewProtocol: () => ({ type: actions.TOGGLE_MODAL_NEW_PROTOCOL }),
  changeStatus: (id, status) => ({ type: actions.CHANGE_STATUS_PROTOCOL, id, status }),
  openTransferModal: () => ({ type: actions.OPEN_TRANSFER_MODAL }),
  closeTransferModal: () => ({ type: actions.CLOSE_TRANSFER_MODAL }),
  openSearchModal: () => ({ type: actions.OPEN_SEARCH_MODAL }),
  closeSearchModal: () => ({ type: actions.CLOSE_SEARCH_MODAL }),
  addReply: form => ({ type: actions.CREATE_REPLY, form }),
  fetchMultipleUsers: (ids) => ({ type: actions.FETCH_MULTIPLE_USERS, sector_ids: ids }),
  transferProtocol: (id, form) => ({ type: actions.TRANSFER_PROTOCOL, id, form }),
  completeProtocol: (id, status) => ({ type: actions.COMPLETE_PROTOCOL, id, status }),
  extendProtocol: (id) => ({ type: actions.EXTEND_PROTOCOL, id }),
  createCurrentProtocol: form => ({ type: actions.CREATE_CURRENT_PROTOCOL, form }),
  clearCurrentProtocol: () => ({ type: actions.CLEAR_CURRENT_PROTOCOL }),
  answerProtocol: (id, form) => ({ type: actions.ANSWER_PROTOCOL, id, form }),
  reportFields: (form) => ({ type: actions.REPORT_FIELDS, form }),
  downloadReportPdf: (form, label) => ({ type: actions.DOWNLOAD_PDF_REPORT_FIELDS, form, label }),
  downloadReportXls: (form, label) => ({ type: actions.DOWNLOAD_XLS_REPORT_FIELDS, form, label }),
  downloadPdfReport: (id, identifier) => ({ type: actions.DOWNLOAD_REPORT, id, identifier }),
  downloadBasicReport: (id, identifier) => ({ type: actions.DOWNLOAD_BASIC_REPORT, id, identifier }),
  openForwardModal: () => ({ type: actions.OPEN_FORWARD_MODAL }),
  closeForwardModal: () => ({ type: actions.CLOSE_FORWARD_MODAL }),
  forwardProtocol: (id, form) => ({ type: actions.FORWARD_PROTOCOL, id, form }),
  protocolDetail: () => ({ type: actions.PROTOCOL_DETAIL }),
  changeResponsible: (id, form) => ({ type: actions.CHANGE_RESPONSIBLE, id, form }),
  changePriority: (id, form) => ({ type: actions.CHANGE_PRIORITY, id, form }),
  dispatchProtocol: id => ({ type: actions.DISPATCH_PROTOCOL, id }),
  toggleModals: () => ({ type: actions.TOGGLE_MODALS }),
  toggleCreateUserNewProtocolModal: () => ({ type: actions.TOGGLE_CREATE_USER_NEW_PROTOCOL_MODAL }),
  toggleNewProtocolModal: () => ({ type: actions.TOGGLE_NEW_PROTOCOL_MODAL }),
  savePositionCreateProtocol: (position) => ({ type: actions.SAVE_POSITION_CREATE_PROTOCOL, position }),
  toggleReadReply: (id) => ({ type: actions.TOGGLE_READ_REPLY, id }),
};

export default actions;
