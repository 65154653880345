import React from 'react';
import { Route, Redirect, Switch, Router } from 'react-router-dom';
import { connect } from 'react-redux';

import { getToken } from './helpers/utility';
import App from './containers/App';
import OmbudsmanApp from './containers/App/ombudsman';
import UserApp from './containers/UserDashboard';

// helper async component
import asyncComponent from './helpers/AsyncFunc';

const UnauthorizedComponent = ({ slug }) => {
  const currentMode = localStorage.getItem('currentMode');

  if (slug && currentMode === 'ombudsman') {
    return <Redirect to={`/ouvidoria/${slug}`} />;
  }
  return <Redirect to="/admin" />;
}

const RestrictedRoute = ({ component: Component, isLoggedIn, history, slug, ...rest }) => (
  <Route
    history={history}
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <UnauthorizedComponent slug={slug} />
      )
    }
  />
);

const PublicRoutes = ({ isLoggedIn, history, slug }) => (
  <Router history={history}>
    <Switch>
      <Route
        exact
        path="/"
        component={() => {
          if (window.location.origin.match(/localhost/)) {
            return window.location.replace('/admin');
          }
          return window.location.replace('https://redeparticiparbrasil.com.br/');
        }}
      />
      <Route
        exact
        path="/ouvidoria/alece"
        component={() => {
          if (window.location.origin.match(/localhost/)) {
            return window.location.replace('/admin');
          }
          return window.location.replace('https://tickdoc.com.br/ouvidoria/alece/login');
        }}
      />
      <Route
        exact
        path="/admin"
        component={asyncComponent(() => import('./containers/Page/signin'))}
      />
      <Route
        exact
        path="/505"
        component={asyncComponent(() => import('./containers/Page/505'))}
      />
      <Route
        exact
        path="/forgot-password"
        component={asyncComponent(() =>
          import('./containers/Page/forgetpassword')
        )}
      />
      <Route
        exact
        path="/search-protocol"
        component={asyncComponent(() =>
          import('./containers/Page/seachProtocol')
        )}
      />
      <Route
        exact
        path="/ouvidoria"
        component={asyncComponent(() =>
          import('./containers/Page/ouvidoria')
        )}
      />
      <Route
        exact
        path="/confirmation"
        component={asyncComponent(() =>
          import('./containers/Page/confirmation')
        )}
      />
      <Route
        exact
        path="/reset-password"
        component={asyncComponent(() =>
          import('./containers/Page/resetpassword')
        )}
      />
      <Route
        exact
        path="/change-email"
        component={asyncComponent(() =>
          import('./containers/Page/changeEmail')
        )}
      />
      <RestrictedRoute
        path="/dashboard"
        component={App}
        isLoggedIn={isLoggedIn}
        slug={slug}
      />
      <RestrictedRoute
        path="/ouvidoria/dashboard"
        component={OmbudsmanApp}
        isLoggedIn={isLoggedIn}
        slug={slug}
      />
      <Route
        exact
        path="/ouvidoria/selecionar"
        component={asyncComponent(() => import('./containers/InstitutionSelect'))}
      />
      <Route
        exact
        path="/ouvidoria/:slug"
        component={asyncComponent(() => import('./containers/Page/ouvidoria'))}
      />
      <Route
        exact
        path="/ouvidoria/:slug/pesquisa-de-satisfacao"
        component={asyncComponent(() => import('./containers/Page/satisfactionSurvey'))}
      />
      <Route
        exact
        path="/ouvidoria/:slug/graficos"
        component={asyncComponent(() => import('./containers/Page/ouvidoria/graphs'))}
      />
      <Route
        exact
        path="/ouvidoria/:slug/forgot-password"
        component={asyncComponent(() => import('./containers/Page/ouvidoria/forgotpassword'))}
      />
      <Route
        exact
        path="/ouvidoria/:slug/reset-password"
        component={asyncComponent(() => import('./containers/Page/ouvidoria/resetpassword'))}
      />
      <RestrictedRoute
        isLoggedIn={isLoggedIn}
        // exact
        path="/ouvidoria/:slug/protocolo"
        component={UserApp}
        slug={slug}
      />
      <Route
        exact
        path="/offline"
        component={asyncComponent(() => import('./containers/Page/offline'))}
      />
      <Route
        path="*"
        component={asyncComponent(() => import('./containers/Page/404'))}
      />
    </Switch>
  </Router>
);

function mapStateToProps(state) {
  const token = getToken();
  return {
    isLoggedIn: token || state.session.authenticated,
    slug: state.Ombudsman.slug,
  };
}
export default connect(mapStateToProps)(PublicRoutes);
