import { PURGE } from "redux-persist";
import clone from 'clone';
import { getCurrentTheme } from '../../containers/ThemeSwitcher/config';
import actions from './actions';

const initState = {
  isActivated: false,
  changeThemes: getCurrentTheme(
    'changeThemes',
    'themedefault'
  ),
  breadCrumbTheme: getCurrentTheme(
    'breadCrumbTheme',
    'themedefault'
  ),
  topbarTheme: getCurrentTheme(
    'topbarTheme',
    'themedefault'
  ),
  sidebarTheme: getCurrentTheme(
    'sidebarTheme',
    'themedefault'
  ),
  layoutTheme: getCurrentTheme(
    'layoutTheme',
    'themedefault'
  )
};

export default function(state = initState, action) {
  switch (action.type) {
    case actions.SWITCH_ACTIVATION:
      return { ...state, isActivated: clone(action.isActivated) };
    case PURGE:
      return initState;
    case actions.CHANGE_THEME:
      return { ...state, [action.attribute]: action.theme };
    default:
      return state;
  }
}
