const actions = {
  CONFIRMATION_ACCOUNT_REQUEST: 'CONFIRMATION_ACCOUNT_REQUEST/confirmation',
  CONFIRMATION_ACCOUNT_SUCCESS: 'CONFIRMATION_ACCOUNT_SUCCESS/confirmation',
  CONFIRMATION_EXTERNAL_USER: 'CONFIRMATION_EXTERNAL_USER/confirmation',
  CONFIRMATION_EXTERNAL_USER_DONE: 'CONFIRMATION_EXTERNAL_USER_DONE/confirmation',
  TOOGLE_MODAL: 'TOOGLE_MODAL/confirmation',
  ERROR: 'ERROR/confirmation',
  confirmationAccount: (token, form) => ({
    type: actions.CONFIRMATION_ACCOUNT_REQUEST,
    token,
    form
  }),
  confirmationExternalUser: (token) => ({
    type: actions.CONFIRMATION_EXTERNAL_USER,
    token,
  }),
  toogleModal: () => ({ type: actions.TOOGLE_MODAL }),
};
export default actions;
