/* eslint-disable radix */
const extractBody = response => response.data;

const extractHeaders = response => {
  return {
    totalItems:   parseInt(response.headers.totalitems, 10),
    itemsPerPage: parseInt(response.headers.itemsperpage, 10),
    currentPage: parseInt(response.headers.currentpage, 10),
    nextPage: parseInt(response.headers.nextpage, 10),
    prevPage: parseInt(response.headers.prevpage, 10),
    pageFrom: parseInt(response.headers.pagefrom, 10),
    pageTo: parseInt(response.headers.pageto, 10),
    pages: parseInt(response.headers.pages, 10),
  };
};

const extractErrorsFromUnauthorizedRequest = response => {
  return extractBody(response).errors.map(error => error.detail);
};

// eslint-disable-next-line no-console
// eslint-disable-next-line no-new-func
const handleRequestError = (error, { logger = console.log, fallback = new Function() } = {}) => {
  const { response } = error;

  if (response) {
    const { status } = response;

    if(status === 422) {
      logger(extractBody(response).errors.full_messages.join('\n'));
    } else if (status === 403) {
      logger(extractErrorsFromUnauthorizedRequest(response).join('\n'));
    }
  } else {
    fallback();
  }
};

export {
  extractBody,
  extractHeaders,
  extractErrorsFromUnauthorizedRequest,
  handleRequestError
};
