import { PURGE } from 'redux-persist';
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initForwardForm = {
  id: null,
  email: '',
  subject: '',
  comment: '',
};

const initModalCreateProtocolAndUser = {
  modalFilter: true,
  modalCreateUser: false,
  modalCreateProtocol: false,
};

const initState = {
  protocol: {},
  protocols: [],
  users: [],
  responsibleUsers: [],
  protocolDetail: {},
  filterFormValues: {},
  replyFormValues: {},
  currentProtocol: {},
  position: {},
  modalTransfer: false,
  modalSearch: true,
  toggleModalsState: false,
  modalCreateProtocolAndUser: initModalCreateProtocolAndUser,
  modalStart: false,
  modalNewProtocol: false,
  downloading: false,
  modalFinish: false,
  modalExtend: false,
  forwardModal: false,
  forwarding: false,
  protocolDispatch: false,
  protocolDetailModal: false,
  forwardFormValues: initForwardForm,
  loading: false,
  downloadingPdf: false,
  downloadingXls: false,
  fieldsReport: [],
  pagination: {
    itemsPerPage: 10,
  },
}

const getProtocolSuccess = (state, action) => ({
  ...state,
  protocols: action.data,
  pagination: action.pagination,
});

const getCurrentProtocolSuccess = (state, action) => {
  return {
    ...state,
    protocol: {
      ...action.data,
      included: action.included,
    },
    modalStart: action.userType === 'user' && action.data && action.data.attributes.status === 'pending',
    loading: false,
    replyFormValues: {
      message: '',
      attachments: [],
    },
  };
};

const protocolDetail = (state, action) => ({
  ...state,
  protocolDetail: action.data,
  protocolDetailModal: true,
});

const saveFilter = (state, action) => ({
  ...state,
  filterFormValues: action.params,
});

const openTransferModal = (state) => ({
  ...state,
  modalTransfer: true,
});

const closeTransferModal = (state) => ({
  ...state,
  modalTransfer: false,
});

const openSearchModal = (state) => ({
  ...state,
  modalSearch: true,
});

const closeSearchModal = (state) => ({
  ...state,
  modalSearch: false,
});

const fetchUsersBySector = (state, action) => ({
  ...state,
  users: action.data
});

const createCurrentProtocol = (state, action) => ({
  ...state,
  currentProtocol: {
    ...state.currentProtocol,
    ...action.form,
  }
});

const createProtocolExternalUser = (state, action) => ({
  ...state,
  currentProtocol: {
    ...state.currentProtocol,
    ...action.form,
  }
});

const clearCurrentProtocol = (state) => ({
  ...state,
  currentProtocol: {},
});

const changeStatusProtocolSuccess = (state, action) => ({
  ...state,
  protocol: {
    ...action.data,
    included: action.included,
  },
  modalStart: false,
});

const toggleModalStart = state => ({
  ...state,
  modalStart: !state.modalStart,
});

const toggleModalFinish = state => ({
  ...state,
  modalFinish: !state.modalFinish,
});

const toggleModalExtend = state => ({
  ...state,
  modalExtend: !state.modalExtend,
});

const toggleModals = state => ({
  ...state,
  toggleModalsState: !state.toggleModalsState,
  modalCreateProtocolAndUser: {
    modalFilter: true,
    modalCreateUser: false,
    modalCreateProtocol: false,
  },
});

const toggleModalNewProtocol = state => ({
  ...state,
  modalNewProtocol: !state.modalNewProtocol,
  modalCreateProtocolAndUser: {
    modalFilter: false,
    modalCreateUser: false,
    modalCreateProtocol: true,
  },
});

const toggleCreateUserNewProtocolModal = state => ({
  ...state,
  modalCreateProtocolAndUser: {
    modalFilter: false,
    modalCreateUser: true,
    modalCreateProtocol: false,
  },
});

const toggleNewProtocolModal = state => ({
  ...state,
  modalCreateProtocolAndUser: {
    modalFilter: false,
    modalCreateUser: false,
    modalCreateProtocol: true,
  },
});

const openForwardModal = (state) => ({
  ...state,
  forwardModal: true,
});

const closeForwardModal = (state) => ({
  ...state,
  forwardModal: false,
  forwardFormValues: initForwardForm,
});

const forwardProtocolLoading = (state) => ({
  ...state,
  forwarding: true,
});

const forwardProtocol = (state) => ({
  ...state,
  forwardFormValues: initForwardForm,
  forwardModal: false,
  forwarding: false,
});

const downloadPdfReport = (state) => ({
  ...state,
  downloading: true,
});

const downloadPdfReportSuccess = (state) => ({
  ...state,
  downloading: false,
  downloadingPdf: false,
  downloadingXls: false,
});

const fieldsReportLoading = (state) => ({
  ...state,
  loading: true,
});

const downloadFieldsPdfReport = (state) => ({
  ...state,
  downloadingPdf: true,
});

const downloadFieldsXlsReport = (state) => ({
  ...state,
  downloadingXls: true,
});

const downloadFieldsPdfReportSuccess = (state) => ({
  ...state,
  downloadingPdf: false,
});

const downloadFieldsXlsReportSuccess = (state) => ({
  ...state,
  downloadingXls: false,
});

const fieldsReportSuccess = (state, action) => {
  return {
    ...state,
    looading: false,
    fieldsReport: action.data,
  }
};

const listResponsible = (state, action) => ({
  ...state,
  responsibleUsers: action.data,
});

const dispatchProtocol = (state) => ({
  ...state,
  protocolDispatch: true,
});

const loadingFalse = (state) => ({
  ...state,
  loading: false,
  forwarding: false,
  protocolDispatch: false,
});

const savePositionCreateProtocol = (state, action) => ({
  ...state,
  position: {
    lat: action.position.lat,
    lng: action.position.lng,
  },
});

const purgeState = () => initState;

const HANDLERS = {
  [actions.GET_PROTOCOLS_SUCCESS]: getProtocolSuccess,
  [actions.GET_PROTOCOL_SUCCESS]: getCurrentProtocolSuccess,
  [actions.FETCH_MULTIPLE_USERS_SUCCESS]: fetchUsersBySector,
  [actions.OPEN_TRANSFER_MODAL]: openTransferModal,
  [actions.CLOSE_TRANSFER_MODAL]: closeTransferModal,
  [actions.OPEN_SEARCH_MODAL]: openSearchModal,
  [actions.CLOSE_SEARCH_MODAL]: closeSearchModal,
  [actions.TOGGLE_CREATE_USER_NEW_PROTOCOL_MODAL]: toggleCreateUserNewProtocolModal,
  [actions.TOGGLE_MODAL_START]: toggleModalStart,
  [actions.TOGGLE_FINISH_MODAL]: toggleModalFinish,
  [actions.TOGGLE_EXTEND_MODAL]: toggleModalExtend,
  [actions.TOGGLE_MODAL_NEW_PROTOCOL]: toggleModalNewProtocol,
  [actions.CHANGE_STATUS_PROTOCOL_SUCCESS]: changeStatusProtocolSuccess,
  [actions.CREATE_CURRENT_PROTOCOL]: createCurrentProtocol,
  [actions.CREATE_PROTOCOL_EXTERNAL_USER]: createProtocolExternalUser,
  [actions.CLEAR_CURRENT_PROTOCOL]: clearCurrentProtocol,
  [actions.DOWNLOAD_REPORT]: downloadPdfReport,
  [actions.DOWNLOAD_REPORT_SUCCESS]: downloadPdfReportSuccess,
  [actions.DOWNLOAD_BASIC_REPORT]: downloadPdfReport,
  [actions.DOWNLOAD_BASIC_REPORT_SUCCESS]: downloadPdfReportSuccess,
  [actions.OPEN_FORWARD_MODAL]: openForwardModal,
  [actions.CLOSE_FORWARD_MODAL]: closeForwardModal,
  [actions.FORWARD_PROTOCOL]: forwardProtocolLoading,
  [actions.FORWARD_PROTOCOL_SUCCESS]: forwardProtocol,
  [actions.REPORT_FIELDS]: fieldsReportLoading,
  [actions.DOWNLOAD_PDF_REPORT_FIELDS]: downloadFieldsPdfReport,
  [actions.DOWNLOAD_XLS_REPORT_FIELDS]: downloadFieldsXlsReport,
  [actions.DOWNLOAD_PDF_REPORT_FIELD_SUCCESS]: downloadFieldsPdfReportSuccess,
  [actions.DOWNLOAD_XLS_REPORT_FIELD_SUCCESS]: downloadFieldsXlsReportSuccess, 
  [actions.REPORT_FIELD_SUCCESS]: fieldsReportSuccess,
  [actions.FINISHED_LOADING]: loadingFalse,
  [actions.SAVE_FILTER]: saveFilter,
  [actions.PROTOCOL_DETAIL]: protocolDetail,
  [actions.LIST_RESPONSIBLES_SUCCESS]: listResponsible,
  [actions.DISPATCH_PROTOCOL]: dispatchProtocol,
  [actions.TOGGLE_MODALS]: toggleModals,
  [actions.TOGGLE_CREATE_USER_NEW_PROTOCOL_MODAL]: toggleCreateUserNewProtocolModal,
  [actions.TOGGLE_NEW_PROTOCOL_MODAL]: toggleNewProtocolModal,
  [actions.SAVE_POSITION_CREATE_PROTOCOL]: savePositionCreateProtocol,
  [PURGE]: purgeState,
};

export default createMyReducer(initState, HANDLERS);
