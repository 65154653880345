import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi } from '../../helpers/api'

function* fetchFolders() {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data: folders } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/folders`,
    );
    yield put({ type: actions.FETCH_FOLDERS_SUCCESS, folders });
  } catch (error) {
    yield put({ type: actions.ERROR_FOLDER, status: 400 });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_FOLDERS, fetchFolders),
  ])
}