import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi, postApi, putApi, stringifyQueryParams } from '../../helpers/api'

function* fetchCities(action) {
  try {
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/cities.json?${stringifyQueryParams(action.params)}`,
      );
      
    yield put({ type: actions.GET_CITIES_SUCCESS, cities: data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* createCity(action) {
  try {
    yield call(postApi, `${API.url}/cities.json`, action.form);
    yield put({ type: actions.GET_CITIES });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error && error.response && error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* editCity(action) {
  try {
    yield call(putApi, `${API.url}/cities/${action.id}.json`, action.form);
    yield put({ type: actions.GET_CITIES });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error && error.response && error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CITIES, fetchCities),
    takeEvery(actions.CREATE_CITY, createCity),
    takeEvery(actions.UPDATE_CITY, editCity),
  ]);
}
