const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION/external',
  CHECK_SUCCESS: 'CHECK_SUCCESS/external',
  LOGIN_REQUEST: 'LOGIN_REQUEST/external',
  SIGN_UP_REQUEST: 'SIGN_UP_REQUEST/external',
  SIGN_UP_REQUEST_SUCCESS: 'SIGN_UP_REQUEST_SUCCESS/external',
  USER_UNAUTHORIZED: 'USER_UNAUTHORIZED/external',
  LOGOUT: 'LOGOUT/external',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS/external',
  LOGIN_ERROR: 'LOGIN_ERROR/external',
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST/external',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS/external',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST/external',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS/external',
  CONFIRMATION_ACCOUNT_REQUEST: 'CONFIRMATION_ACCOUNT_REQUEST/external',
  CONFIRMATION_ACCOUNT_SUCCESS: 'CONFIRMATION_ACCOUNT_SUCCESS/external',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: payload => ({
    type: actions.LOGIN_REQUEST,
    payload
  }),
  signup: form => ({
    type: actions.SIGN_UP_REQUEST,
    form,
  }),
  logout: (redirectTo) => ({
    type: actions.LOGOUT,
    redirectTo,
  }),
  forgotPassword: payload => ({
    type: actions.FORGOT_PASSWORD_REQUEST,
    payload
  }),
  resetPassword: (token, form) => ({
    type: actions.RESET_PASSWORD_REQUEST,
    token,
    form
  }),
  confirmationAccount: token => ({
    type: actions.CONFIRMATION_ACCOUNT_REQUEST,
    token,
  }),
};

export default actions;
