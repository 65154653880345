import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from '@sentry/tracing';
import MaterialAdmin from './materialAdmin';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://bf388f7fdd334c8ba5e8e5208a7a3c8c@o461644.ingest.sentry.io/5473075",
    release: "react-ci-2.0",
    integrations: [new Integrations.BrowserTracing()],    
    autoSessionTracking: true,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<MaterialAdmin />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    registration.unregister().then(() => window.location.reload());
  }
});
