const actions = {
  FETCH_INSTITUTIONS: 'FETCH_INSTITUTIONS/trades',
  FETCH_INSTITUTIONS_SUCCESS: 'FETCH_INSTITUTIONS_SUCCESS/trades',
  FETCH_REQUEST: 'FETCH_REQUEST/trades',
  FETCH_SUCCESS: 'FETCH_SUCCESS/trades',
  FETCH_RESOURCES: 'FETCH_RESOURCES/trades',
  FETCH_RESOURCES_SUCCESS: 'FETCH_RESOURCES_SUCCESS/trades',
  FETCH_USERS_BY_SECTION: 'FETCH_USERS_BY_SECTION/trades',
  FETCH_USERS_BY_SECTION_SUCCESS: 'FETCH_USERS_BY_SECTION_SUCCESS/trades',
  COMPLETE_TICKET: 'COMPLETE/trades',
  COMPLETE_TICKET_SUCCESS: 'COMPLETE_SUCCESS/trades',
  OPEN_MODAL_COMPLETE: 'OPEN_COMPLETE_MODAL/trades',
  TOGGLE_MODAL_COMPLETE: 'TOGGLE_COMPLETE_MODAL/trades',
  CHANGE_STATUS_TICKET: 'CHANGE_STATUS_TICKET/trades',
  CHANGE_STATUS_TICKET_SUCCESS: 'CHANGE_STATUS_TICKET_SUCCESS/trades',
  OPEN_MODAL_START: 'OPEN_MODAL_START/trades',
  TOGGLE_MODAL_START: 'TOGGLE_MODAL_START/trades',
  GET: 'GET/trades',
  SET: 'SET/trades',
  SET_SUCCESS: 'SET_SUCCESS/trades',
  CREATE: 'ADD_ITEM/trades',
  CREATE_SUCCESS: 'CREATE_SUCCESS/trades',
  OPEN_MODAL_NEW: 'OPEN_MODAL_NEW/trades',
  TOGGLE_MODAL_NEW: 'TOGGLE_MODAL_NEW/trades',
  OPEN_REPLY_MODAL: 'OPEN_REPLY_MODAL/trades',
  TOGGLE_REPLY_MODAL: 'TOGGLE_REPLY_MODAL/trades',
  CREATE_REPLY: 'ADD_ITEM/trades',
  CREATE_REPLY_SUCCESS: 'CREATE_SUCCESS/trades',
  TOGGLE_FORWARD_MODAL: 'TOGGLE_FORWARD_MODAL/trades',
  OPEN_FORWARD_MODAL: 'OPEN_FORWARD_MODAL/trades',
  FORWARD_TICKET: 'FORWARD_TICKET/trades',
  FORWARD_TICKET_SUCCESS: 'FORWARD_TICKET_SUCCESS/trades',
  OPEN_MODAL_FILTER: 'OPEN_MODAL_FILTER/trades',
  TOGGLE_MODAL_FILTER: 'TOGGLE_MODAL_FILTER/trades',
  SAVE_FILTER_FORM: 'SAVE_FILTER_FORM/trades',
  OPEN_EDIT_MODAL: 'OPEN_EDIT_MODAL/trades',
  TOGGLE_EDIT_MODAL: 'TOGGLE_EDIT_MODAL/trades',
  TRANSFER: 'TRANSFER/trades',
  TRANSFER_SUCCESS: 'TRANSFER_SUCCESS/trades',
  ERROR: 'ERROR/trades',
  fetchInstitutions: () => ({ type: actions.FETCH_INSTITUTIONS }),
  fetchResources: institutionId => ({ type: actions.FETCH_RESOURCES, institutionId }),
  fetchRequest: params => ({ type: actions.FETCH_REQUEST, params }),
  fetchUsersBySector: id => ({ type: actions.FETCH_USERS_BY_SECTION, id }),
  addTicket: form => ({ type: actions.CREATE, form }),
  fetchTicket: id => ({ type: actions.SET, id }),
  completeTicket: id => ({ type: actions.COMPLETE_TICKET, id }),
  changeStatus: (id, status) => ({
    type: actions.CHANGE_STATUS_TICKET,
    id,
    status,
  }),
  toggleStartComplete: () => ({ type: actions.TOGGLE_MODAL_START }),
  openModalComplete: () => ({ type: actions.OPEN_MODAL_COMPLETE }),
  toggleModalComplete: () => ({ type: actions.TOGGLE_MODAL_COMPLETE }),
  openModalNew: () => ({ type: actions.OPEN_MODAL_NEW }),
  toggleModalNew: () => ({ type: actions.TOGGLE_MODAL_NEW }),
  openReplyModal: () => ({ type: actions.OPEN_REPLY_MODAL }),
  toggleReplyModal: () => ({ type: actions.TOGGLE_REPLY_MODAL }),
  addReply: form => ({ type: actions.CREATE_REPLY, form }),
  openForwardModal: () => ({ type: actions.OPEN_FORWARD_MODAL }),
  toggleForwardModal: () => ({ type: actions.TOGGLE_FORWARD_MODAL }),
  openModalFilter: () => ({ type: actions.OPEN_MODAL_FILTER }),
  toggleModalFilter: () => ({ type: actions.TOGGLE_MODAL_FILTER }),
  saveFilter: params => ({ type: actions.SAVE_FILTER_FORM, params }),
  forwardTicket: form => ({ type: actions.FORWARD_TICKET, form }),
  closeModalNew: () => ({ type: actions.CLOSE_MODAL_NEW }),
  openEditModal: () => ({ type: actions.OPEN_EDIT_MODAL }),
  toggleEditModal: () => ({ type: actions.TOGGLE_EDIT_MODAL }),
  transferTicket: (id, form) => ({ type: actions.TRANSFER, id, form }),
};
export default actions;
