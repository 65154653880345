import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import confirmationSagas from './confirmation/sagas';
import NotificationSagas from './userNotification/sagas';
import searchSagas from './searchProtocol/saga'
import institutionSagas from './institution/sagas';
import sectorSagas from './sector/sagas';
import groupingSagas from './grouping/sagas';
import subjetcSagas from './subject/sagas';
import categorySagas from './category/sagas';
import ticketSagas from './ticket/sagas';
import tradeSagas from './trade/sagas';
import userSagas from './user/sagas';
import userOuvidoriaSagas from './userOuvidoria/sagas';
import annoucementSagas from './announcement/sagas';
import dashboardSagas from './dashboard/sagas';
import ProfileSagas from './profile/sagas';
import TodoListSagas from './todoList/sagas';
import BackupSagas from './backup/sagas';
import LockerSagas from './locker/sagas';
import draftSagas from './draft/sagas';
import noticeSagas from './notice/sagas';
import ombudsmanSagas from './ombudsman/sagas';
import questionSagas from './question/sagas';
import stateSagas from './state/sagas';
import externalAuthSagas from './externalAuth/sagas';
import citySagas from './city/sagas';
import branchActivitySagas from './branchActivity/sagas';
import protocolSagas from './protocol/sagas';
import iconSagas from './icon/sagas';
import slidesSagas from './slides/sagas';
import addressSagas from './address/sagas';
import neighrborhoodSagas from './neighborhood/sagas';

export default function* rootSaga() {
	yield all([
    authSagas(),
    confirmationSagas(),
    NotificationSagas(),
		institutionSagas(),
		sectorSagas(),
    groupingSagas(),
		subjetcSagas(),
		categorySagas(),
		ticketSagas(),
    tradeSagas(),
    userSagas(),
    userOuvidoriaSagas(),
    annoucementSagas(),
    dashboardSagas(),
    ProfileSagas(),
    searchSagas(),
    TodoListSagas(),
    BackupSagas(),
    LockerSagas(),
    draftSagas(),
    noticeSagas(),
    ombudsmanSagas(),
    questionSagas(),
    stateSagas(),
    externalAuthSagas(),
    citySagas(),
    branchActivitySagas(),
    protocolSagas(),
    iconSagas(),
    slidesSagas(),
    addressSagas(),
    neighrborhoodSagas(),
	]);
}
