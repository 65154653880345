import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Collapse, Tooltip, withStyles } from '@material-ui/core';
import appActions from '../../redux/app/actions';
import Icon from '../../components/uielements/icon';
import Scrollbars from '../../components/utility/customScrollBar';

import options from './options';
import ombudsmanOptions from './ombudsmanOptions';
import userOptions from './userOptions';
import {
  Lists,
  ListItem,
  ListItemIcon,
} from './style';

const { toggleCollapsed, changeOpenKeys, changeCurrent } = appActions;

const CiTooltip = withStyles(() => ({
  tooltip: {
    fontWeight: 'lighter',
    fontSize: 12,
    marginLeft: 28,
    backgroundColor: 'rgba(0, 0, 0, 0.67)',
    color: '#ffffff',
  },
}))(Tooltip);

let selectedTheme = {};


const currentModule = (user) => {
  if (user && user.type === 'externalUser') {
    return userOptions;
  }
  const mode = localStorage.getItem('currentMode');
  return mode === 'ci_digital' ? options : ombudsmanOptions;
};

const ListLabel = ({ label, icon = 'photo' }) => (
  <div className="sidebar--options">
    <ListItemIcon>
      <CiTooltip  title={label} aria-label={label} placement="right">
        <Icon className="sidebar--icon">{icon}</Icon>
      </CiTooltip>
    </ListItemIcon>
  </div>
);
const ListElement = ({
  leftIcon,
  label,
  icon,
}) => {
  return (
    <div className="ListItemWrapper">
      {leftIcon ? (
        <ListItemIcon style={{ color: selectedTheme.textColor }}>
          {leftIcon}
        </ListItemIcon>
      ) : null}
      <ListLabel label={label} icon={icon} />
    </div>
  );
};

const stripTrailingSlash = str => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};
class Sidebar extends Component {
  handleClick = () => {};

  render() {
    const {
      openKeys,
      current,
      customizedTheme,
      currentUser,
      url: urlProps,
    } = this.props;
    selectedTheme = customizedTheme;
    const url = stripTrailingSlash(urlProps);
    
    const menuItem = option => {
      const { path, key, children, isNavTab, allow, external = false } = option;
      const optionCollapsed = children && openKeys[key] === true;
      const isOpened = openKeys[key] === true;
      let linkTo;
      if (!external) {
        linkTo = option.withoutDashboard ? `/${path || key}` : `${url}/${path || key}`;
      } else {
        linkTo = option.path || option.key;
      }
      
      const collapsedClick = () => {
        if (children && !isNavTab) {
          changeOpenKeys({ [key]: !optionCollapsed });
        } else {
          changeCurrent({ [key]: !optionCollapsed });
        }
      };
      return (
        <div key={key}>
          {children && !isNavTab ? (
            <ListItem
              button
              onClick={collapsedClick}
              className={optionCollapsed ? 'expands' : ''}
            >
              { currentUser && allow(currentUser) && (
                <ListElement
                  {...option}
                  isOpened={isOpened}
                  optionCollapsed={optionCollapsed}
                />
              )}
            </ListItem>
          ) : (
            <React.Fragment>
              { currentUser && allow(currentUser) && (
              <ListItem
                className={current[key] ? 'selected' : ''}
                onClick={collapsedClick}
              >
                <Link to={{ pathname: linkTo }} target={external ? '_blank' : ''} onClick={toggleCollapsed}>
                  <ListElement
                    {...option}
                    isOpened={isOpened}
                    optionCollapsed={optionCollapsed}
                  />
                </Link>
              </ListItem>
              )}
            </React.Fragment>
          )}
          {optionCollapsed && !isNavTab ? (
            <Collapse in timeout={200} unmountOnExit>
              {children.map(menuItem)}
            </Collapse>
          ) : (
            ''
          )}
        </div>
      );
    };
    return (
      <Scrollbars style={{ backgroundColor: '#2b2b2b', height: '100%', width: '62px' }}>
        <Lists>{currentModule(currentUser)
          .filter(option => option.key !== 'backups'
            || (option.key === 'backups' && currentUser && currentUser.attributes.backupAllowed))
          .map(menuItem)}</Lists>
      </Scrollbars>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    currentUser: state.session.user.data,
    customizedTheme: state.ThemeSwitcher.sidebarTheme,
  }),
  { toggleCollapsed, changeOpenKeys, changeCurrent }
)(Sidebar);
