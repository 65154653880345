import { all, call, takeEvery, put, select } from 'redux-saga/effects';
import { reset } from 'redux-form';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi, postApi, putApi, stringifyQueryParams } from '../../helpers/api'
import { notification } from '../../components';

function* fetchInstitutions(action) {
  try {
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/institutions.json?${stringifyQueryParams(action.params)}`,
    );

    yield put({ type: actions.FETCH_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* getInstitutionsList(action) {
  try {
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/institutions/list.json?${stringifyQueryParams(action.params)}`,
    );

    yield put({ type: actions.LIST_INSTITUTION_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* getInstitution(action) {
  try {
    const { data, included } = yield call(
      fetchApi,
      `${API.url}/institutions/${action.id}`
      );
    yield put({ type: actions.GET_SUCCESS, data, included });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors: error.response.data.error,
    });
  }
}

function* getLiteInstitution(action) {
  try {
    const { data, included } = yield call(
      fetchApi,
      `${API.url}/institutions/${action.id}/lite`
      );
    yield put({ type: actions.GET_LITE_SUCCESS, data, included });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors: error.response.data.error,
    });
  }
}

function* createInstitution(action) {
  try {
    const { data } = yield call(postApi, `${API.url}/institutions/`, action.form);

    notification('success', 'Instituição cadastrada com sucesso');
    yield put(reset('InstitutionForm'));
    yield put({ type: actions.CREATE_SUCCESS, data });
  } catch (error) {
    notification('error', `${error.response.data.errors.full_messages}`);
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages
    });
  }
}

function* updateInstitution(action) {
  try {
    yield call(putApi, `${API.url}/institutions/${action.id}`, action.form);

    notification('success', 'Instituição atualizada com sucesso');
    yield put({ type: actions.GET, id: action.id });
  } catch (error) {
    notification('error', `${error.response.data.errors.full_messages}`);
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages
    });
  }
}

function* updateLiteInstitution(action) {
  try {
    yield call(putApi, `${API.url}/institutions/${action.id}/details`, action.form);

    notification('success', 'Instituição atualizada com sucesso');
    yield put({ type: actions.GET_LITE, id: action.id });
  } catch (error) {
    notification('error', `${error.response.data.errors.full_messages}`);

    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages
    });
  }
}

function* updateStatus(action) {
  try {
    yield call(
      putApi,
      `${API.url}/institutions/${action.id}/status`,
      { status: action.status },
      );

    notification('success', `Cliente ${action.status ? 'ativado' : 'desativado'}`);
    yield getInstitution(action);
  } catch (error) {
    notification('error', `${error.response.data.errors}`);
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* getFranchise(action) {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/institutions/${action.id}/franchise`,
    );

    yield put({ type: actions.GET_FRANCHISE_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: 'Falha ao buscar dados da franquia',
    });
  }
}

function* setFranchise(action) {
  try {
    yield call(
      putApi,
      `${API.url}/institutions/${action.id}/set_franchise`,
      { users: action.form },
    )
    notification('success', 'Salvo!');
    yield put({ type: actions.GET_FRANCHISE, form: action.form,  id: action.id });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status
    })
  }
}

function* uploadProtocols(action) {
 try {
  const institutionId = yield select(state => state.Dashboard.currentInstitution)

  yield call(
    postApi,
    `${API.url}/institutions/${institutionId}/import_protocols`,
    action.file,
  );
  notification('success', 'Protocolos em processo de importação');
 } catch (error) {
   yield put({
      type: actions.ERROR,
      status: 422,
      error_message: 'Há erros na planilha. Contate o suporte para resolver',
   });
 } finally {
  yield put({ type: actions.RESTORE_PROTOCOLS_SUCCESS });
 }
}

function* uploadSectors(action) {
  try {
   const institutionId = yield select(state => state.Dashboard.currentInstitution)

   yield call(
     postApi,
     `${API.url}/institutions/${institutionId}/import_sectors`,
     action.file,
   );
   notification('success', 'Setores em processo de importação');
  } catch (error) {
    yield put({
       type: actions.ERROR,
       status: 422,
       error_message: 'Há erros na planilha. Contate o suporte para resolver',
    });
  } finally {
   yield put({ type: actions.RESTORE_SECTORS_SUCCESS });
  }
}

function* uploadUsers(action) {
  try {
   const institutionId = yield select(state => state.Dashboard.currentInstitution);

    yield call(
     postApi,
     `${API.url}/institutions/${institutionId}/import_users`,
     action.file,
    );
    notification('success', 'Usuários em processo de importação');
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: 422,
      error_message: 'Há erros na planilha. Contate o suporte para resolver',
    });
  } finally {
    yield put({ type: actions.RESTORE_USERS_SUCCESS });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.FETCH_REQUEST, fetchInstitutions),
    yield takeEvery(actions.GET, getInstitution),
    yield takeEvery(actions.GET_LITE, getLiteInstitution),
    yield takeEvery(actions.CREATE, createInstitution),
    yield takeEvery(actions.UPDATE, updateInstitution),
    yield takeEvery(actions.UPDATE_LITE, updateLiteInstitution),
    yield takeEvery(actions.UPDATE_STATUS, updateStatus),
    yield takeEvery(actions.GET_FRANCHISE, getFranchise),
    yield takeEvery(actions.SET_FRANCHISE, setFranchise),
    yield takeEvery(actions.RESTORE_PROTOCOLS, uploadProtocols),
    yield takeEvery(actions.RESTORE_SECTORS, uploadSectors),
    yield takeEvery(actions.RESTORE_USERS, uploadUsers),
    yield takeEvery(actions.LIST_INSTITUTION, getInstitutionsList),
  ]);
}
