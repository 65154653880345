import { PURGE } from 'redux-persist';
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initState = {
  slides: [],
  slidesList: [],
  editableSlide: {},
  filterFormValues: {},
  loading: false,
  modalNewSlide: false,
  modalEditSlide: false,
  pagination: {
    itemsPerPage: 10,
    totalItems: 0,
    currentPage: 1,
  },
};

const getSlidesSuccess = (state, action) => ({
  ...state,
  slides: action.slides,
  pagination: action.pagination,
  modalNewSlide: false,
  modalEditSlide: false,
  editableSlide: {},
});


const getListSlides = (state, action) => ({
  ...state,
  slidesList: action.slides,
});

const createSlides = (state) => ({
  ...state,
  modalNewSlide: true,
});

const openNewModal = (state) => ({
  ...state,
  modalNewSlide: true,
});

const openEditModal = (state, action) => {
  return {
    ...state,
    modalEditSlide: true,
    editableSlide: {
      id: action.slide.id,
      title: action.slide.title,
      link: action.slide.link,
      status: action.slide.status === 'active',
      image: action.slide.image,
      imageName: action.slide.fileName,
    },
  };
};

const createSuccess = (state) => ({
  ...state,
  loading: false,
  modalNewSlide: false,
});

const closeNewModal = (state) => ({
  ...state,
  modalNewSlide: false,
});

const closeEditModal = (state) => ({
  ...state,
  modalEditSlide: false,
});

const editSlides = (state) => ({
  ...state,
  modalEditSlide: true,
});

const editSuccess = (state) => ({
  ...state,
  loading: false,
  modalEditSlide: false,
});

const saveFilter = (state, action) => ({
  ...state,
  filterFormValues: action.params,
})

const purgeState = () => initState;

const HANDLERS = {
  [actions.GET_SLIDES_SUCCESS]: getSlidesSuccess,
  [actions.LIST_SLIDES_SUCCESS]: getListSlides,
  [actions.CREATE_SLIDES]: createSlides,
  [actions.CREATE_SLIDES_SUCCESS]: createSuccess,
  [actions.OPEN_MODAL_NEW]: openNewModal,
  [actions.OPEN_MODAL_EDIT_SUCCESS]: openEditModal,
  [actions.EDIT_SLIDES]: editSlides,
  [actions.EDIT_SLIDES_SUCCESS]: editSuccess,
  [actions.CLOSE_MODAL_EDIT]: closeEditModal,
  [actions.CLOSE_MODAL_NEW]: closeNewModal,
  [actions.SAVE_FILTER]: saveFilter,
  [PURGE]: purgeState,
};

export default createMyReducer(initState, HANDLERS);
