import React from 'react';

import { makeStyles, Avatar } from '@material-ui/core';
import BoringAvatar from 'boring-avatars';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}));

const CiAvatar = (props) => {
  const { user, url, isLarge } = props;
  const classes = useStyles();

  if (url) {
    return (
      <Avatar
        className={isLarge ? classes.large : ''}
        src={url}
      />
    )
  }

  if (user === null || user === undefined) {
    return null
  }

  if (user.type === 'externalUser') {
    return (
      <BoringAvatar
        size={35}
        name={user.attributes.name}
        variant="beam"
        colors={["#913F33", "#FF705F", "#FFAA67", "#FFDFAB", "#9FB9C2"]}
      />
    );
  }
  if (user.attributes.photo.url) {
    return (
      <Avatar
        className={isLarge ? classes.large : ''}
        src={user.attributes.photo.url}
      />
    )
  }

  return (
    <Avatar
      style={{color: '#fff', backgroundColor: user.attributes.avatar.avatarColor}}
      className={isLarge ? classes.large : ''}
    >
      {user.attributes.avatar.nameInitials}
    </Avatar>
  )
}

export default CiAvatar;
