const actions = {
  GET_STATES: 'GET_STATES/states',
  GET_STATES_SUCCESS: 'GET_STATES_SUCCESS/states',
  GET_STATE_CITIES: 'GET_STATE_CITIES/states',
  GET_STATE_CITIES_SUCCESS: 'GET_STATE_CITIES_SUCCESS/states',
  ERROR: 'ERROR/STATES',
  getStates: () => ({ type: actions.GET_STATES }),
  getCities: (id) => ({ type: actions.GET_STATE_CITIES, id }),
};

export default actions;
