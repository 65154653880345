import React from 'react';
import "moment/locale/pt-br";
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider, DatePicker } from '../materialUiPicker'

function DatePickers(props) {
  const {
    onChange,
    label,
    value,
    defaultValue,
    minDate,
    maxDate,
    noMargin,
  } = props;

  return (
    <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
      <KeyboardDatePicker
        // disableToolbar
        variant="inline"
        margin={noMargin ? '' : "normal"}
        minDate={minDate}
        maxDate={maxDate}
        format="L"
        label={label}
        value={value || defaultValue}
        onChange={onChange}
        autoOk
        openTo="year"
        views={['year', 'month', 'date']}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePickers;
