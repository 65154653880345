import { select, all, call, takeLatest, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi, putApi, stringifyQueryParams } from '../../helpers/api'
import { notification } from '../../components';

const defaultMessages = {
  400: 'Há erros no formulário',
  404: 'Não encontrado',
  500: 'Erro interno. Tente novamente mais tarde',
  403: 'Você não possui acesso',
};

function* fetchCards() {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/dashboard/cards.json`,
    );

    yield put({ type: actions.FETCH_CARDS_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error
    });
  }
}
function* fetchOmbudsmanCards() {
  try {
    const { data } = yield call(fetchApi, `${API.url}/institutions/ombudsman_cards.json`);
    yield put({ type: actions.GET_OMBUDSMAN_CARDS_SUCCESS, data });

  } catch (error) {
    yield put({
      type: actions.ERROR,
      error
    });
  }
}

function* fetchOmbudsmanMonthlySituation() {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/dashboard/ombudsman_monthly_situation.json`,
    );

    yield put({ type: actions.GET_OMBUDSMAN_MONTHLY_CARDS_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error
    });
  }
}

function* fetchOmbudsmanStatusCategories() {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/dashboard/ombudsman_kind_response.json`,
    );

    yield put({ type: actions.GET_OMBUDSMAN_STATUS_CATEGORY_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error
    });
  }
}

function* fetchMonthlySituation() {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/dashboard/monthly_situation.json`,
    );

    yield put({ type: actions.FETCH_MONTHLY_SITUATION_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error
    });
  }
}

function* fetchTodoList() {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/dashboard/todo_list.json`,
    );

    yield put({ type: actions.FETCH_TODO_LIST_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: (error.response && error.response.status) || 401,
      errors: (error.reponse && defaultMessages[error.response.status])
        || 'Não foi possível carregar lista de tarefas',
    });
  }
}

function* fetchInternalCommunications(action) {
  try {
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/dashboard/internal_communication.json?${stringifyQueryParams(action.params)}`,
    );
    yield put({ type: actions.FETCH_INTERNAL_COMMUNICATIONS_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: (error.response && error.response.status) || 400,
    });
  }
}

function* fetchInstitutionsByRoles() {
  try {

    const { data } = yield call(
      fetchApi,
      `${API.url}/institutions/institutions_by_role.json`,
    );
    yield put({ type: actions.FETCH_INSTITUTION_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error
    });
  }
}

function* changeInstitution(action) {
  try {
    const data = { institutionId: action.id };
    const previousInstitution = localStorage.getItem('institutionId');
    localStorage.setItem('institutionId', action.id);
    if (action.isAdmin && (parseInt(action.id, 10) !== parseInt(previousInstitution, 10))) {
      notification('success', 'Instituição alterada com sucesso');
    }
    yield put({ type: actions.CHANGE_INSTITUTION_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error
    });
  }
}

function* clearInstitution() {
  yield localStorage.removeItem('institutionId');
  yield localStorage.removeItem('currentMode');
  yield put({ type: actions.CLEAR_INSTITUTION_ID_SUCCESS });
}

function* updateTodoListStatus({id, form}) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );

    const { data } = yield call(
      putApi,
      `${
        API.url
      }/institutions/${institutionId}/todo_lists/${id}/change_status`,
      form,
    );
    notification('success', `Tarefa ${data.attributes.title} atualizada!`);
    yield put({ type: actions.CHECK_TODO_LIST_SUCCESS_DASHBOARD, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.FETCH_CARDS, fetchCards),
    yield takeLatest(actions.FETCH_MONTHLY_SITUATION, fetchMonthlySituation),
    yield takeLatest(actions.FETCH_TODO_LIST, fetchTodoList),
    yield takeLatest(actions.FETCH_INTERNAL_COMMUNICATIONS, fetchInternalCommunications),
    yield takeLatest(actions.FETCH_INSTITUTION_REQUEST, fetchInstitutionsByRoles),
    yield takeEvery(actions.CHANGE_INSTITUTION_REQUEST, changeInstitution),
    yield takeEvery(actions.CHECK_TODO_LIST_REQUEST_DASHBOARD, updateTodoListStatus),
    yield takeEvery(actions.CLEAR_INSTITUTION_ID, clearInstitution),
    yield takeEvery(actions.GET_OMBUDSMAN_CARDS, fetchOmbudsmanCards),
    yield takeEvery(actions.GET_OMBUDSMAN_MONTHLY_CARDS, fetchOmbudsmanMonthlySituation),
    yield takeEvery(actions.GET_OMBUDSMAN_STATUS_CATEGORY, fetchOmbudsmanStatusCategories),
  ])
}
