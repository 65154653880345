const actions = {
  GET_ADDRESSES: 'GET_ADDRESSES/addresses',
  GET_ADDRESSES_SUCCESS: 'GET_ADDRESSES_SUCCESS/addresses',
  GET_STATE: 'GET_STATE/addresses',
  GET_STATE_SUCCESS: 'GET_STATE_SUCCESS/addresses',
  GET_CITIES: 'GET_CITIES/addresses',
  GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS/addresses',
  GET_NEIGHBORHOODS: 'GET_NEIGHBORHOODS/addresses',
  GET_NEIGHBORHOODS_SUCCESS: 'GET_NEIGHBORHOODS_SUCCESS/addresses',
  LIST_ADDRESSES: 'LIST_ADDRESSES/addresses',
  LIST_ADDRESSES_SUCCESS: 'LIST_ADDRESSES_SUCCESS/addresses',
  LIST_ADDRESSES_EXTERNAL_USERS: 'LIST_ADDRESSES_EXTERNAL_USERS/addresses',
  LIST_ADDRESSES_EXTERNAL_USERS_SUCCESS: 'LIST_ADDRESSES_EXTERNAL_USERS_SUCCESS/addresses',
  OPEN_MODAL_NEW: 'OPEN_MODAL_NEW/addresses',
  CLOSE_MODAL_NEW: 'CLOSE_MODAL_NEW/addresses',
  CREATE_ADDRESS: 'CREATE_ADDRESS/addresses',
  CREATE_ADDRESS_SUCCESS: 'CREATE_ADDRESS_SUCCESS/addresses',
  EDIT_ADDRESS: 'EDIT_ADDRESS/addresses',
  OPEN_MODAL_EDIT: 'OPEN_MODAL_EDIT/addresses',
  CLOSE_MODAL_EDIT: 'CLOSE_MODAL_EDIT/addresses',
  EDIT_ADDRESS_SUCCESS: 'EDIT_ADDRESS_SUCCESS/addresses',
  UPDATE_ADDRESS: 'UPDATE_ADDRESS/addresses',
  SAVE_FILTER: 'SAVE_FILTER/addresses',
  ERROR: 'ERROR/STATES',
  getAddresses: (params) => ({ type: actions.GET_ADDRESSES, params }),
  listAddresses: () => ({ type: actions.LIST_ADDRESSES }),
  listAddressesExternalUsers: (id) => ({ type: actions.LIST_ADDRESSES_EXTERNAL_USERS, id }),
  createAddress: form => ({ type: actions.CREATE_ADDRESS, form }),
  editAddress: (id, form) => ({ type: actions.UPDATE_ADDRESS, id, form }),
  openNewModal: () => ({ type: actions.OPEN_MODAL_NEW }),
  openEditModal: (address) => ({ type: actions.OPEN_MODAL_EDIT, address }),
  closeNewModal: () => ({ type: actions.CLOSE_MODAL_NEW }),
  closeEditModal: () => ({ type: actions.CLOSE_MODAL_EDIT }),
  saveFilter: params => ({ type: actions.SAVE_FILTER, params }),
  getStates: () => ({ type: actions.GET_STATE }),
  getCities: (id) => ({ type: actions.GET_CITIES, id }),
  getNeiborhoods: (id) => ({ type: actions.GET_NEIGHBORHOODS, id }),
};

export default actions;
