import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi, postApi, putApi, stringifyQueryParams } from '../../helpers/api'
import { notification } from '../../components';

function* fetchNeighborhoods(action) {
  try {
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/neighborhoods.json?${stringifyQueryParams(action.params)}`,
      );
      
    yield put({ type: actions.GET_NEIGHBORHOODS_SUCCESS, neighborhoods: data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* createNeighborhood(action) {
  try {
    yield call(postApi, `${API.url}/neighborhoods.json`, action.form);
    yield put({ type: actions.GET_NEIGHBORHOODS });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error && error.response && error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* uploadCsv(action) {
  try {
    yield call(
      postApi,
      `${API.url}/neighborhoods/upload`,
      action.file,
    )
    notification('success', 'Protocolo criado com sucesso!');
    yield put({ type: actions.GET_NEIGHBORHOODS });

  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error && error.response && error.response.status,
      error_message: error.response.data.errors,
    });
  } finally {
    yield put({ type: actions.UPLOAD_CSV_SUCCESS });
  }
}

function* editNeighborhood(action) {
  try {
    yield call(putApi, `${API.url}/neighborhoods/${action.id}.json`, action.form);
    yield put({ type: actions.GET_NEIGHBORHOODS });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error && error.response && error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_NEIGHBORHOODS, fetchNeighborhoods),
    takeEvery(actions.CREATE_NEIGHBORHOOD, createNeighborhood),
    takeEvery(actions.UPDATE_NEIGHBORHOOD, editNeighborhood),
    takeEvery(actions.UPLOAD_CSV, uploadCsv),
  ]);
}
