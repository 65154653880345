const actions = {
  FETCH_REQUEST: 'FETCH_REQUEST/subjects',
  FETCH_SUCCESS: 'FETCH_SUCCESS/subjects',
  FETCH_LIST: 'FETCH_LIST/subjects',
  FETCH_LIST_SUCCESS: 'FETCH_LIST_SUCCESS/subjects',
  FETCH_RESOURCES: 'FETCH_RESOURCES/subjects',
  FETCH_RESOURCES_SUCCESS: 'FETCH_RESOURCES_SUCCESS/subjects',
  GET: 'GET/subjects',
  SET: 'SET/subjects',
  CREATE: 'ADD_ITEM/subjects',
  CREATE_SUCCESS: 'CREATE_SUCCESS/subjects',
  UPDATE: 'UPDATE/subjects',
  UPDATED_SUCCESS: 'UPDATED_SUCCESS/subjects',
  SELECT_CURRENT: 'SELECT_CURRENT/subjects',
  TOGGLE_VIEW: 'TOGGLE_VIEW/subjects',
  EDIT: 'EDIT/subjects',
  CHANGE_STATUS: 'CHANGE_STATUS/subjects',
  OPEN_MODAL_NEW: 'OPEN_MODAL_NEW/subjects',
  TOGGLE_MODAL_NEW: 'TOGGLE_MODAL_NEW/subjects',
  TOGGLE_MODAL_EDIT: 'TOGGLE_MODAL_EDIT/subjects',
  OPEN_MODAL_FILTER: 'OPEN_MODAL_FILTER/subjects',
  TOGGLE_MODAL_FILTER: 'TOGGLE_MODAL_FILTER/subjects',
  SAVE_FILTER_FORM: 'SAVE_FILTER_FORM/subjects',
  ERROR: 'ERROR/subjects',
  fetchRequest: params => ({ type: actions.FETCH_REQUEST, params }),
  fetchResources: institutionId => ({ type: actions.FETCH_RESOURCES, institutionId }),
  fetchSubjectsList: institutionId => ({ type: actions.FETCH_LIST, institutionId }),
  openModalNew: () => ({ type: actions.OPEN_MODAL_NEW }),
  closeModalNew: () => ({ type: actions.CLOSE_MODAL_NEW }),
  toggleModalNew: () => ({ type: actions.TOGGLE_MODAL_NEW }),
  closeModalEdit: () => ({ type: actions.CLOSE_MODAL_EDIT }),
  toggleModalEdit: () => ({ type: actions.TOGGLE_MODAL_EDIT }),
  openModalFilter: () => ({ type: actions.OPEN_MODAL_FILTER }),
  toggleModalFilter: () => ({ type: actions.TOGGLE_MODAL_FILTER }),
  saveFilter: params => ({ type: actions.SAVE_FILTER_FORM, params }),
  addSubject: form => ({ type: actions.CREATE, form }),
  setSubject: subject => ({ type: actions.SET, subject }), // Open modal edit
  changeSubject: form => ({ type: actions.UPDATE, form }),
};

export default actions;
