const actions = {
  FETCH_REQUEST: 'FETCH_REQUEST/categories',
  FETCH_SUCCESS: 'FETCH_SUCCESS/categories',
  FETCH_RESOURCES: 'FETCH_RESOURCES/categories',
  FETCH_RESOURCES_SUCCESS: 'FETCH_RESOURCES_SUCCESS/categories',
  GET: 'GET/categories',
  SET: 'SET/categories',
  CREATE: 'ADD_ITEM/categories',
  CREATE_SUCCESS: 'CREATE_SUCCESS/categories',
  UPDATE: 'UPDATE/categories',
  UPDATED_SUCCESS: 'UPDATED_SUCCESS/categories',
  SELECT_CURRENT: 'SELECT_CURRENT/categories',
  TOGGLE_VIEW: 'TOGGLE_VIEW/categories',
  EDIT: 'EDIT/categories',
  CHANGE_STATUS: 'CHANGE_STATUS/categories',
  TOGGLE_MODAL_NEW: 'TOGGLE_MODAL_NEW/categories',
  TOGGLE_MODAL_EDIT: 'TOGGLE_MODAL_EDIT/categories',
  TOGGLE_MODAL_FILTER: 'TOGGLE_MODAL_FILTER/categories',
  SAVE_FILTER_FORM: 'SAVE_FILTER_FORM/categories',
  ERROR: 'ERROR/categories',
  fetchRequest: params => ({ type: actions.FETCH_REQUEST, params }),
  fetchResources: institutionId => ({ type: actions.FETCH_RESOURCES, institutionId }),
  openModalNew: () => ({ type: actions.OPEN_MODAL_NEW }),
  closeModalNew: () => ({ type: actions.CLOSE_MODAL_NEW }),
  toggleModalNew: () => ({ type: actions.TOGGLE_MODAL_NEW }),
  openModalFilter: () => ({ type: actions.OPEN_MODAL_FILTER }),
  toggleModalFilter: () => ({ type: actions.TOGGLE_MODAL_FILTER }),
  saveFilter: params => ({ type: actions.SAVE_FILTER_FORM, params }),
  closeModalEdit: () => ({ type: actions.CLOSE_MODAL_EDIT }),
  toggleModalEdit: () => ({ type: actions.TOGGLE_MODAL_EDIT }),
  addCategory: form => ({ type: actions.CREATE, form }),
  setCategory: category => ({ type: actions.SET, category }), // Open modal edit
  changeCategory: form => ({ type: actions.UPDATE, form }),
};

export default actions;
