import { PURGE } from 'redux-persist';
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initForm = {
  id: null,
  title: '',
  description: '',
  daily: true,
};

const todoFilterForm = {
  title_cont: '',
  status_eq: undefined,
  description_cont: '',
};

const initState = {
  todoLists: [],
  ticketTodoLists: [],
  selected: [],
  users: [],
  formValues: initForm,
  todoFilterFormValues: todoFilterForm,
  sending: false,
  current: {},
  orderBy: '',
  order: 'asc',
  editable: {},
  pagination: {
    itemsPerPage: 10,
  },
  todoPagination: {
    itemsPerPage: 10,
  },
  loading: true,
  modalNew: false,
  modalEdit: false,
  enableEdit: false,
  errors: null,
};

const fetchRequest = state => ({
  ...state,
  loading: false,
  modalEdit: false,
});

const fetchTicketRequest = state => ({
  ...state,
  loading: false,
  modalEdit: false,
});

const fetchSuccess = (state, action) => ({
  ...state,
  todoLists: action.data,
  pagination: action.pagination,
  loading: false,
  modalEdit: false,
});

const fetchTicketSuccess = (state, action) => ({
  ...state,
  ticketTodoLists: action.data,
  todoPagination: action.pagination,
  users: action.users,
  loading: false,
});

const getTodoList = state => ({
  ...state,
  loading: false,
  modalEdit: true,
});

const set = (state, action) => ({
  ...state,
  editable: action.todoList.attributes,
  modalEdit: true,
});

const toggleOrder = state => ({
  ...state,
  order: state.order === 'asc' ? 'desc' : 'asc',
});

const toggleModalNew = state => ({
  ...state,
  modalNew: !state.modalNew,
});

const toggleModalEdit = state => ({
  ...state,
  modalEdit: !state.modalEdit,
});

const closeModalEdit = state => ({
  ...state,
  modalEdit: false,
});

const createTodo = state => ({
  ...state,
  sending: true,
});

const createTodoSuccess = (state, action) => ({
  ...state,
  ticketTodoLists: [action.data, ...state.ticketTodoLists],
  formValues: initForm,
  sending: false,
});

const updateTodo = state => ({
  ...state,
  sending: true,
});

const updateTodoSuccess = (state, action) => ({
  ...state,
  ticketTodoLists: state.ticketTodoLists.map(todoList =>
    todoList.id === action.data.id ? { ...action.data } : todoList
  ),
  sending: false,
  modalEdit: false,
});

const updateTodoUserSuccess = (state, action) => ({
  ...state,
  todoLists: state.todoLists.map(todoList =>
    todoList.id === action.data.id ? { ...action.data } : todoList
  ),
  sending: false,
  modalEdit: false,
});

const error = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors,
});

const purgeState = () => initState;

const HANDLERS = {
  [actions.FETCH_REQUEST]: fetchRequest,
  [actions.FETCH_SUCCESS]: fetchSuccess,
  [actions.FETCH_TICKET_REQUEST]: fetchTicketRequest,
  [actions.FETCH_TICKET_SUCCESS]: fetchTicketSuccess,
  [actions.SET]: set,
  [actions.GET_TODO_LIST]: getTodoList,
  [actions.CLOSE_MODAL_EDIT]: closeModalEdit,
  [actions.TOGGLE_MODAL_NEW]: toggleModalNew,
  [actions.TOGGLE_MODAL_EDIT]: toggleModalEdit,
  [actions.CREATE]: createTodo,
  [actions.CREATE_SUCCESS]: createTodoSuccess,
  [actions.UPDATE]: updateTodo,
  [actions.UPDATED_SUCCESS]: updateTodoSuccess,
  [actions.CHECK_TODO_LIST_USER_SUCCESS]: updateTodoUserSuccess,
  [actions.TOGGLE_ORDER]: toggleOrder,
  [actions.ERROR]: error,
  [PURGE]: purgeState,
};

const todoListRecucer = createMyReducer(initState, HANDLERS);

export default todoListRecucer;
