const options = [
  {
    label: 'Dashboard',
    icon: 'dashboard',
    key: '',
    allow: () => {
      return true;
    }
  },
  {
    label: 'Protocolos',
    icon: 'confirmation_number',
    key: 'lista',
    allow: () => {
      return true;
    }
  },
  {
    label: 'Endereços',
    icon: 'location_city',
    key: 'address',
    allow: () => {
      return true;
    }
  },
];

export default options;
