import React, { useCallback } from 'react'
import {
  FormControlLabel,
  FormHelperText,
} from "../../uielements/form"
import Switch from '../../uielements/switch';

const RenderSwitchField = ({
  input,
  label,

  meta: { touched, error },
  ...custom
}) => {
  const onChange = useCallback(value => input.onChange(value), [input]);

  return (
    <FormControlLabel
      control={
        <Switch
          label={label}
          {...input}
          {...custom}
          color="primary"
          onChange={onChange}
          value={input.value}
          checked={!!input.value}
        />
      }
      label={label}
    >
      {touched && error ? <FormHelperText>{error}</FormHelperText> : ''}
    </FormControlLabel >
  );
};

export default RenderSwitchField;
