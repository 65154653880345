import { PURGE } from "redux-persist";
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initForm = {
  id: null,
  name: '',
  status: true,
}

const filterForm = {
  name_cont: '',
  status_eq: undefined
};

const initState = {
  categories: [],
  hasAssociation: localStorage.getItem('currentMode') !== 'ci_digital',
  sectors: [],
  formValues: initForm,
  filterFormValues: filterForm,
  current: {},
  editable: {},
  pagination: {
    itemsPerPage: 10,
  },
  loading: true,
  modalNew: false,
  modalEdit: false,
  modalFilter: false,
  enableEdit: false,
  sending: false,
  errors: null,
};

const fetchRequest = (state) => ({
  ...state,
  loading: true,
});

const fetchRequestSuccess = (state, action) => ({
  ...state,
  categories: action.data,
  pagination: action.pagination,
  loading: false,
});

const set = (state ,action) => ({
  ...state,
  editable: {
    ...action.category.attributes,
    sectorId: { value: String(action.category.attributes.sectorId) },
  },
  modalEdit: true
})

const saveFilterForm = (state, action) => ({
  ...state,
  filterFormValues: action.params,
});

const create = (state) => ({
  ...state,
  sending: true,
})

const createdSuccess = (state, action) => ({
  ...state,
  categories: [
    action.data,
    ...state.categories,
  ],
  sending: false,
  formValues: initForm,
})


const update = (state) => ({
  ...state,
  sending: true,
})

const fetchResourcesSuccess = (state, action) => ({
  ...state,
  sectors: action.sectors,
});

const updatedSuccess = (state, action) => ({
  ...state,
  categories: state.categories.map(category => category.id === action.data.id ?
    { ...action.data } :
    category
  ),
  sending: false,
})

const toggleModalNew = state => ({
  ...state,
  modalNew: !state.modalNew,
})

const toggleModalEdit = state => ({
  ...state,
  modalEdit: !state.modalEdit,
})

const toggleModalFilter = state => ({
  ...state,
  modalFilter: !state.modalFilter,
})

const actionError = (state, action) => ({
  ...state,
  sending: false,
  errorStatus: action.errorStatus,
  errors: action.errors,
});

const purgeState = () => {
  return initState;
};

const HANDLERS = {
  [actions.FETCH_REQUEST]: fetchRequest,
  [actions.FETCH_SUCCESS]: fetchRequestSuccess,
  [actions.FETCH_RESOURCES_SUCCESS]: fetchResourcesSuccess,
  [actions.SET]: set,
  [actions.SAVE_FILTER_FORM]: saveFilterForm,
  [actions.CREATE]: create,
  [actions.CREATE_SUCCESS]: createdSuccess,
  [actions.UPDATE]: update,
  [actions.UPDATED_SUCCESS]: updatedSuccess,
  [actions.TOGGLE_MODAL_NEW]: toggleModalNew,
  [actions.TOGGLE_MODAL_EDIT]: toggleModalEdit,
  [actions.TOGGLE_MODAL_FILTER]: toggleModalFilter,
  [actions.ERROR]: actionError,
  [PURGE]: purgeState,
};

const categoryRecucer = createMyReducer(initState, HANDLERS);

export default categoryRecucer;
