import { PURGE } from "redux-persist";
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initForm = {
  id: null,
  logo: null,
  name: '',
  prefixProtocol: '',
  status: true,
  priceCents: 0,
  hasParticipar: false,
  parent: null,
}

const filterForm = {
  name_cont: '',
  status_eq: undefined
};

const initState = {
  institutions: [],
  institutionList: [],
  formValues: initForm,
  filterFormValues: filterForm,
  current: {},
  editable: {},
  franchise: {},
  pagination: {
    itemsPerPage: 10,
  },
  loading: true,
  modalNew: false,
  modalEdit: false,
  modalFilter: false,
  enableEdit: false,
  loadingInstitutionList: false,
  errors: null,
  importingProtocols: false,
  importingSectors: false,
  importingUsers: false,
};

const fetchRequest = state => ({
  ...state,
  loading: true,
});

const fetchSuccess = (state, action) => ({
  ...state,
  institutions: action.data,
  pagination: action.pagination,
  loading: false,
});

const setInstitution = (state, action) => ({
  ...state,
  editable: {
    ...action.institution.attributes,
    cidigital: action.institution.attributes.modules.includes('ci_digital'),
    ombudsman: action.institution.attributes.modules.includes('ombudsman'),
    report: action.institution.attributes.modules.includes('report'),
    clientType: { value: action.institution.attributes.clientType },
    coverage: { value: action.institution.attributes.coverage },
    kindPlan: { value: action.institution.attributes.kindPlan },
    branchActivityId: { value: action.institution.attributes.branchActivityId },
    franchiseId: action.institution.attributes.franchise.id,
    glacierLimit: action.institution.attributes.franchise.glacier_limit.toString(),
    storageLimit: action.institution.attributes.franchise.storage_limit,
    glacierValue: action.institution.attributes.franchise.glacier_value,
    storageValue: action.institution.attributes.franchise.storage_value,
    stateId: { value: String(action.institution.attributes.stateId) },
    cityId: { value: String(action.institution.attributes.cityId) },
  },
  modalEdit: true
});

const getInstitution = state => ({
  ...state,
  loading: true,
  errors: null,
});

const getSuccess = (state, action) => ({
    ...state,
    loading: false,
    current: {
      ...action.data,
      included: action.included,
     },
});

const getLiteInstitution = state => ({
  ...state,
  loading: true,
  errors: null,
});

const getLiteSuccess = (state, action) => ({
    ...state,
    loading: false,
    current: {
      ...action.data,
      included: action.included,
     },
})

const toogleModalNew = state => ({
  ...state,
  modalNew: !state.modalNew,
});

const toogleModalEdit = state => ({
  ...state,
  modalEdit: !state.modalEdit,
});

const create = state => ({
  ...state,
  sending: true,
});

const createSuccess = (state, action) => ({
  ...state,
  institutions: [action.data, ...state.institutions],
  formValues: initForm,
  modalNew: false,
  sending: false,
});

const update = state => ({
  ...state,
  sending: true,
});

const updateSuccess = (state, action) => ({
  ...state,
  institutions: state.institutions.map(institution =>
    institution.id === action.data.id ? { ...action.data } : institution
  ),
  sending: false,
});

const updateLite = state => ({
  ...state,
  sending: true,
});

const updateLiteSuccess = (state, action) => ({
  ...state,
  institutions: state.institutions.map(institution =>
    institution.id === action.data.id ? { ...action.data } : institution
  ),
  sending: false,
});

const updateStatusSuccess = (state, action) => ({
  ...state,
  current: {
    included: state.current.included,
    ...action.data,
  },
});

const toggleModalFilter = state => ({
  ...state,
  modalFilter: !state.modalFilter,
});

const saveFilterForm = (state, action) => ({
  ...state,
  filterFormValues: action.params,
});


const getFranchiseSuccess = (state, action) => ({
  ...state,
  franchise: action.data.attributes,
});

const fetchError = (state, action) => ({
  ...state,
  loading: false,
  sending: false,
  errors: action.errors,
});

const importProtocols = state => ({
  ...state,
  importingProtocols: true,
});

const importProtocolsSuccess = state => ({
  ...state,
  importingProtocols: false,
});

const importSectors = state => ({
  ...state,
  importingSectors: true,
});

const importSectorsSuccess = state => ({
  ...state,
  importingSectors: false,
});

const importUsers = state => ({
  ...state,
  importingUsers: true,
});

const importUsersSuccess = state => ({
  ...state,
  importingUsers: false,
});

const getInstitutionsList = state => ({
  ...state,
  loadingInstitutionList: true,
});

const getInstitutionsListSuccess = (state, action) => ({
  ...state,
  loadingInstitutionList: false,
  institutionList: action.data,
  pagination: action.pagination,
});

const purgeState = () => initState;

const HANDLERS = {
  [actions.FETCH_REQUEST]: fetchRequest,
  [actions.FETCH_SUCCESS]: fetchSuccess,
  [actions.SET]: setInstitution,
  [actions.GET]: getInstitution,
  [actions.GET_SUCCESS]: getSuccess,
  [actions.GET_LITE]: getLiteInstitution,
  [actions.GET_LITE_SUCCESS]: getLiteSuccess,
  [actions.TOGGLE_MODAL_NEW]: toogleModalNew,
  [actions.TOGGLE_MODAL_EDIT]: toogleModalEdit,
  [actions.CREATE]: create,
  [actions.CREATE_SUCCESS]: createSuccess,
  [actions.UPDATE]: update,
  [actions.UPDATED_SUCCESS]: updateSuccess,
  [actions.UPDATE_LITE]: updateLite,
  [actions.UPDATE_LITE_SUCCESS]: updateLiteSuccess,
  [actions.UPDATE_STATUS_SUCCESS]: updateStatusSuccess,
  [actions.TOGGLE_MODAL_FILTER]: toggleModalFilter,
  [actions.SAVE_FILTER_FORM]: saveFilterForm,
  [actions.GET_FRANCHISE_SUCCESS]: getFranchiseSuccess,
  [actions.RESTORE_PROTOCOLS]: importProtocols,
  [actions.RESTORE_PROTOCOLS_SUCCESS]: importProtocolsSuccess,
  [actions.RESTORE_SECTORS]: importSectors,
  [actions.RESTORE_SECTORS_SUCCESS]: importSectorsSuccess,
  [actions.RESTORE_USERS]: importUsers,
  [actions.RESTORE_USERS_SUCCESS]: importUsersSuccess,
  [actions.LIST_INSTITUTION]: getInstitutionsList,
  [actions.LIST_INSTITUTION_SUCCESS]: getInstitutionsListSuccess,
  [actions.ERROR]: fetchError,
  [PURGE]: purgeState,
};

const institutionRecucer = createMyReducer(initState, HANDLERS);

export default institutionRecucer;
