import { PURGE } from "redux-persist";
import actions from './actions';

const initForm = {
  id: null,
  name: '',
  status: true,
}

const filterForm = {
  name_cont: '',
  status_eq: undefined
};

const initState = {
  announcements: [],
  unconfirmedAnnouncements: [],
  formValues: initForm,
  filterFormValues: filterForm,
  current: {},
  currentAnnouncement: null,
  currentAnnouncementWhoLiked: null,
  editable: {},
  pagination: {
    itemsPerPage: 10,
  },
  loading: true,
  modalNew: false,
  modalEdit: false,
  modalFilter: false,
  modalWhoLiked: false,
  showAnnouncements: false,
  showAnnouncementDetail: false,
  showAnnouncementDetailWhoLiked: false,
  announcementDetail: null,
  enableEdit: false,
  errors: null,
};

export default function (state = initState, action) {
  switch (action.type) {
    case actions.FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.FETCH_SUCCESS:
      return {
        ...state,
        announcements: action.data,
        pagination: action.pagination,
        loading: false
      }
    case actions.GET_ANNOUNCEMENT:
      return {
        ...state,
        loading: true,
        modalEdit: false,
      };
    case actions.OPEN_MODAL_DETAIL_SUCCESS:
      return {
        ...state,
        announcementDetail: action.data,
        showAnnouncementDetail: true,
      };
    case actions.CLOSE_MODAL_DETAIL:
      return {
        ...state,
        showAnnouncementDetail: false,
      };
    case actions.GET_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAnnouncement: {
          ...action.data,
          included: action.included,
        },
      };
    case actions.FETCH_UNCONFIRMED_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.FETCH_UNCONFIRMED_SUCCESS:
      return {
        ...state,
        unconfirmedAnnouncements: action.data,
        loading: false,
        showAnnouncements: action.data.length > 0,
        currentAnnouncement: action.data[0]
      }
    case actions.DISMISS_ANNOUNCEMENT:
      return {
        ...state,
        unconfirmedAnnouncements: state.unconfirmedAnnouncements.filter(announcement => announcement.id !== state.currentAnnouncement.id),
        currentAnnouncement: state.unconfirmedAnnouncements.filter(announcement => announcement.id !== state.currentAnnouncement.id)[0],
        showAnnouncements: state.unconfirmedAnnouncements.filter(announcement => announcement.id !== state.currentAnnouncement.id).length > 0,
      }
    case actions.SET:
      return {
        ...state,
        editable: action.announcement.attributes,
        modalEdit: true
      }
    case actions.TOGGLE_MODAL_FILTER:
      return {
        ...state,
        modalFilter: !state.modalFilter,
      }
    case actions.TOGGLE_MODAL_WHO_LIKED:
        return {
          ...state,
          currentAnnouncementWhoLiked: action.announcement,
          modalWhoLiked: !state.modalWhoLiked,
        }
    case actions.SAVE_FILTER_FORM:
      return {
        ...state,
        filterFormValues: action.params,
      }
    case actions.OPEN_MODAL_NEW:
      return {
        ...state,
        modalNew: true,
      }
    case actions.CLOSE_MODAL_NEW:
      return {
        ...state,
        modalNew: false,
      }
    case actions.TOGGLE_MODAL_NEW:
      return {
        ...state,
        modalNew: !state.modalNew,
      }
    case actions.TOGGLE_MODAL_EDIT:
      return {
        ...state,
        modalEdit: !state.modalEdit,
      }
    case actions.CLOSE_MODAL_EDIT:
      return {
        ...state,
        editable: {},
        modalEdit: false,
      }
    case actions.CREATE_SUCCESS:
      return {
        ...state,
        announcements: [
          action.data,
          ...state.announcements,
        ],
        formValues: initForm,
        modalNew: false,
      }
    case actions.UPDATED_SUCCESS:
      return {
        ...state,
        announcements: state.announcements.map(announcement => announcement.id === action.data.id ?
          { ...action.data } :
          announcement
        ),
        modalEdit: false,
      }
    case PURGE:
      return initState;
    case actions.ERROR:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      }
    default:
      return state;
  }
}
