import React from 'react';
import asyncComponent from '../../helpers/AsyncFunc';
import Route from '../../components/utility/customRoute';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('./newProtocol')),
  },
  {
    path: ':id/detalhes',
    component: asyncComponent(() => import('./protocols/Show')),
  },
  {
    path: 'lista',
    component: asyncComponent(() => import('./protocols')),
  },
  {
    path: 'address',
    component: asyncComponent(() => import('../Address')),
  },
  {
    path: 'profile',
    component: asyncComponent(() => import('./Profile')),
  }
];

const AppRouter = ({ url, style }) => {
  return (
    <div style={style}>
      {routes.map(singleRoute => {
        const { path, exact, ...otherProps } = singleRoute;
        return (
          <Route
            exact={exact !== false}
            key={singleRoute.path}
            path={`${url}/${path}`}
            {...otherProps}
          />
        );
      })}
    </div>
  );
};

export default AppRouter;
