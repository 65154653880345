import { select, all, call, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
import draftActions from '../draft/actions';
import { API } from '../../settings';
import {
  fetchApi,
  postApi,
  putApi,
  stringifyQueryParams,
  downloadApi,
} from '../../helpers/api';
import { notification } from '../../components';

const defaultMessages = {
  400: 'Há erros no formulário',
  404: 'Não encontrado',
  500: 'Erro interno. Tente novamente mais tarde',
  403: 'Você não possui acesso',
};

function* fetchTickets(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    const { data, pagination } = yield call(
      fetchApi,
      `${
        API.url
      }/institutions/${institutionId}/tickets.json?${stringifyQueryParams(
        action.params
      )}`
    );

    yield put({ type: actions.FETCH_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* fetchTicketsReceived(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    const { data, pagination } = yield call(
      fetchApi,
      `${
        API.url
      }/institutions/${institutionId}/tickets/received.json?${stringifyQueryParams(
        action.params
      )}`
    );

    yield put({ type: actions.FETCH_RECEIVED_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* fetchTicketsRelatives(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    const { data, pagination } = yield call(
      fetchApi,
      `${
        API.url
      }/institutions/${institutionId}/tickets/relative.json?${stringifyQueryParams(
        action.params
      )}`
    );

    yield put({ type: actions.FETCH_RELATIVES_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* fetchResources(action) {
  try {
    const { institutionId } = action;

    const sessionInstitutionId = yield select(
      state => state.session.user.data.attributes.institutionId
    );

    const id = institutionId || sessionInstitutionId;

    const [subjects, sectors, categories] = yield all([
      call(fetchApi, `${API.url}/resources/institutions/${id}/subjects.json`),
      call(fetchApi, `${API.url}/resources/institutions/${id}/sectors.json`),
      call(fetchApi, `${API.url}/resources/institutions/${id}/categories.json`),
    ]);

    yield put({
      type: actions.FETCH_RESOURCES_SUCCESS,
      subjects: subjects.data,
      sectors: sectors.data,
      categories: categories.data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* fecthMultipleUsersBySector(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);
    const { data } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/sectors/users.json`,
      { sector_ids: action.sector_ids },
    )
    yield put({ type: actions.FETCH_USERS_BY_SECTION_SUCCESS, data });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* fetchUsersBySector(action) {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/resources/sectors/${action.id}/sector_users.json`
    );

    yield put({ type: actions.FETCH_USERS_BY_SECTION_SUCCESS, data });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* createTicket(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/tickets/`,
      action.form
    );

    notification('success', 'Ticket cadastrado com sucesso');
    yield put({ type: actions.FETCH_REQUEST });
    if (action.draftId) {
      yield put ({ type: draftActions.REMOVE_DRAFT, uuid: action.draftId })
    }
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* fetchTicket(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    const { data, included } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/tickets/${action.id}`
    );

    yield put({ type: actions.SET_SUCCESS, data, included });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      errorStatus: error.response && error.response.status,
      errors: error.response && error.response.data && error.response.data.error,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* createReply(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/replies`,
      action.form
    );

    notification('success', 'Comentário feito com sucesso');
    yield put({ type: actions.SET, id: action.form.get('reply[ticket_id]') });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* forwardTicket(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/tickets/${action.id}/forwards`,
      action.form
    );

    notification('success', 'Comunicado encaminhado com sucesso');
    yield put({ type: actions.SET, id: action.id });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* completeTicket(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    const { data, included } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/tickets/${action.id}/complete`,
      { forward: action.form }
    );

    notification('success', 'Ticket concluído com sucesso');
    yield put({ type: actions.SET_SUCCESS, data, included });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

// TODO remove this method, but test before delete
function* changeTicket(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    const { data, included } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/tickets/${action.id}/change_status`,
      { status: action.status }
    );

    notification('success', 'Status do ticket alterado com sucesso');
    yield put({ type: actions.CHANGE_STATUS_TICKET_SUCCESS, data, included });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* transferTicket(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    const { data, included } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/tickets/${action.id}/transfer`,
      action.form
    );

    notification('success', 'CI transferida com sucesso');
    yield put({ type: actions.TRANSFER_SUCCESS, data, included });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* addAttachmentTicket(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    const { data, included } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/tickets/${action.id}/send_attachments`,
      action.form
    );

    notification('success', 'Arquivo adicionado com sucesso');
    yield put({ type: actions.ADD_ATTACHMENT_SUCCESS, data, included });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* changeExpiration(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    const { data, included } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/tickets/${action.id}/change_expiration`,
      action.form
    );

    notification('success', 'Data de vencimento foi alterada com sucesso');
    yield put({ type: actions.CHANGE_EXPIRATION_SUCCESS, data, included });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* updateTicket(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    const { data, included } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/tickets/${action.id}`,
      action.form
    );

    notification('success', 'Descrição alterada com sucesso');
    yield put({ type: actions.SET_SUCCESS, data, included });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* reopenTicket(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    const { data, included } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/tickets/${action.id}/reopen`,
      action.form
    );

    notification('success', 'Comunicado reaberto!');
    yield put({ type: actions.SET_SUCCESS, data, included });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* createDeadline(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/deadlines`,
      action.form
    );
    yield put({ type: actions.SET, id: action.form.requestable_id });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* answerDeadline(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/deadlines/${action.id}/answer`,
      action.form
    );

    yield put({ type: actions.SET, id: action.ticketId });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  } finally {
    yield put({ type: actions.RESET_LOADING });
  }
}

function* downloadPdfReport(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const blob = yield call(
      downloadApi,
      `${API.url}/institutions/${institutionId}/tickets/${action.id}/report`
    );
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${action.protocol}.pdf`);
    document.body.appendChild(link);
    link.click();

  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  } finally {
    yield put({ type: actions.DOWNLOAD_REPORT_SUCCESS });
  }
}

function* toggleReadReply(action) {
  try {
    const userType = yield select(state => state.session.user.data.type);
    const institutionId = userType === 'externalUser' ?
    yield select(state => state.Ombudsman.ombudsman.id) :
    yield select(state => state.Dashboard.currentInstitution);

    const { data } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/replies/${action.id}/read`,
    );

    yield put({ type: actions.SET, id: data.attributes.ticketId });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.FETCH_REQUEST, fetchTickets),
    yield takeEvery(actions.FETCH_RESOURCES, fetchResources),
    yield takeEvery(actions.FETCH_RECEIVED_REQUEST, fetchTicketsReceived),
    yield takeEvery(actions.FETCH_RELATIVES_REQUEST, fetchTicketsRelatives),
    yield takeEvery(actions.FETCH_USERS_BY_SECTION, fetchUsersBySector),
    yield takeEvery(actions.CREATE, createTicket),
    yield takeEvery(actions.CREATE_REPLY, createReply),
    yield takeEvery(actions.SET, fetchTicket),
    yield takeEvery(actions.FORWARD_TICKET, forwardTicket),
    yield takeEvery(actions.COMPLETE_TICKET, completeTicket),
    yield takeEvery(actions.CHANGE_STATUS_TICKET, changeTicket),
    yield takeEvery(actions.TRANSFER, transferTicket),
    yield takeEvery(actions.ADD_ATTACHMENT, addAttachmentTicket),
    yield takeEvery(actions.CHANGE_EXPIRATION_REQUEST, changeExpiration),
    yield takeEvery(actions.CREATE_DEALINE_REQUEST, createDeadline),
    yield takeEvery(actions.ANSWER_DEADLINE_REQUEST, answerDeadline),
    yield takeEvery(actions.DOWNLOAD_REPORT, downloadPdfReport),
    yield takeEvery(actions.REOPEN_TICKET, reopenTicket),
    yield takeEvery(actions.EDIT_DESCRIPTION, updateTicket),
    yield takeEvery(actions.FETCH_MULTIPLE_USERS, fecthMultipleUsersBySector),
    yield takeEvery(actions.TOGGLE_READ_REPLY, toggleReadReply),
  ]);
}
