import { PURGE } from 'redux-persist';
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initState = {
  neighborhoods: [],
  editableNeighborhood: {},
  filterFormValues: {},
  loading: false,
  loadingCsv: false,
  modalNewNeighborhood: false,
  modalEditNeighborhood: false,
  pagination: {
    itemsPerPage: 10,
  },
};

const getNeighborhoodsSuccess = (state, action) => ({
  ...state,
  neighborhoods: action.neighborhoods,
  pagination: action.pagination,
  modalNewNeighborhood: false,
  modalEditNeighborhood: false,
});

const createNeighborhood = (state) => ({
  ...state,
  modalNewNeighborhood: true,
});

const uploadCsv = (state) => ({
  ...state,
  loadingCsv: true,
});

const uploadCsvSuccess = (state) => ({
  ...state,
  loadingCsv: false,
});

const openNewModal = (state) => ({
  ...state,
  modalNewNeighborhood: true,
});

const openEditModal = (state, action) => {
  return {
    ...state,
    modalEditNeighborhood: true,
    editableNeighborhood: {
      id: action.neighborhood.id,
      name: action.neighborhood.name,
      state_id: { value: action.neighborhood.stateId.toString() },
      city_id: { value: action.neighborhood.cityId.toString() },
    },
  };
};

const createSuccess = (state) => ({
  ...state,
  loading: false,
  modalNewNeighborhood: false,
});

const closeNewModal = (state) => ({
  ...state,
  modalNewNeighborhood: false,
});

const closeEditModal = (state) => ({
  ...state,
  modalEditNeighborhood: false,
});

const editNeighborhood = (state) => ({
  ...state,
  modalEditNeighborhood: true,
});

const editSuccess = (state) => ({
  ...state,
  loading: false,
  modalEditNeighborhood: false,
});

const saveFilter = (state, action) => ({
  ...state,
  filterFormValues: action.params,
})

const purgeState = () => initState;

const HANDLERS = {
  [actions.GET_NEIGHBORHOODS_SUCCESS]: getNeighborhoodsSuccess,
  [actions.CREATE_NEIGHBORHOOD]: createNeighborhood,
  [actions.CREATE_NEIGHBORHOOD_SUCCESS]: createSuccess,
  [actions.OPEN_MODAL_NEW]: openNewModal,
  [actions.OPEN_MODAL_EDIT]: openEditModal,
  [actions.EDIT_NEIGHBORHOOD]: editNeighborhood,
  [actions.EDIT_NEIGHBORHOOD_SUCCESS]: editSuccess,
  [actions.CLOSE_MODAL_EDIT]: closeEditModal,
  [actions.CLOSE_MODAL_NEW]: closeNewModal,
  [actions.UPLOAD_CSV]: uploadCsv,
  [actions.UPLOAD_CSV_SUCCESS]: uploadCsvSuccess,
  [actions.SAVE_FILTER]: saveFilter,
  [PURGE]: purgeState,
};

export default createMyReducer(initState, HANDLERS);
