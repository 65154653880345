import React from 'react';
import asyncComponent from '../../../helpers/AsyncFunc';
import Route from '../../../components/utility/customRoute';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../../ombudsman/dashboard')),
  },
  {
    path: 'storage',
    component: asyncComponent(() => import('../../Institution/storage')),
  },
  {
    path: 'sectors',
    component: asyncComponent(() => import('../../Sector')),
  },
  {
    path: 'sectors/:id',
    component: asyncComponent(() => import('../../Sector/Show')),
  },
  {
    path: 'subjects',
    component: asyncComponent(() => import('../../Subject')),
  },
  {
    path: 'categories',
    component: asyncComponent(() => import('../../Category')),
  },
  {
    path: 'todo-lists',
    component: asyncComponent(() => import('../../UserTodoList')),
  },
  {
    path: 'users',
    component: asyncComponent(() => import('../../User')),
  },
  {
    path: 'users/:id',
    component: asyncComponent(() => import('../../User/Show')),
  },
  {
    path: 'users-ouvidoria',
    component: asyncComponent(() => import('../../User/Ouvidoria')),
  },
  {
    path: 'users-ouvidoria/:id',
    component: asyncComponent(() => import('../../User/Ouvidoria/Show')),
  },
  {
    path: 'questions',
    component: asyncComponent(() => import('../../Questions')),
  },
  {
    path: 'profile',
    component: asyncComponent(() => import('../../Profile')),
  },
  {
    path: 'locker',
    component: asyncComponent(() => import('../../Locker')),
  },
  {
    path: 'restore',
    component: asyncComponent(() => import('../../Restore')),
  },
  {
    path: 'protocols',
    component: asyncComponent(( ) => import('../../UserDashboard/protocols')),
  },
  {
    path: 'survey',
    component: asyncComponent(( ) => import('../../Page/satisfactionSurvey')),
  },
  {
    path: 'new-slides',
    component: asyncComponent(() => import('../../CreateSlides')),
  },
  {
    path: 'grouping',
    component: asyncComponent(() => import('../../Grouping')),
  },
  {
    path: 'grouping/:id',
    component: asyncComponent(() => import('../../Grouping/Show')),
  },
  {
    path: 'protocols/:id/detalhes',
    component: asyncComponent(() => import('../../UserDashboard/protocols/Show')),
  },
  {
    path: 'ombudsman-report',
    component: asyncComponent(() => import('../../OmbudsmanReport')),
  },
  {
    path: 'manager-config',
    component: asyncComponent(() => import('../../ManagerConfig')),
  },
];

const AppRouter = ({ url, style }) => {
  localStorage.setItem('currentMode', 'ombudsman');
  return (
    <div style={style}>
      {routes.map(singleRoute => {
        const { path, exact, ...otherProps } = singleRoute;
        return (
          <Route
            exact={exact !== false}
            key={singleRoute.path}
            path={`${url}/${path}`}
            {...otherProps}
          />
        );
      })}
    </div>
  );
};

export default AppRouter;
