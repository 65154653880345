import { select, all, call, takeEvery, put } from 'redux-saga/effects';
import { reset } from 'redux-form';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi, postApi, putApi, stringifyQueryParams } from '../../helpers/api'
import { notification } from '../../components';

function* fetchTodoLists(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/todo_lists.json?${stringifyQueryParams(action.params)}`,
    );

    yield put({ type: actions.FETCH_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* fetchTicketTodoLists(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const [users, todoList] = yield all([
      call(fetchApi, `${API.url}/resources/institutions/${institutionId}/users.json`),
      call(fetchApi, `${API.url}/resources/tickets/${action.ticketId}/todo_lists.json?${stringifyQueryParams(action.params)}`),
    ])

    const { data, pagination } = todoList

    yield put({
      type: actions.FETCH_TICKET_SUCCESS,
      data,
      pagination,
      users: users.data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* createTodoList(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/todo_lists/`,
      action.form,
    );
    notification('success', 'Tarefa criada com sucesso');
    yield put(reset('TodoListForm'));
    yield put({ type: actions.FETCH_REQUEST })
    if (action.form.get('todo_list[ticket_id]')) {
      yield put({
        type: actions.FETCH_TICKET_REQUEST,
        ticketId: action.form.get('todo_list[ticket_id]'),
      });
    }
  } catch (error) {
    notification('error', `${error.response.data.errors.full_messages}`);
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages
    });
  }
}

function* updateTodoList(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/todo_lists/${action.id}`,
      action.form,
    );
    notification('success', `Tarefa ${data.attributes.title} atualizada com sucesso`);
    yield put({ type: actions.FETCH_TICKET_REQUEST, ticketId: data.attributes.ticketId });
  } catch (error) {
    notification('error', `${error.response.data.errors.full_messages}`);
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages
    });
  }
}

function* updateUserTodoList(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/todo_lists/${action.id}`,
      action.form,
    );
    notification('success', `Tarefa ${data.attributes.title} atualizada com sucesso`);
    yield put({ type: actions.FETCH_REQUEST });
  } catch (error) {
    notification('error', `${error.response.data.errors.full_messages}`);
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages
    });
  }
}

function* getTodoList({ id }) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)

    const { data } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/todo_lists/${id}`,
    );
    yield put({ type: actions.SET, todoList: data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* updateStatus({id, form}) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/todo_lists/${id}/change_status`,
      form,
    );
    notification('success', `Tarefa ${data.attributes.title} atualizada com sucesso`);
    yield put({ type: actions.UPDATED_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages
    });
  }
}

function* updateUserStatus({id, form}) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/todo_lists/${id}/change_status`,
      form,
    );
    notification('success', `Tarefa ${data.attributes.title} atualizada com sucesso`);
    yield put({ type: actions.CHECK_TODO_LIST_USER_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages
    });
  }
}

function* toggleOrder(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const order = yield select(state => state.TodoList.order);
    const query = `field=${action.field}&order=${order}`;
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/todo_lists.json?${query}`,
    );

    yield put({ type: actions.FETCH_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.FETCH_REQUEST, fetchTodoLists),
    yield takeEvery(actions.FETCH_TICKET_REQUEST, fetchTicketTodoLists),
    yield takeEvery(actions.CREATE, createTodoList),
    yield takeEvery(actions.UPDATE, updateTodoList),
    yield takeEvery(actions.UPDATE_USER_TODO, updateUserTodoList),
    yield takeEvery(actions.CHECK_TODO_LIST_REQUEST, updateStatus),
    yield takeEvery(actions.CHECK_TODO_LIST_USER_REQUEST, updateUserStatus),
    yield takeEvery(actions.TOGGLE_ORDER, toggleOrder),
    yield takeEvery(actions.GET_TODO_LIST, getTodoList)
  ])
}
