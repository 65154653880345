import { PURGE } from 'redux-persist';
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initState = {
  icons: [],
  iconsList: [],
  editableIcon: {},
  filterFormValues: {},
  loading: false,
  modalNewIcon: false,
  modalEditIcon: false,
  pagination: {
    itemsPerPage: 10,
  },
};

const getIconsSuccess = (state, action) => ({
  ...state,
  icons: action.icons,
  pagination: action.pagination,
  modalNewIcon: false,
  modalEditIcon: false,
  editableIcon: {},
});


const getListIcons = (state, action) => ({
  ...state,
  iconsList: action.icons,
});

const createIcon = (state) => ({
  ...state,
  modalNewIcon: true,
});

const openNewModal = (state) => ({
  ...state,
  modalNewIcon: true,
});

const openEditModal = (state, action) => {
  return {
    ...state,
    modalEditIcon: true,
    editableIcon: {
      id: action.icon.id,
      name: action.icon.name,
      status: action.icon.status === 'active',
      photo: null,
    },
  };
};

const createSuccess = (state) => ({
  ...state,
  loading: false,
  modalNewIcon: false,
});

const closeNewModal = (state) => ({
  ...state,
  modalNewIcon: false,
});

const closeEditModal = (state) => ({
  ...state,
  modalEditIcon: false,
});

const editIcon = (state) => ({
  ...state,
  modalEditIcon: true,
});

const editSuccess = (state) => ({
  ...state,
  loading: false,
  modalEditIcon: false,
});

const saveFilter = (state, action) => ({
  ...state,
  filterFormValues: action.params,
})

const purgeState = () => initState;

const HANDLERS = {
  [actions.GET_ICONS_SUCCESS]: getIconsSuccess,
  [actions.LIST_ICONS_SUCCESS]: getListIcons,
  [actions.CREATE_ICON]: createIcon,
  [actions.CREATE_ICON_SUCCESS]: createSuccess,
  [actions.OPEN_MODAL_NEW]: openNewModal,
  [actions.OPEN_MODAL_EDIT]: openEditModal,
  [actions.EDIT_ICON]: editIcon,
  [actions.EDIT_ICON_SUCCESS]: editSuccess,
  [actions.CLOSE_MODAL_EDIT]: closeEditModal,
  [actions.CLOSE_MODAL_NEW]: closeNewModal,
  [actions.SAVE_FILTER]: saveFilter,
  [PURGE]: purgeState,
};

export default createMyReducer(initState, HANDLERS);
