const actions = {
  GET_NEIGHBORHOODS: 'GET_NEIGHBORHOODS/neighborhoods',
  GET_NEIGHBORHOODS_SUCCESS: 'GET_NEIGHBORHOODS_SUCCESS/neighborhoods',
  OPEN_MODAL_NEW: 'OPEN_MODAL_NEW/neighborhoods',
  CLOSE_MODAL_NEW: 'CLOSE_MODAL_NEW/neighborhoods',
  CREATE_NEIGHBORHOOD: 'CREATE_NEIGHBORHOOD/neighborhoods',
  CREATE_NEIGHBORHOOD_SUCCESS: 'CREATE_NEIGHBORHOOD_SUCCESS/neighborhoods',
  EDIT_NEIGHBORHOOD: 'EDIT_NEIGHBORHOOD/neighborhoods',
  OPEN_MODAL_EDIT: 'OPEN_MODAL_EDIT/neighborhoods',
  CLOSE_MODAL_EDIT: 'CLOSE_MODAL_EDIT/neighborhoods',
  UPLOAD_CSV: 'UPLOAD_CSV/neighborhoods',
  UPLOAD_CSV_SUCCESS: 'UPLOAD_CSV_SUCCESS/neighborhoods',
  EDIT_NEIGHBORHOOD_SUCCESS: 'EDIT_NEIGHBORHOOD_SUCCESS/neighborhoods',
  UPDATE_NEIGHBORHOOD: 'UPDATE_NEIGHBORHOOD/neighborhoods',
  SAVE_FILTER: 'SAVE_FILTER/neighborhoods',
  ERROR: 'ERROR/STATES',
  getNeighborhoods: (params) => ({ type: actions.GET_NEIGHBORHOODS, params }),
  createNeighborhood: form => ({ type: actions.CREATE_NEIGHBORHOOD, form }),
  editNeighborhood: (id, form) => ({ type: actions.UPDATE_NEIGHBORHOOD, id, form }),
  openNewModal: () => ({ type: actions.OPEN_MODAL_NEW }),
  openEditModal: (neighborhood) => ({ type: actions.OPEN_MODAL_EDIT, neighborhood }),
  closeNewModal: () => ({ type: actions.CLOSE_MODAL_NEW }),
  closeEditModal: () => ({ type: actions.CLOSE_MODAL_EDIT }),
  saveFilter: params => ({ type: actions.SAVE_FILTER, params }),
  uploadCsv: file => ({ type: actions.UPLOAD_CSV, file }),
};

export default actions;
