import { PURGE } from "redux-persist";
import actions from './actions';

const initState = {
  countNotification: 0,
  unReadNotifications: [],
  notifications: [],
  pagination: {
    itemsPerPage: 10,
  },
  loading: false,
  loadingUnread: false,
  errors: null,
};

export default function (state = initState, action) {
  switch (action.type) {
    case actions.FETCH_UNREAD_REQUEST:
      return {
        ...state,
        loadingUnread: true,
      }
    case actions.FETCH_UNREAD_REQUEST_SUCCESS:
      return {
        ...state,
        countNotification: action.included,
        unReadNotifications: action.data.data,
        loadingUnread: false
      }
    case actions.FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.FETCH_SUCCESS:
      return {
        ...state,
        notifications: action.data,
        pagination: action.pagination,
        loading: false
      }
    case actions.UPDATED_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map(notification => notification.id === action.data.id ?
          { ...action.data } :
          notification
        )
      }
    case actions.UPDATE_NOTIFICATION_WEBSOCKET:
      return {
        ...state,
        unReadNotifications: [action.notification, ...state.unReadNotifications],
        countNotification: [action.notification, ...state.unReadNotifications].length,
      };
    case actions.UPDATE_NOTIFICATIONS_WEBSOCKET:
      return {
        ...state,
        unReadNotifications: action.notifications,
        countNotification: action.notifications.length,
      };
    case PURGE:
      return initState;
    case actions.ERROR_NOTIFICATION:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      }
    default:
      return state;
  }
}
