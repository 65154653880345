const actions = {
  FETCH_REQUEST: 'FETCH_REQUEST/todoLists',
  FETCH_SUCCESS: 'FETCH_SUCCESS/todoLists',
  FETCH_TICKET_REQUEST: 'FETCH_TICKET_REQUEST/todoLists',
  FETCH_TICKET_SUCCESS: 'FETCH_TICKET_SUCCESS/todoLists',
  GET_TODO_LIST: 'GET_TODO_LIST/todoLists',
  SET: 'SET/todoLists',
  CREATE: 'ADD_ITEM/todoLists',
  CREATE_SUCCESS: 'CREATE_SUCCESS/todoLists',
  UPDATE: 'UPDATE/todoLists',
  UPDATE_USER_TODO: 'UPDATE_USER_TODO/todoLists',
  UPDATED_SUCCESS: 'UPDATED_SUCCESS/todoLists',
  SELECT_CURRENT: 'SELECT_CURRENT/todoLists',
  TOGGLE_VIEW: 'TOGGLE_VIEW/todoLists',
  EDIT: 'EDIT/todoLists',
  CHANGE_STATUS: 'CHANGE_STATUS/todoLists',
  TOGGLE_MODAL_NEW: 'TOGGLE_MODAL_NEW/todoLists',
  TOGGLE_MODAL_EDIT: 'TOGGLE_MODAL_EDIT/todoLists',
  CLOSE_MODAL_EDIT: 'CLOSE_MODAL_EDIT/todoLists',
  CHECK_TODO_LIST_REQUEST: 'CHECK_TODO_LIST_REQUEST/todoLists',
  CHECK_TODO_LIST_SUCCESS: 'CHECK_TODO_LIST_SUCCESS/todoLists',
  CHECK_TODO_LIST_USER_REQUEST: 'CHECK_TODO_LIST_USER_REQUEST/todoLists',
  CHECK_TODO_LIST_USER_SUCCESS: 'CHECK_TODO_LIST_USER_SUCCESS/todoLists',
  TOGGLE_ORDER: 'TOGGLE_ORDER/todoLists',
  ERROR: 'ERROR/todoLists',
  fetchRequest: params => ({ type: actions.FETCH_REQUEST, params }),
  getTodoList: id => ({ type: actions.GET_TODO_LIST, id }),
  fetchTicketRequest: (ticketId, params) => ({ type: actions.FETCH_TICKET_REQUEST, ticketId, params }),
  openModalNew: () => ({ type: actions.OPEN_MODAL_NEW }),
  closeModalNew: () => ({ type: actions.CLOSE_MODAL_NEW }),
  toggleModalNew: () => ({ type: actions.TOGGLE_MODAL_NEW }),
  closeModalEdit: () => ({ type: actions.CLOSE_MODAL_EDIT }),
  addTodoList: form => ({ type: actions.CREATE, form }),
  toggleModalEdit: todoList => ({ type: actions.SET, todoList }), // open edit modal
  changeTodoList: (id, form) => ({ type: actions.UPDATE, id, form }),
  changeUserTodoList: (id, form) => ({ type: actions.UPDATE_USER_TODO, id, form }),
  checkTodoList: (id, form) => ({ type: actions.CHECK_TODO_LIST_REQUEST, id, form }),
  checkTodoListToUser: (id, form) => ({ type: actions.CHECK_TODO_LIST_USER_REQUEST, id, form }),
  toggleOrder: (field) => ({ type: actions.TOGGLE_ORDER, field }),
};

export default actions;
