import { PURGE } from 'redux-persist';
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initState = {
  addresses: [],
  addressesList: [],
  addressesListExternalUsers: [],
  states: [],
  cities: [],
  neighborhoods: [],
  editableAddress: {},
  filterFormValues: {},
  loading: false,
  modalNewAddress: false,
  modalEditAddress: false,
  pagination: {
    itemsPerPage: 10,
  },
};

const getAddressesSuccess = (state, action) => ({
  ...state,
  addresses: action.addresses,
  pagination: action.pagination,
  modalNewAddress: false,
  modalEditAddress: false,
  editableAddress: {},
});


const getListAddresses = (state, action) => ({
  ...state,
  addressesList: action.addresses,
});

const getListAddressesExternalUsers = (state, action) => ({
  ...state,
  addressesListExternalUsers: action.addresses,
});

const getStates = (state, action) => ({
  ...state,
  states: action.states,
});

const getCities = (state, action) => {
  return {
  ...state,
  cities: action.cities,
  }
};

const getNeighborhoods = (state, action) => ({
  ...state,
  neighborhoods: action.neighborhoods,
});

const createAddress = (state) => ({
  ...state,
  modalNewAddress: true,
});

const openNewModal = (state) => ({
  ...state,
  modalNewAddress: true,
});

const openEditModal = (state, action) => {
  return {
    ...state,
    modalEditAddress: true,
    editableAddress: {
      ...action.address,
      city_id: { value: String(action.address.cityId) },
      state_id: { value: String(action.address.stateId) },
    },
  };
};

const createSuccess = (state) => ({
  ...state,
  loading: false,
  modalNewAddress: false,
});

const closeNewModal = (state) => ({
  ...state,
  modalNewAddress: false,
});

const closeEditModal = (state) => ({
  ...state,
  modalEditAddress: false,
});

const editAddress = (state) => ({
  ...state,
  modalEditAddress: true,
});

const editSuccess = (state) => ({
  ...state,
  loading: false,
  modalEditAddress: false,
});

const saveFilter = (state, action) => ({
  ...state,
  filterFormValues: action.params,
})

const purgeState = () => initState;

const HANDLERS = {
  [actions.GET_ADDRESSES_SUCCESS]: getAddressesSuccess,
  [actions.LIST_ADDRESSES_SUCCESS]: getListAddresses,
  [actions.LIST_ADDRESSES_EXTERNAL_USERS_SUCCESS]: getListAddressesExternalUsers,
  [actions.CREATE_ADDRESS]: createAddress,
  [actions.CREATE_ADDRESS_SUCCESS]: createSuccess,
  [actions.OPEN_MODAL_NEW]: openNewModal,
  [actions.OPEN_MODAL_EDIT]: openEditModal,
  [actions.EDIT_ADDRESS]: editAddress,
  [actions.EDIT_ADDRESS_SUCCESS]: editSuccess,
  [actions.CLOSE_MODAL_EDIT]: closeEditModal,
  [actions.CLOSE_MODAL_NEW]: closeNewModal,
  [actions.SAVE_FILTER]: saveFilter,
  [actions.GET_STATE_SUCCESS]: getStates,
  [actions.GET_CITIES_SUCCESS]: getCities,
  [actions.GET_NEIGHBORHOODS_SUCCESS]: getNeighborhoods,
  [PURGE]: purgeState,
};

export default createMyReducer(initState, HANDLERS);
