/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles,
  Button,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import { Delete as ActionDelete, CloudUpload } from '@material-ui/icons';

import './style.scss';

const styles = theme => ({
  dropItem: {
    borderColor: theme.palette.divider,
    background: theme.palette.background.default,
    color: theme.palette.text.disabled,
    textAlign: 'center'
  },
  uploadIconSize: {
    display: 'inline-block',
    '& svg': {
      width: 72,
      height: 72,
      fill: '#3f51b5',
    }
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    '& svg': {
      fill: theme.palette.common.white,
    }
  },
  button: {
    marginTop: 20,
  }
});

class MaterialDropZone extends React.Component {
  state = {
    openSnackBar: false,
    errorMessage: '',
    files: this.props.files, // eslint-disable-line
    acceptedFiles: this.props.acceptedFiles // eslint-disable-line
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.files.length) {
      this.setState({ files: [] })
    }
  }

  onDrop = (filesVal) => {
    const { files } = this.state;
    const { filesLimit, onChange } = this.props;
    let oldFiles = files;
    const filesLimitVal = filesLimit || 5;
    oldFiles = oldFiles.concat(filesVal);
    if (oldFiles.length > filesLimit) {
      this.setState({
        openSnackBar: true,
        errorMessage: `Não é possível fazer upload de mais de ${  filesLimitVal  } itens.`,
      });
    } else {
      onChange(oldFiles)
      this.setState({ files: oldFiles });
    }
  }

  onDropRejected = (filesVal) => {
    const { files } = this.state;
    const { acceptedFiles, sizeMb = '5MB', filesLimit } = this.props;
    const invalidType = filesVal.find(fileRejected => !acceptedFiles.find(af => !!fileRejected.type.match(af)));

    if (invalidType) {
      const size = invalidType.path.split('.').length - 1;
      const extension = invalidType.path.split('.')[size];

      this.setState({
        openSnackBar: true,
        errorMessage: `Arquivo com extensão .${extension} não é suportado.`,
      });
    } else if ((files.length + filesVal.length) > filesLimit) {
      this.setState({
        openSnackBar: true,
        errorMessage: `Não é possível fazer upload de mais de ${filesLimit} itens.`,
      });
    } else {
      this.setState({
        openSnackBar: true,
        errorMessage: `Arquivo muito grande, o tamanho máximo é ${sizeMb}`,
      });
    }
  }

  handleRequestCloseSnackBar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  handleRemove(file, fileIndex) {
    const thisFiles = this.state.files; // eslint-disable-line
    // This is to prevent memory leaks.
    window.URL.revokeObjectURL(file.preview);

    thisFiles.splice(fileIndex, 1);
    this.setState({ files: thisFiles });
  }

  render() {
    const {
      classes,
      showPreviews,
      maxSize,
      text,
      showButton,
    } = this.props;

    const {
      acceptedFiles,
      files,
      openSnackBar,
      errorMessage
    } = this.state;

    const fileSizeLimit = maxSize || 3000000;
    const deleteBtn = (file, index) => (
      <div className="middle">
        <IconButton onClick={() => this.handleRemove(file, index)}>
          <ActionDelete className="removeBtn" />
        </IconButton>
      </div>
    );

    const previews = (filesArray = []) => filesArray.map((file, index) => {
      return (
        <div
          key={index.toString()}
          className="imageContainer col fileIconImg"
        >
          <p><strong>{file.path || file.name}</strong></p>
          {deleteBtn(file, index)}
        </div>
      );
    });

    let dropzoneRef;

    return (
      <div>
        <Dropzone
          accept={acceptedFiles.join(',')}
          onDrop={this.onDrop}
          onDropRejected={this.onDropRejected}
          acceptClassName="stripes"
          rejectClassName="rejectStripes"
          maxSize={fileSizeLimit}
          ref={(node) => { dropzoneRef = node; }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={classNames(classes.dropItem, 'dropZone')}>
              <div className="dropzoneTextStyle">
                <input {...getInputProps()}/>
                <p className="dropzoneParagraph">{text}</p>
                <div className={classes.uploadIconSize}>
                  <CloudUpload color="primary" />
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        {showButton && (
          <Button
            className={classes.button}
            fullWidth
            variant="contained"
            onClick={() => {
              dropzoneRef.open();
            }}
            color="primary"
          >
            Clique para fazer upload
            <span className={classes.rightIcon}>
              <CloudUpload />
            </span>
          </Button>
        )}
        <div className="row preview">
          {showPreviews && previews(files)}
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openSnackBar}
          message={errorMessage}
          autoHideDuration={4000}
          onClose={this.handleRequestCloseSnackBar}
        />
      </div>
    );
  }
}

MaterialDropZone.propTypes = {
  files: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
  acceptedFiles: PropTypes.array,
  showPreviews: PropTypes.bool.isRequired,
  showButton: PropTypes.bool,
  maxSize: PropTypes.number.isRequired,
  filesLimit: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

MaterialDropZone.defaultProps = {
  acceptedFiles: [],
  showButton: false,
};

export default withStyles(styles)(MaterialDropZone);
