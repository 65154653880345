import { call, all, takeEvery, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { sessionService } from 'redux-react-session';
// import Cookies from "js-cookie";
import { API } from '../../settings';
import { postApi, putApi } from '../../helpers/api';
import { notification } from '../../components';
import { getToken, clearToken } from '../../helpers/utility';
import setAuthorizationToken from '../../helpers/setAuthorizationToken';
import actions from './actions';
import dashboardActions from '../dashboard/actions';

const defaultMessages = {
  400: 'Há erros no formulário',
  404: 'Não encontrado',
  500: 'Erro interno. Tente novamente mais tarde',
  403: 'Você não possui acesso',
};

export function* loginRequest({ payload }) {
  try {
    const { data } = yield call(postApi, `${API.url}/sessions`, payload);

    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: { token: data.attributes.authToken },
      profile: data.attributes.user,
    });
  } catch (error) {
    const {
      response: {
        data: {
          errors = { default: 'Não foi possível realizar login. Tente atualizar a página' },
        } = {},
      } = {},
    } = error;
    const messages = Object.values(errors)
    notification('error', messages[0]);
    yield put({ type: actions.LOGIN_ERROR });
  }
}

export function* loginSuccess({ payload, profile }) {
  yield localStorage.setItem('id_token', payload.token);
  yield setAuthorizationToken(payload.token);
  yield sessionService.saveSession(payload.token);
  yield sessionService.saveUser(profile);
  if (profile.data.attributes.role !== 'admin') {
    yield dashboardActions.changeInstitution(profile.data.attributes.institutionId);
    yield put({
      type: dashboardActions.CHANGE_INSTITUTION_REQUEST,
      id: profile.data.attributes.institutionId,
    });
  }
  const hasCiDigital = profile.data.attributes.modules.includes('ci_digital');
  // Cookies.set('USER_DATA', JSON.stringify(profile), { path: '/' });
  if (hasCiDigital) {
    yield put(push('/dashboard'));
  } else {
    yield put(push('/ouvidoria/dashboard'));
  }
}

export function* logout(action) {
  yield clearToken();
  yield sessionService.invalidateSession()
  yield sessionService.deleteSession();
  yield sessionService.deleteUser();
  localStorage.removeItem('institutionId');
  localStorage.removeItem('ombudsman');
  if (action.slug) {
    yield put(push(`/ouvidoria/${action.slug}`));
  } else {
    yield put(push('/admin'));
  }
}

export function* logoutOmbudsman(action) {
  const ombudsman = JSON.parse(localStorage.getItem('ombudsman'));
  const { slug } = ombudsman.attributes;

  yield clearToken();
  yield sessionService.invalidateSession()
  yield sessionService.deleteSession();
  yield sessionService.deleteUser();
  localStorage.removeItem('institutionId');
  if (!action.skip) {
    yield put(push(`/ouvidoria/${slug}`));
  }
}

export function* checkAuthorization() {
  const token = getToken();
  if (token) {
    yield setAuthorizationToken(token);
  }
}

export function* forgotPassword({ payload }) {
  try {
    const { data } = yield call(postApi, `${API.url}/forgot-password`, payload);

    yield put({
      type: actions.FORGOT_PASSWORD_SUCCESS,
      message: data,
    });
    notification('success', data.message);
  } catch (error) {
    notification('error', error.response.data.data.message);
    yield put({ type: actions.LOGIN_ERROR });
  }
}

export function* resetPassword(action) {
  try {
    const { data } = yield call(
      putApi,
      `${API.url}/reset-password?token=${action.token}`,
      action.form
    );

    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: { token: data.attributes.authToken },
      profile: data.attributes.user,
    });
  } catch (error) {
    const { response: { data, status } } = error;
    const errors = (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({ type: actions.LOGIN_ERROR });
  }
}

function* userUnauthorized() {
  yield put(push('/dashboard'));
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.CHECK_AUTHORIZATION, checkAuthorization),
    yield takeEvery(actions.USER_UNAUTHORIZED, userUnauthorized),
    yield takeEvery(actions.LOGIN_REQUEST, loginRequest),
    yield takeEvery(actions.LOGIN_SUCCESS, loginSuccess),
    yield takeEvery(actions.LOGOUT, logout),
    yield takeEvery(actions.LOGOUT_OMBUDSMAN, logoutOmbudsman),
    yield takeEvery(actions.FORGOT_PASSWORD_REQUEST, forgotPassword),
    yield takeEvery(actions.RESET_PASSWORD_REQUEST, resetPassword),
  ]);
}
