import React from 'react';
import { Provider } from 'react-redux';
import { sessionService } from 'redux-react-session';
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components';
import { JssProvider } from 'react-jss';
import { create } from 'jss';
// import preset from 'jss-preset-default';
import { jssPreset } from '@material-ui/styles';
import rtl from 'jss-rtl';
import themes from './settings/themes';
import { themeConfig } from './settings';
import { store, history, persistor } from './redux/store';
import Boot from './redux/boot';
import Router from './router';


if (!global.__INSERTION_POINT__) {
  global.__INSERTION_POINT__ = true;
  const styleNode = document.createComment('insertion-point-jss');

  if (document.head) {
    document.head.insertBefore(styleNode, document.head.firstChild);
  }
}

// Initialize session
sessionService
  .initSessionService(store, { driver: 'LOCALSTORAGE' })
  .then(() => console.log('Redux React Session is ready and a session was refreshed from your storage'))
  .catch(() => console.log('Redux React Session is ready and there is no session in your storage'))

// const jss = create(preset(), rtl());
const jss = create(jssPreset(), rtl());
jss.options.insertionPoint = 'insertion-point-jss';

const MetaAdmin = () => {
  return (
    <JssProvider jss={jss}>
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <Provider store={store}>
          <PersistGate loading persistor={persistor}>
            <Router history={history} />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </JssProvider>
  );
};

Boot()
  .then(() => MetaAdmin())
  .catch(error => console.error(error));

export default MetaAdmin;
