import { all, call, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi, postApi, putApi, stringifyQueryParams } from '../../helpers/api';
import { notification } from '../../components';

function* fetchInstitutions() {
  try {
    const { data } = yield call(fetchApi, `${API.url}/resources/institutions/children_institutions.json`);

    yield put({ type: actions.FETCH_INSTITUTIONS_SUCCESS, data });

  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* fetchTickets(action) {
  try {
    const { data, pagination } = yield call(fetchApi, `${API.url}/trades.json?${stringifyQueryParams(action.params)}`);

    yield put({ type: actions.FETCH_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* fetchResources(action) {
  try {
    const [subjects, sectors, categories] = yield all([
      call(fetchApi, `${API.url}/resources/institutions/${action.institutionId}/subjects.json`),
      call(fetchApi, `${API.url}/resources/institutions/${action.institutionId}/sectors.json`),
      call(fetchApi, `${API.url}/resources/institutions/${action.institutionId}/categories.json`),
    ])

    yield put({
      type: actions.FETCH_RESOURCES_SUCCESS,
      subjects: subjects.data,
      sectors: sectors.data,
      categories: categories.data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* fetchUsersBySector(action) {
  try {
    const { data } = yield call(fetchApi, `${API.url}/resources/sectors/${action.id}/sector_users.json`);

    yield put({ type: actions.FETCH_USERS_BY_SECTION_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors.full_messages,
    });
  }
}

function* createTicket(action) {
  try {
    const { data } = yield call(postApi, `${API.url}/tickets/`, action.form);

    notification('success', 'Oficio cadastrado com sucesso');
    yield put({ type: actions.CREATE_SUCCESS, data });
  } catch (error) {
    notification('error', `${error.response.data.errors.full_messages}`);
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages
    });
  }
}

function* fetchTicket(action) {
  try {
    const { data, included } = yield call(
      fetchApi,
      `${API.url}/tickets/${action.id}`
    );

    yield put({ type: actions.SET_SUCCESS, data, included });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors: error.response.data.error,
    });
  }
}

function* createReply(action) {
  try {
    const { data, included } = yield call(
      postApi,
      `${API.url}/replies`,
      action.form
    );

    notification('success', 'Comentário feito com sucesso');
    yield put({ type: actions.CREATE_REPLY_SUCCESS, data, included });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors: error.response.data.errors.full_messages,
    });
  }
}

function* forwardTicket(action) {
  try {
    const { data } = yield call(
      postApi,
      `${API.url}/tickets/${action.form.id}/forward`,
      { forward: action.form }
    );

    notification('success', 'Ticket encaminhado com sucesso');
    yield put({ type: actions.FORWARD_TICKET_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors: error.response.data.errors.full_messages,
    });
  }
}

function* completeTicket(action) {
  try {
    const { data, included } = yield call(
      putApi,
      `${API.url}/tickets/${action.id}/complete`,
      { forward: action.form }
    );

    notification('success', 'Ticket concluído com sucesso');
    yield put({ type: actions.SET_SUCCESS, data, included });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors: error.response.data.errors.full_messages,
    });
  }
}

function* changeTicket(action) {
  try {
    const { data, included } = yield call(
      putApi,
      `${API.url}/tickets/${action.id}/change_status`,
      { status: action.status }
    );

    notification('success', 'Status do ticket alterado com sucesso');
    yield put({ type: actions.CHANGE_STATUS_TICKET_SUCCESS, data, included });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors: error.response.data.errors.full_messages,
    });
  }
}

function* transferTicket(action) {
  try {
    const { data, included } = yield call(
      putApi,
      `${API.url}/tickets/${action.id}/transfer`,
      action.form
    );

    notification('success', 'CI transferida com sucesso');
    yield put({ type: actions.TRANSFER_SUCCESS, data, included });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors: error.response.data.errors.full_messages,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.FETCH_INSTITUTIONS, fetchInstitutions),
    yield takeEvery(actions.FETCH_REQUEST, fetchTickets),
    yield takeEvery(actions.FETCH_RESOURCES, fetchResources),
    yield takeEvery(actions.FETCH_USERS_BY_SECTION, fetchUsersBySector),
    yield takeEvery(actions.CREATE, createTicket),
    yield takeEvery(actions.CREATE_REPLY, createReply),
    yield takeEvery(actions.SET, fetchTicket),
    yield takeEvery(actions.FORWARD_TICKET, forwardTicket),
    yield takeEvery(actions.COMPLETE_TICKET, completeTicket),
    yield takeEvery(actions.CHANGE_STATUS_TICKET, changeTicket),
    yield takeEvery(actions.TRANSFER, transferTicket),
  ])
}
