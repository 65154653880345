import { PURGE } from "redux-persist";
import actions from './actions';

const initForm = {
  id: null,
  name: '',
  status: false,
}

const initState = {
  notices: [],
  unconfirmedNotices: [],
  formValues: initForm,
  current: {},
  currentNotice: null,
  editable: {},
  pagination: {
    itemsPerPage: 10,
  },
  loading: true,
  modalNew: false,
  modalEdit: false,
  modalFilter: false,
  showNotices: false,
  enableEdit: false,
  errors: null,
};


const dismissNotice = (state) => {
  const { unconfirmedNotices } = state;
  const index = state.unconfirmedNotices.findIndex(notice => notice.id === state.currentNotice.id);
  if (index >= 0) {
    unconfirmedNotices.splice(index, 1);
  }

  return {
    ...state,
    unconfirmedNotices,
    currentNotice: unconfirmedNotices[0],
    showNotices: !!unconfirmedNotices.length,
  };
}
export default function (state = initState, action) {
  switch (action.type) {
    case actions.FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.FETCH_SUCCESS:
      return {
        ...state,
        notices: action.data,
        pagination: action.pagination,
        loading: false
      };
    case actions.FETCH_UNCONFIRMED_NOTICES:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_UNCONFIRMED_NOTICES_SUCCESS:
      return {
        ...state,
        unconfirmedNotices: action.data,
        loading: false,
        showNotices: !!action.data.length,
        currentNotice: action.data[0],
      };
    case actions.DISMISS_NOTICE:
      return dismissNotice(state, action);
    case actions.GET_NOTICE:
      return {
        ...state,
        loading: true,
        modalEdit: false,
      };
    case actions.GET_NOTICE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentNotice: {
          ...action.data,
          included: action.included,
        },
      };
    case actions.FETCH_UNCONFIRMED_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.SET:
      return {
        ...state,
        editable: action.notice.attributes,
        modalEdit: true
      }
    case actions.OPEN_MODAL_NEW:
      return {
        ...state,
        modalNew: true,
      }
    case actions.CLOSE_MODAL_NEW:
      return {
        ...state,
        modalNew: false,
      }
    case actions.TOGGLE_MODAL_NEW:
      return {
        ...state,
        modalNew: !state.modalNew,
      }
    case actions.TOGGLE_MODAL_EDIT:
      return {
        ...state,
        modalEdit: !state.modalEdit,
      }
    case actions.CLOSE_MODAL_EDIT:
      return {
        ...state,
        editable: {},
        modalEdit: false,
      }
    case actions.CREATE_SUCCESS:
      return {
        ...state,
        notices: [
          action.data,
          ...state.notices,
        ],
        formValues: initForm,
        modalNew: false,
      }
    case actions.UPDATED_SUCCESS:
      return {
        ...state,
        notices: state.notices.map(notice => notice.id === action.data.id ?
          { ...action.data } :
          notice
        ),
        modalEdit: false,
      }
    case PURGE:
      return initState;
    case actions.ERROR:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      }
    default:
      return state;
  }
}
