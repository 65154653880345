import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';

import { Button } from '@material-ui/core';
import RenderTextField from '../../../../components/renderform/textField';
import { FullColumn, Row } from '../../../../components/utility/rowColumn';

import '../style.scss';

const FilterForm = ({ handleSubmit, submitting, onSubmit, formState = {} }) => {

  const isFirstRun = useRef(true);
  
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      onSubmit(formState);
    }
  }, [formState, onSubmit]);

  return (
    <form onSubmit={handleSubmit} className="mainFormsWrapper userFilterForm">
      <div className="mainFormsInfoWrapper">
        <Row>
          <FullColumn>
            <Field
              name="document_eq"
              component={RenderTextField}
              label="Documento"
            />
          </FullColumn>

          <FullColumn xs={12} md={6} lg={3} className="form--actions">
            <div className="mateFormsSubmit">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={submitting ? '' : 'mateFormsSubmitBtn'}
                  disabled={submitting}
                  onClick={() => onSubmit({
                    status_eq: undefined,
                    name_cont: undefined,
                    email_cont: undefined,
                    document_cont: undefined,
                  })}
                >
                  Limpar Filtro
                </Button>
              </div>
            </div>
          </FullColumn>
        </Row>
      </div>
    </form>
  );
};

const filterForm = reduxForm({
  form: 'FilterForm',
  enableReinitialize: true,
})(FilterForm);


const mapStateToProps = state => {
  const formState = getFormValues('FilterForm')(state);
  return { formState };
};

export default connect(mapStateToProps)(filterForm);
