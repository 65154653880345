import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi, postApi, putApi, stringifyQueryParams } from '../../helpers/api'

function* fetchAddresses(action) {
  try {
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/addresses.json?${stringifyQueryParams(action.params)}`,
      );

    yield put({ type: actions.GET_ADDRESSES_SUCCESS, addresses: data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* listAddresses() {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/addresses/list.json`,
      );

    yield put({ type: actions.LIST_ADDRESSES_SUCCESS, addresses: data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* getListAddressesExternalUsers(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);

    const { data } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/admin/external_users/${action.id}/addresses`,
      );

    yield put({ type: actions.LIST_ADDRESSES_EXTERNAL_USERS_SUCCESS, addresses: data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* listStates() {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/states.json`,
      );

    yield put({ type: actions.GET_STATE_SUCCESS, states: data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* listCities(action) {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/states/${action.id}/cities.json`,
      );

    yield put({ type: actions.GET_CITIES_SUCCESS, cities: data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* listNeighborhoods(action) {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/cities/${action.id}/neighborhoods.json`,
      );

    yield put({ type: actions.GET_NEIGHBORHOODS_SUCCESS, neighborhoods: data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* createAddress(action) {
  try {
    yield call(postApi, `${API.url}/addresses.json`, action.form);
    yield put({ type: actions.GET_ADDRESSES });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error && error.response && error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* editAddress(action) {
  try {
    yield call(putApi, `${API.url}/addresses/${action.id}.json`, action.form);
    yield put({ type: actions.GET_ADDRESSES });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error && error.response && error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ADDRESSES, fetchAddresses),
    takeEvery(actions.LIST_ADDRESSES, listAddresses),
    takeEvery(actions.LIST_ADDRESSES_EXTERNAL_USERS, getListAddressesExternalUsers),
    takeEvery(actions.CREATE_ADDRESS, createAddress),
    takeEvery(actions.UPDATE_ADDRESS, editAddress),
    takeEvery(actions.GET_STATE, listStates),
    takeEvery(actions.GET_CITIES, listCities),
    takeEvery(actions.GET_NEIGHBORHOODS, listNeighborhoods),
  ]);
}
