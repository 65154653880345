const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION/auth',
  CHECK_SUCCESS: 'CHECK_SUCCESS/auth',
  LOGIN_REQUEST: 'LOGIN_REQUEST/auth',
  USER_UNAUTHORIZED: 'USER_UNAUTHORIZED/auth',
  LOGOUT: 'LOGOUT/auth',
  LOGOUT_OMBUDSMAN: 'LOGOUT_OMBUDSMAN/auth',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS/auth',
  LOGIN_ERROR: 'LOGIN_ERROR/auth',
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST/auth',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS/auth',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST/auth',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS/auth',
  CONFIRMATION_ACCOUNT_REQUEST: 'CONFIRMATION_ACCOUNT_REQUEST/auth',
  CONFIRMATION_ACCOUNT_SUCCESS: 'CONFIRMATION_ACCOUNT_SUCCESS/auth',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: payload => ({
    type: actions.LOGIN_REQUEST,
    payload
  }),
  logout: (slug = undefined) => ({
    type: actions.LOGOUT,
    slug,
  }),
  logoutOmbudsman: (skip = false) => ({
    type: actions.LOGOUT_OMBUDSMAN,
    skip,
  }),
  forgitPassword: payload => ({
    type: actions.FORGOT_PASSWORD_REQUEST,
    payload
  }),
  resetPassword: (token, form) => ({
    type: actions.RESET_PASSWORD_REQUEST,
    token,
    form
  }),
  confirmationAccount: (token, form) => ({
    type: actions.CONFIRMATION_ACCOUNT_REQUEST,
    token,
    form
  }),
};
export default actions;
