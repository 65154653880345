import React from 'react'
import {
  FormControl,
  FormHelperText,
} from '../../uielements/form'
import MaterialDropZone from '../../uielements/MaterialDropZone';

const RenderDropZone = ({
  input,
  maxSize,
  filesLimit,
  acceptedFiles,
  text,
  initFiles,
  sizeMb,
  meta: { touched, error },
  value,
  showButton = true,
}) => {
  const { onChange } = input

  return (
    <FormControl value={input.value || value}>
      <MaterialDropZone
        input={input}
        files={initFiles}
        acceptedFiles={acceptedFiles}
        maxSize={maxSize}
        text={text}
        showPreviews
        onChange={onChange}
        filesLimit={filesLimit}
        showButton={showButton}
        sizeMb={sizeMb}
      />
      {touched && error ? <FormHelperText style={{ fontSize: 16, alignSelf: 'center' }}>{error}!</FormHelperText> : ''}
    </FormControl>
  );
};

export default RenderDropZone;
