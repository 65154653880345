import { all, call, takeEvery, select, put } from 'redux-saga/effects';

import actions from './actions';
import { API } from '../../settings';
import { fetchApi, postApi } from '../../helpers/api'
import { notification } from '../../components';

function *getOmbudsman(action) {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/institutions/ombudsman/${action.slug}`,
      );

    localStorage.setItem('currentMode', 'ombudsman');
    localStorage.setItem('slug', action.slug);

    yield put({ type: actions.GET_OMBUDSMAN_SUCCESS, data });
  } catch (error) {
    const message = error.response && error.response.status === 404
      ? 'Ouvidoria não encontrada'
      : 'Não foi possível carregar ouvidoria. Tente novamente mais tarde.';

    notification('error', message);

    yield put({ type: actions.ERROR, error: error.response.status });
  }
}

function *getGraphs(action) {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/institutions/charts/${action.slug}.json`,
      );

    yield put({ type: actions.GET_GRAPHS_SUCCESS, data });
  } catch (error) {
    notification('error', 'Não foi possível carregar os gráficos');

    yield put({ type: actions.ERROR, error: error.response.status });
  }
}

function *getSurvey(action) {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/institutions/${action.id}/satisfaction_survey.json`,
      );

    yield put({ type: actions.GET_SATISFACTION_SURVEY_SUCCESS, data });
  } catch (error) {
    notification('error', 'Não foi possível carregar os gráficos');

    yield put({ type: actions.ERROR, error: error.response.status });
  }
}

function* fetchGroupings() {
  try {
    const userType = yield select(state => state.session.user.data.type);
    const institutionId = userType === 'externalUser' ?
      yield select(state => state.Ombudsman.ombudsman.id) :
      yield select(state => state.Dashboard.currentInstitution);

    const { data } = yield call(
      fetchApi,
      `${API.url}/resources/institutions/${institutionId}/groupings.json`,
      {},
      { grouping_type: localStorage.getItem('currentMode') },
    );

    yield put({ type: actions.GET_GROUPINGS_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response && error.response.status,
      error_message: error.response && error.response.data.errors
    });
  }
}

function* fetchSectors(action) {
  try {
    const userType = yield select(state => state.session.user.data.type);
    const institutionId = userType === 'externalUser' ?
      yield select(state => state.Ombudsman.ombudsman.id) :
      yield select(state => state.Dashboard.currentInstitution);

    const url = action.id ?
    `${API.url}/resources/institutions/${institutionId}/groupings/${action.id}/sectors.json` :
    `${API.url}/resources/institutions/${institutionId}/sectors.json`;

    const { data } = yield call(
      fetchApi,
      url,
      {},
      { sector_type: localStorage.getItem('currentMode') },
    );

    yield put({ type: actions.GET_SECTORS_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response && error.response.status,
      error_message: error.response && error.response.data.errors
    });
  }
}


function* fetchCategories() {
  try {
    const userType = yield select(state => state.session.user.data.type);
    const institutionId = userType === 'externalUser' ?
      yield select(state => state.Ombudsman.ombudsman.id) :
      yield select(state => state.Dashboard.currentInstitution);

    const { data } = yield call(
      fetchApi,
      `${API.url}/resources/institutions/${institutionId}/categories.json`,
      {},
      { category_type: localStorage.getItem('currentMode') },
    );

    yield put({ type: actions.GET_CATEGORIES_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response && error.response.status,
      error_message: error.response && error.response.data.errors
    });
  }
}

function* fetchSubjects(action) {
  try {
    const userType = yield select(state => state.session.user.data.type);
    const institutionId = userType === 'externalUser' ?
      yield select(state => state.Ombudsman.ombudsman.id) :
      yield select(state => state.Dashboard.currentInstitution);

    const { data } = yield call(
      fetchApi,
      `${API.url}/resources/institutions/${institutionId}/sectors/${action.id}/subjects.json`,
      {},
      { subject_type: localStorage.getItem('currentMode') },
    );

    yield put({ type: actions.GET_SUBJECTS_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response && error.response.status,
      error_message: error.response && error.response.data.errors
    });
  }
}

function* createExternalUser(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);

    const { data } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/admin/external_users`,
      action.form,
    );

    notification('success', 'Usuário criado com sucesso!');
    yield put({ type: actions.CREATE_EXTERNAL_USER_SUCCESS, data });
  } catch (error) {
    notification('error', error.response.data.errors.full_messages);
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_OMBUDSMAN, getOmbudsman),
    takeEvery(actions.GET_GROUPINGS, fetchGroupings),
    takeEvery(actions.GET_SECTORS, fetchSectors),
    takeEvery(actions.GET_CATEGORIES, fetchCategories),
    takeEvery(actions.GET_SUBJECTS, fetchSubjects),
    takeEvery(actions.GET_GRAPHS, getGraphs),
    takeEvery(actions.CREATE_EXTERNAL_USER, createExternalUser),
    takeEvery(actions.GET_SATISFACTION_SURVEY, getSurvey),
  ]);
}
