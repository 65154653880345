import { PURGE } from "redux-persist";
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initForm = {
  attachments: [],
  id: null,
  title: '',
  description: '',
  kind: 'internal_communication',
  status: 'pending',
  expires_at: '',
  internal: false,
  customer_name: '',
  customer_identification: '',
  user_ids: [],
  sector_ids: [],
};

const initReplyForm = {
  message: 'ola',
  attachments: [],
};

const initForwardForm = {
  id: null,
  email: '',
  subject: '',
  comment: '',
  send_replies: false,
};

const filterForm = {
  title_cont: '',
  expires_at_gteq: '',
  status_eq: undefined,
};

const initState = {
  tickets: [],
  ticketsReceived: [],
  ticketsRelatives: [],
  categories: [],
  sectors: [],
  subjects: [],
  users: [],
  formValues: initForm,
  replyFormValues: initReplyForm,
  forwardFormValues: initForwardForm,
  filterFormValues: filterForm,
  rawFilterFormValues: {},
  sending: false,
  downloading: false,
  current: {},
  editable: {},
  loading: true,
  loadingModal: false,
  modalNew: false,
  modalEdit: false,
  modalFilter: false,
  modalStart: false,
  modalAttachment: false,
  modalChangeExpiration: false,
  replyModal: false,
  forwardModal: false,
  reopenModal: false,
  descriptionModal: false,
  deadlineModal: false,
  deadlineAnswerModal: false,
  enableEdit: false,
  pagination: {
    itemsPerPage: 10,
  },
  errorStatus: null,
  errors: null,
};

const fetchRequest = state => ({
  ...state,
  loading: true,
  modalNew: false,
  modalEdit: false,
});

const fetchSuccess = (state, action) => {
  return {
    ...state,
    tickets: action.data,
    pagination: action.pagination,
    loading: false,
  }
};

const fetchReceived = state => ({
  ...state,
  loading: true,
});

const fetchReceivedSuccess = (state, action) => ({
  ...state,
  ticketsReceived: action.data,
  pagination: action.pagination,
  loading: false,
});

const fetchRelatives = state => ({
  ...state,
  loading: true,
});

const fetchRelativesSuccess = (state, action) => ({
  ...state,
  ticketsRelatives: action.data,
  pagination: action.pagination,
  loading: false,
});

const openEditModal = state => ({
  ...state,
  loading: true,
  modalEdit: true,
});

const fetchError = (state, action) => ({
  ...state,
  loading: false,
  sending: false,
  errors: action.errors,
});

const toggleModalEdit = state => ({
  ...state,
  modalEdit: false,
});

const transferSuccess = (state, action) => ({
  ...state,
  current: {
    ...action.data,
    included: action.included,
  },
  modalEdit: false,
});

const setTicket = state => ({
  ...state,
  loading: true,
  loadingModal: false,
  forwardModal: false,
  replyModal: false,
  sending: false,
  replyFormValues: {
    message: '',
    attachments: [],
  },
});

const setTicketSuccess = (state, action) => {
  const { deadline } = action.data.attributes;
  return {
    ...state,
    current: {
      ...action.data,
      included: action.included,
    },
    deadlineAnswerModal: deadline && deadline.status === 'pending',
    loading: false,
    modalStart: action.data && action.data.attributes.status === 'pending',
    descriptionModal: false,
    replyFormValues: {
      message: '',
      attachments: [],
    },
  };
};

const toggleModalNew = state => ({
  ...state,
  modalNew: !state.modalNew,
});

const createTicket = state => ({
  ...state,
  sending: true,
});

const createSuccess = state => {
  return {
    ...state,
    formValues: initForm,
    modalNew: false,
    sending: false,
  }
};

const toggleReplyModal = state => ({
  ...state,
  replyModal: !state.replyModal,
});

const openReplyModal = state => ({
  ...state,
  replyModal: true,
});

const createReply = state => ({
  ...state,
  loadingModal: true,
  sending: true,
});

const createReplySuccess = state => ({
  ...state,
  loadingModal: false,
  replyModal: false,
  sending: false,
  replyFormValues: initReplyForm,
});

const forwardTicket = state => ({
  ...state,
  loadingModal: true,
});

const forwardTicketSuccess = state => ({
  ...state,
  loadingModal: false,
  forwardModal: false,
});

const toggleForwardModal = state => ({
  ...state,
  forwardModal: !state.forwardModal,
});

const openForwardModal = state => ({
  ...state,
  forwardModal: true,
});

const changeStatusTicket = state => ({
  ...state,
  loadingModal: false,
});

const changeStatusTicketSuccess = (state, action) => ({
  ...state,
  current: {
    ...action.data,
    included: action.included,
  },
  loadingModal: false,
  modalStart: false,
});

const toggleModalFilter = state => ({
  ...state,
  modalFilter: !state.modalFilter,
});

const saveFilterForm = (state, action) => ({
  ...state,
  filterFormValues: action.params,
  rawFilterFormValues: action.formatedParams
});

const openModalFilter = state => ({
  ...state,
  modalFilter: true,
});

const toggleModalStart = state => ({
  ...state,
  modalStart: !state.modalStart,
});

const toggleModalFinish = state => ({
  ...state,
  modalFinish: !state.modalFinish,
});

const toggleAttachmentModal = state => ({
  ...state,
  modalAttachment: !state.modalAttachment,
});

const addAttachmentTicket = state => ({
  ...state,
  sending: true,
})

const addAttachmentTicketSuccess = (state, action) => ({
  ...state,
  current: {
    ...action.data,
    included: action.included,
  },
  modalAttachment: false,
  sending: false,
})

const fetchResources = state => ({
  ...state,
  loadingModal: true,
});

const fetchResourcesSuccess = (state, action) => ({
  ...state,
  subjects: action.subjects,
  categories: action.categories,
  sectors: action.sectors,
  loadingModal: false,
});

const toggleModalExpiration = state => ({
  ...state,
  modalChangeExpiration: !state.modalChangeExpiration,
})

const changeExpirationRequest = state => ({
  ...state,
  sending: true,
});

const changeExpirationSuccess = (state, action) => ({
  ...state,
  current: {
    ...action.data,
    included: action.included,
  },
  sending: false,
});

const toggleDeadlineModal = state => ({
  ...state,
  deadlineModal: !state.deadlineModal,
});

const toggleDeadlineAnswerModal = state => ({
  ...state,
  deadlineAnswerModal: !state.deadlineAnswerModal,
});

const createDeadline = (state) => ({
  ...state,
  sending: true,
});

const createDeadlineSuccess = (state) => ({
  ...state,
  sending: false,
});

const answerDeadline = (state) => ({
  ...state,
  sending: true,
});

const answerDeadlineSuccess = (state) => ({
  ...state,
  sending: false,
});

const toggleReopenModal = (state) => ({
  ...state,
  reopenModal: !state.reopenModal,
});

const resetLoading = (state) => ({
  ...state,
  loading: false,
  sending: false,
  toggleReopenModal: false,
  attachmentLink: true,
});

const actionError = (state, action) => {
  return {
    ...state,
    loading: false,
    sending: false,
    errorStatus: action.errorStatus,
    errors: action.errors,
  }
};

const fetchUsersBySector = (state, action) => ({
  ...state,
  users: action.data
})

const downloadPdfReport = (state) => ({
  ...state,
  downloading: true,
});

const downloadPdfReportSuccess = state => ({
  ...state,
  downloading: false,
});

const toggleDescriptionModal = state => ({
  ...state,
  descriptionModal: !state.descriptionModal,
});

const purgeState = () => initState;

const HANDLERS = {
  [actions.FETCH_REQUEST]: fetchRequest,
  [actions.FETCH_SUCCESS]: fetchSuccess,
  [actions.FETCH_RECEIVED_REQUEST]: fetchReceived,
  [actions.FETCH_RECEIVED_SUCCESS]: fetchReceivedSuccess,
  [actions.FETCH_RELATIVES_REQUEST]: fetchRelatives,
  [actions.FETCH_RELATIVES_SUCCESS]: fetchRelativesSuccess,
  [actions.SET]: setTicket,
  [actions.SET_SUCCESS]: setTicketSuccess,
  [actions.TOGGLE_MODAL_NEW]: toggleModalNew,
  [actions.CREATE]: createTicket,
  [actions.CREATE_SUCCESS]: createSuccess,
  [actions.TOGGLE_EDIT_MODAL]: toggleModalEdit,
  [actions.OPEN_EDIT_MODAL]: openEditModal,
  [actions.TRANSFER_SUCCESS]: transferSuccess,
  [actions.TOGGLE_REPLY_MODAL]: toggleReplyModal,
  [actions.OPEN_REPLY_MODAL]: openReplyModal,
  [actions.CREATE_REPLY]: createReply,
  [actions.CREATE_REPLY_SUCCESS]: createReplySuccess,
  [actions.FORWARD_TICKET]: forwardTicket,
  [actions.FORWARD_TICKET_SUCCESS]: forwardTicketSuccess,
  [actions.TOGGLE_FORWARD_MODAL]: toggleForwardModal,
  [actions.OPEN_FORWARD_MODAL]: openForwardModal,
  [actions.CHANGE_STATUS_TICKET_SUCCESS]: changeStatusTicketSuccess,
  [actions.CHANGE_STATUS_TICKET]: changeStatusTicket,
  [actions.SAVE_FILTER_FORM]: saveFilterForm,
  [actions.TOGGLE_MODAL_FILTER]: toggleModalFilter,
  [actions.OPEN_MODAL_FILTER]: openModalFilter,
  [actions.TOGGLE_MODAL_START]: toggleModalStart,
  [actions.TOGGLE_FINISH_MODAL]: toggleModalFinish,
  [actions.TOGGLE_ATTACHMENT_MODAL]: toggleAttachmentModal,
  [actions.ADD_ATTACHMENT]: addAttachmentTicket,
  [actions.ADD_ATTACHMENT_SUCCESS]: addAttachmentTicketSuccess,
  [actions.FETCH_RESOURCES_SUCCESS]: fetchResourcesSuccess,
  [actions.FETCH_RESOURCES]: fetchResources,
  [actions.FETCH_USERS_BY_SECTION_SUCCESS]: fetchUsersBySector,
  [actions.TOGGLE_MODAL_EXPIRATION]: toggleModalExpiration,
  [actions.CHANGE_EXPIRATION_REQUEST]: changeExpirationRequest,
  [actions.CHANGE_EXPIRATION_SUCCESS]: changeExpirationSuccess,
  [actions.FETCH_ERROR]: fetchError,
  [actions.ERROR]: actionError,
  [actions.TOGGLE_DEADLINE_MODAL]: toggleDeadlineModal,
  [actions.TOGGLE_DEADLINE_ANSWER_MODAL]: toggleDeadlineAnswerModal,
  [actions.CREATE_DEALINE_REQUEST]: createDeadline,
  [actions.CREATE_DEADLINE_SUCCESS]: createDeadlineSuccess,
  [actions.ANSWER_DEADLINE_SUCCESS]: answerDeadlineSuccess,
  [actions.ANSWER_DEADLINE_REQUEST]: answerDeadline,
  [actions.DOWNLOAD_REPORT]: downloadPdfReport,
  [actions.DOWNLOAD_REPORT_SUCCESS]: downloadPdfReportSuccess,
  [actions.TOGGLE_REOPEN_MODAL]: toggleReopenModal,
  [actions.RESET_LOADING]: resetLoading,
  [actions.TOGGLE_DESCRIPTION_MODAL]: toggleDescriptionModal,
  [PURGE]: purgeState,
};

const ticketRecucer = createMyReducer(initState, HANDLERS);

export default ticketRecucer;
