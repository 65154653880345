/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, {  useState, useCallback, useEffect } from 'react';
import { change, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import AsyncSelect from "react-select/async";
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

import { FullColumn, Row } from '../../../../components/utility/rowColumn';
import RenderSelectFieldWithSearch from '../../../../components/renderform/selectFieldWithSearch';
import { FormLabel } from '../../../../components/uielements/form';

import protocolActions from '../../../../redux/protocol/actions';

import { required } from '../../../../helpers/validate';
import { fetchLocalMapBox } from '../../../../helpers/api'; 

import '../../style.scss';

const SectorForm = ({
  dispatch,
  formState,
  coverage,
  states,
  cities,
  neighborhoods,
  getCities,
  getNeighborhoods,
}) => {
  const {
    savePositionCreateProtocol,
  } = protocolActions;

  const [map, setMap] = useState();
  const [address, setAddress] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  const [position, setPosition] = useState({
    lat: -20.2036449,
    lng: -54.7219268,
  });

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length < 5) return;

    const scopedAddress = [inputValue];
    if (formState && formState.neighborhood_id && formState.neighborhood_id.value) {
      scopedAddress.push(formState.neighborhood_id.label);
    }
    const places = [];
    await fetchLocalMapBox(scopedAddress.join(', '))
      .then((response) => {
        response.features.forEach((item) => {
          places.push({
            label: item.place_name,
            value: item.place_name,
            coords: item.center,
            place: item.place_name,
            neighborhood: item.context[0].text,
          });
        });
        return places;
      });
    callback(places);
  };

  const handleChangeSelect = (event) => {
    setAddress({ label: event.place, value: event.place });

    const neighborhood = neighborhoods.find(n => n.attributes.name === event.neighborhood);
    if (neighborhood) {
      dispatch(change(
        'ProtocolLocationForm',
        'neighborhood_id',
        { value: neighborhood.id, label: neighborhood.attributes.name },
      ));
    }
    dispatch(change('ProtocolLocationForm', 'longitude', event.coords[0]));
    dispatch(change('ProtocolLocationForm', 'latitude', event.coords[1]));

    setPosition({
      lng: event.coords[0],
      lat: event.coords[1],
    });
  };

  const onLoad = useCallback((mapParam) => {
    setMap(mapParam);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleMapClick = useCallback((e) => {
    setPosition({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  }, []);

  useEffect(() => {
    if ("geolocation" in navigator && map) {
      navigator.geolocation.getCurrentPosition((cPosition) => {
        setPosition({
          lat: cPosition.coords.latitude,
          lng: cPosition.coords.longitude,
        });
      });
    }
  }, [map]);

  const handleSetPosition = useCallback(() => {
    dispatch(savePositionCreateProtocol(position));
  }, [savePositionCreateProtocol, position]);

  useEffect(() => {
    handleSetPosition();
  }, [position]);
  
  return (
    <Row>
      {coverage === 'national' && (
        <FullColumn md={6} lg={4}>
          <Field
            name="state_id"
            label="Estado"
            component={RenderSelectFieldWithSearch}
            validate={[required]}
            props={{
              options: states.map(({ attributes }) => ({
                label: attributes.name,
                value: String(attributes.id),
              })),
              isDisabled: false,
              isSearchable: true,
              isLoading: false,
              isMulti: false,
            }}
            onChange={(e) => {
              if (!e) return;

              getCities(e.value);
            }}
          />
        </FullColumn>
      )}
      {coverage === 'estadual' && (
        <FullColumn md={6} lg={4}>
          <Field
            name="city_id"
            label="Cidade"
            component={RenderSelectFieldWithSearch}
            validate={[required]}
            props={{
              options: cities.map(({ attributes }) => ({
                label: attributes.name,
                value: String(attributes.id),
              })),
              isDisabled: false,
              isSearchable: true,
              isLoading: false,
              isMulti: false,
            }}
            onChange={(e) => {
              if (!e) return;
              dispatch(change('ProtocolLocationForm', 'neighborhood_id', undefined));
              getNeighborhoods(e.value);
            }}
          />
        </FullColumn>
      )}
      {coverage === 'regional' && (
        <FullColumn md={6} lg={6}>
          <Field
            name="neighborhood_id"
            label="Bairro"
            component={RenderSelectFieldWithSearch}
            validate={[required]}
            props={{
              options: neighborhoods.map(({ attributes }) => ({
                label: attributes.name,
                value: String(attributes.id),
              })),
              isDisabled: false,
              isSearchable: true,
              isLoading: false,
              isMulti: false,
            }}
          />
        </FullColumn>
      )}
      <FullColumn md={6} lg={6}>
        <div className="input-block">
          <FormLabel className="labelSelect">Endereço</FormLabel>
          <AsyncSelect
            label="Endereço"
            placeholder="Digite o endereço"
            classNamePrefix="filter"
            cacheOptions
            loadOptions={loadOptions}
            onChange={handleChangeSelect}
            value={address}
          />
        </div>
      </FullColumn>

      <FullColumn md={12} lg={12}>
        {isLoaded ? (
          <GoogleMap
            name="position"
            mapContainerStyle={{ 
              width: '100%', 
              height: 400, 
              borderRadius: 5 
            }}
            options={{
              disableDefaultUI: true,
              zoomControl: true,
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
            zoom={15}
            center={position}
            onClick={(e) => handleMapClick(e)}
          >
            <Marker position={position} />
          </GoogleMap>
          ) : 
          <div>Carregando mapa...</div>
        }

        {loadError && (
          <div>Erro ao carregar mapa.</div>
        )}
      </FullColumn>
    </Row>
  );
};


const mapStateToProps = state => {
  const formState = getFormValues('ProtocolLocationForm')(state);
  return { formState };
};

export default connect(mapStateToProps)(SectorForm);
