/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components as reactSelectComponents } from 'react-select';


const { Option } = reactSelectComponents;

const IconOption = props => {
  const { data: { icon, label } } = props;
  return (
    <Option {...props}>
      <img
        src={icon}
        style={{ width: 36, marginRight: 10 }}
        alt={label}
      />
      {label}
    </Option>
  );
};

class SelectWithSearch extends Component {
  getPlaceholderComponent = (props) => {
    const { placeholder } = this.props;

    return (
      <reactSelectComponents.Placeholder {...props}>
        {/* {placeholder} */}
        Selecione...
      </reactSelectComponents.Placeholder>
    );
  }


  getValue = () => {
    const { value, options, isMulti, defaultValue, isGrouped } = this.props;
    if (isGrouped) {
      return value;
    }

    if (isMulti) {
      const array = Array.isArray(value) ? value.map(item => item.value) : [];
      const filterOptions  = options.filter((option) => array.includes(option.value))

      return filterOptions
    }

    const filterOptions = options
      .find((option) => option.value === value.value || option.value === defaultValue);
    return filterOptions || '';
  }

  render() {
    const {
      isDisabled,
      isSearchable,
      onChange,
      options,
      isMulti,
      label,
      selectWithIcon = false,
    } = this.props;

    return (
      <>
        {label && (
          <label className="labelSelect">{label}</label>
        )}
        <Select
          className="Select"
          isMulti={isMulti}
          components={{
            Placeholder: this.getPlaceholderComponent,
            Option: selectWithIcon ? IconOption : Option,
          }}
          isDisabled={isDisabled}
          isSearchable={isSearchable}
          onChange={onChange}
          options={options}
          value={this.getValue()}
        />
      </>
    );
  }
}

SelectWithSearch.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isLoading: PropTypes.bool,
  defaultValue: PropTypes.string,
};

SelectWithSearch.defaultPropTypes = {
  placeholder: 'Selecione uma opção',
  isMulti: false,
};

export default SelectWithSearch;
