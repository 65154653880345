const actions = {
  FETCH_REQUEST: 'FETCH_REQUEST/institutions',
  FETCH_SUCCESS: 'FETCH_SUCCESS/institutions',
  GET_SUCCESS: 'GET_SUCCESS/institutions',
  GET: 'GET/institutions',
  GET_LITE: 'GET_LITE/institutions',
  GET_LITE_SUCCESS: 'GET_LITE_SUCCESS/institutions',
  SET: 'SET/institutions',
  LIST_INSTITUTION: 'LIST_INSTITUTION/institutions',
  LIST_INSTITUTION_SUCCESS: 'LIST_INSTITUTION_SUCCESS/institutions',
  CREATE: 'ADD_ITEM/institutions',
  CREATE_SUCCESS: 'CREATE_SUCCESS/institutions',
  UPDATE: 'UPDATE/institutions',
  UPDATED_SUCCESS: 'UPDATED_SUCCESS/institutions',
  UPDATE_LITE: 'UPDATE_LITE/institutions',
  UPDATE_LITE_SUCCESS: 'UPDATE_LITE_SUCCESS/institutions',
  SELECT_CURRENT: 'SELECT_CURRENT/institutions',
  TOGGLE_VIEW: 'TOGGLE_VIEW/institutions',
  EDIT: 'EDIT/institutions',
  CHANGE_STATUS: 'CHANGE_STATUS/instituions',
  TOGGLE_MODAL_NEW: 'TOGGLE_MODAL_NEW/instituions',
  TOGGLE_MODAL_EDIT: 'TOGGLE_MODAL_EDIT/instituions',
  TOGGLE_MODAL_FILTER: 'TOGGLE_MODAL_FILTER/institutions',
  SAVE_FILTER_FORM: 'SAVE_FILTER_FORM/institutions',
  UPDATE_STATUS: 'UPDATE_STATUS/institutions',
  SET_FRANCHISE: 'SET_FRANCHISE/institutions',
  GET_FRANCHISE: 'GET_FRANCHISE/institutions',
  GET_FRANCHISE_SUCCESS: 'GET_FRANCHISE_SUCCESS/institutions',
  RESTORE_PROTOCOLS: 'RESTORE_PROTOCOLS/institutions',
  RESTORE_PROTOCOLS_SUCCESS: 'RESTORE_PROTOCOLS_SUCCESS/institutions',
  RESTORE_SECTORS: 'RESTORE_SECTORS/institutions',
  RESTORE_SECTORS_SUCCESS: 'RESTORE_SECTORS_SUCCESS/institutions',
  RESTORE_USERS: 'RESTORE_USERS/institutions',
  RESTORE_USERS_SUCCESS: 'RESTORE_USERS_SUCCESS/institutions',
  ERROR: 'ERROR/instituions',
  fetchRequest: params => ({ type: actions.FETCH_REQUEST, params }),
  getInstitution: id => ({type: actions.GET, id}),
  getLiteInstitution: id => ({type: actions.GET_LITE, id}),
  toggleModalNew: () => ({ type: actions.TOGGLE_MODAL_NEW }),
  toggleModalEdit: () => ({ type: actions.TOGGLE_MODAL_EDIT }),
  toggleModalFilter: () => ({ type: actions.TOGGLE_MODAL_FILTER }),
  saveFilter: params => ({ type: actions.SAVE_FILTER_FORM, params }),
  addInstitution: form => ({ type: actions.CREATE, form }),
  setInstitution: institution => ({ type: actions.SET, institution }), // Open modal edit
  changeInstitution: (id, form) => ({ type: actions.UPDATE, id, form }),
  changeLiteInstitution: (id, form) => ({ type: actions.UPDATE_LITE, id, form }),
  updateStatus: (id, status) => ({ type: actions.UPDATE_STATUS, id, status }),
  getFranchise: id => ({ type: actions.GET_FRANCHISE, id }),
  setFranchise: (form, id) => ({ type: actions.SET_FRANCHISE, id, form}),
  uploadProtocols: file => ({ type: actions.RESTORE_PROTOCOLS, file }),
  uploadSectors: file => ({ type: actions.RESTORE_SECTORS, file }),
  uploadUsers: file => ({ type: actions.RESTORE_USERS, file }),
  getInstitutionsList: params => ({ type: actions.LIST_INSTITUTION, params }),
};

export default actions;
