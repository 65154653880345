import { call, all, takeEvery, put } from 'redux-saga/effects';
import { API } from '../../settings';
import { fetchApi, putApi } from '../../helpers/api';
import { notification } from '../../components';
import actions from './actions';

export function* confirmationAccount(action) {
  try {
    const { data } = yield call(
      putApi,
      `${API.url}/confirmation?token=${action.token}`,
      action.form
    );
    yield put({
      type: actions.CONFIRMATION_ACCOUNT_SUCCESS,
      current: data.attributes.user,
    });
  } catch (error) {
    notification('error', error.response.data.errors.full_messages);
    yield put({ type: actions.ERROR });
  }
}

export function* confirmationExternalUser(action) {
  try {
    yield call(
      fetchApi,
      `${API.url}/external_users/confirmation.json?confirmation_token=${action.token}`,
    );
    notification('success', 'Usuário confirmado!');
  } catch (error) {
    notification('error', error.response.data.errors.full_messages);
    yield put({ type: actions.ERROR });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CONFIRMATION_ACCOUNT_REQUEST, confirmationAccount),
    takeEvery(actions.CONFIRMATION_EXTERNAL_USER, confirmationExternalUser),
  ]);
}
