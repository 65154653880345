import { all, call, takeEvery, put, select } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi, putApi, stringifyQueryParams } from '../../helpers/api'
import { notification } from '../../components';

const history = createBrowserHistory({ forceRefresh: true });

function redirectTo(path) {
  history.push(path);
}

function* fetchUnReadNotifications() {
  try {
    const userType = yield select(state => state.session.user.data.type);
    const url = userType === 'user' ?
      `${API.url}/notifications/unread_notifications` :
      `${API.url}/external_user/notifications/unread_notifications`;
    const { data, included } = yield call(
      fetchApi,
      url,
    );

    yield put({ type: actions.FETCH_UNREAD_REQUEST_SUCCESS, data, included });
  } catch (error) {
    yield put({
      type: actions.ERROR_NOTIFICATION,
      status: error.response && error.response.status,
      error_message: error.response && error.response.data && error.response
        .data.errors,
    });
  }
}

function* fetchNotifications(action) {
  try {
    const userType = yield select(state => state.session.user.data.type);
    const url = userType === 'user' ?
      `${API.url}/notifications?${stringifyQueryParams(action.params)}` :
      `${API.url}/external_user/notifications?${stringifyQueryParams(action.params)}`;
    const { data, pagination } = yield call(
      fetchApi,
      url,
      { params: action.params },
    );

    yield put({ type: actions.FETCH_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR_NOTIFICATION,
      status: error.response && error.response.status,
      errors: error.response && error.response.data && error.response
        .data.errors,
    });
  }
}

function* readNotification({ id, path }) {
  try {
    const userType = yield select(state => state.session.user.data.type);
    const url = userType === 'user' ?
      `${API.url}/notifications/${id}/read` :
      `${API.url}/external_user/notifications/${id}/read`;
    yield call(putApi, url);
    yield put({ type: actions.FETCH_UNREAD_REQUEST });
    yield call(redirectTo, path);
  } catch (error) {
    yield put({
      type: actions.ERROR_NOTIFICATION,
      status: error.response && error.response.status,
      errors: error.response && error.response.data && error.response.data
        .errors.full_messages,
    });
  }
}

function* updateNotification({id, form}) {
  try {
    const userType = yield select(state => state.session.user.data.type);
    const url = userType === 'user' ?
      `${API.url}/notifications/${id}` :
      `${API.url}/external_user/notifications/${id}`;

    const { data } = yield call(putApi, url, form);
    notification('success', 'Notificação foi atualizada com sucesso');
    yield put({ type: actions.UPDATED_NOTIFICATION_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR_NOTIFICATION,
      status: error.response && error.response.status,
      errors: error.response && error.response.data && error.response.data
        .errors.full_messages,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_UNREAD_REQUEST, fetchUnReadNotifications),
    takeEvery(actions.FETCH_REQUEST, fetchNotifications),
    takeEvery(actions.READ_NOTIFICATION, readNotification),
    takeEvery(actions.CHECK_READ_REQUEST, updateNotification),
  ])
}
