import { PURGE } from "redux-persist";
import actions from './actions';

const initForm = {
  id: null,
  name: '',
  status: true,
}

const filterForm = {
  name_cont: '',
  status_eq: undefined
};

const initState = {
  groupings: [],
  formValues: initForm,
  hasAssociation: localStorage.getItem('currentMode') !== 'ci_digital',
  filterFormValues: filterForm,
  current: {},
  editable: {},
  pagination: {
    itemsPerPage: 10,
  },
  loading: true,
  modalNew: false,
  modalEdit: false,
  modalFilter: false,
  enableEdit: false,
  errors: null,
};

export default function (state = initState, action) {
  switch (action.type) {
    case actions.FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.FETCH_SUCCESS:
      return {
        ...state,
        groupings: action.data,
        pagination: action.pagination,
        loading: false
      }
    case actions.FETCH_SECTION_BY_GROUPING_SUCCESS:
      return {
        ...state,
        groupings: action.data
      }
    case actions.SET:
      return {
        ...state,
        editable: {
          ...action.grouping.attributes,
          iconId: { value: action.grouping.attributes.iconId },
        },
        modalEdit: true
      }
    case actions.GET:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actions.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...action.data,
          included: action.included,
        },
      };
    case actions.SAVE_FILTER_FORM:
      return {
        ...state,
        filterFormValues: action.params,
      }
    case actions.OPEN_MODAL_NEW:
      return {
        ...state,
        modalNew: true,
      }
    case actions.CLOSE_MODAL_NEW:
      return {
        ...state,
        modalNew: false,
      }
    case actions.TOGGLE_MODAL_NEW:
      return {
        ...state,
        modalNew: !state.modalNew,
      }
    case actions.OPEN_MODAL_FILTER:
      return {
        ...state,
        modalFilter: true,
      }
    case actions.TOGGLE_MODAL_FILTER:
      return {
        ...state,
        modalFilter: !state.modalFilter,
      }
    case actions.TOGGLE_MODAL_EDIT:
      return {
        ...state,
        modalEdit: !state.modalEdit,
      }
    case actions.CLOSE_MODAL_EDIT:
      return {
        ...state,
        editable: {
          status: true,
        },
        modalEdit: false,
      }
    case actions.CREATE_SUCCESS:
      return {
        ...state,
        groupings: [
          action.data,
          ...state.groupings,
        ],
        formValues: initForm,
      }
    case actions.UPDATED_SUCCESS:
      return {
        ...state,
        current: {
          ...action.data,
        },
        groupings: state.groupings.map(grouping => grouping.id === action.data.id ?
          { ...action.data } :
          grouping
        )
      }
    case PURGE:
        return initState;
    case actions.ERROR:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      }
    default:
      return state;
  }
}
