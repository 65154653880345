import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi } from '../../helpers/api'

function* fetchStates() {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/states`,
    );

    yield put({ type: actions.GET_STATES_SUCCESS, states: data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* fetchCities(action) {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/states/${action.id}/cities`,
    );

    yield put({ type: actions.GET_STATE_CITIES_SUCCESS, cities: data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_STATES, fetchStates),
    takeEvery(actions.GET_STATE_CITIES, fetchCities),
  ]);
}
