const actions = {
  GET_CITIES: 'GET_CITIES/cities',
  GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS/cities',
  OPEN_MODAL_NEW: 'OPEN_MODAL_NEW/cities',
  CLOSE_MODAL_NEW: 'CLOSE_MODAL_NEW/cities',
  CREATE_CITY: 'CREATE_CITY/cities',
  CREATE_CITY_SUCCESS: 'CREATE_CITY_SUCCESS/cities',
  EDIT_CITY: 'EDIT_CITY/cities',
  OPEN_MODAL_EDIT: 'OPEN_MODAL_EDIT/cities',
  CLOSE_MODAL_EDIT: 'CLOSE_MODAL_EDIT/cities',
  EDIT_CITY_SUCCESS: 'EDIT_CITY_SUCCESS/cities',
  UPDATE_CITY: 'UPDATE_CITY/cities',
  SAVE_FILTER: 'SAVE_FILTER/cities',
  ERROR: 'ERROR/STATES',
  getCities: (params) => ({ type: actions.GET_CITIES, params }),
  createCity: form => ({ type: actions.CREATE_CITY, form }),
  editCity: (id, form) => ({ type: actions.UPDATE_CITY, id, form }),
  openNewModal: () => ({ type: actions.OPEN_MODAL_NEW }),
  openEditModal: (city) => ({ type: actions.OPEN_MODAL_EDIT, city }),
  closeNewModal: () => ({ type: actions.CLOSE_MODAL_NEW }),
  closeEditModal: () => ({ type: actions.CLOSE_MODAL_EDIT }),
  saveFilter: params => ({ type: actions.SAVE_FILTER, params }),
};

export default actions;
