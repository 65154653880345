import { PURGE } from "redux-persist";
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initState = {
  loadingCsvUser: false,
  loadingExcelUser: false,
  loadingCsvTicket: false,
  loadingExcelTicket: false,
  loadingCsvReply: false,
  loadingExcelReply: false,
  errors: null,
};

const purgeState = () => initState;

const fetchUsersRequest = (state, action) => ({
  ...state,
  loadingCsvUser: action.filetype === 'csv',
  loadingExcelUser: action.filetype === 'xlsx',
});

const fetchCiRequest = (state, action) => ({
  ...state,
  loadingCsvTicket: action.filetype === 'csv',
  loadingExcelTicket: action.filetype === 'xlsx',
});

const fetchReplies = (state, action) => ({
  ...state,
  loadingCsvReply: action.filetype === 'csv',
  loadingExcelReply: action.filetype === 'xlsx',
});

const fetchUsersSuccess = (state) => ({
  ...state,
  loadingCsvUser: false,
  loadingExcelUser: false,
});

const CisSuccess = state => ({
  ...state,
  loadingCsvTicket: false,
  loadingExcelTicket: false,
});

const repliesSuccess = state => ({
  ...state,
  loadingCsvReply: false,
  loadingExcelReply: false
});

const actionError = (state, action) => ({
  ...state,
  loadUsers: false,
  errorStatus: action.errorStatus,
  errors: action.errors,
});

const HANDLERS = {
  [actions.FETCH_USERS_REQUEST]: fetchUsersRequest,
  [actions.CI_REQUEST]: fetchCiRequest,
  [actions.REPLIES_REQUEST]: fetchReplies,
  [actions.FETCH_USERS_SUCCESS]: fetchUsersSuccess,
  [actions.CI_SUCCESS]: CisSuccess,
  [actions.REPLIES_SUCCESS]: repliesSuccess,
  [actions.ERROR]: actionError,
  [PURGE]: purgeState,
};

const backupRecucer = createMyReducer(initState, HANDLERS);

export default backupRecucer;
