
const actions = {
  FETCH_REQUEST: 'FETCH_REQUEST/tickets',
  FETCH_SUCCESS: 'FETCH_SUCCESS/tickets',
  FETCH_RECEIVED_REQUEST: 'FETCH_RECEIVED_REQUEST/tickets',
  FETCH_RECEIVED_SUCCESS: 'FETCH_RECEIVED_SUCCESS/tickets',
  FETCH_RELATIVES_SUCCESS: 'FETCH_RELATIVES_SUCCESS/tickets',
  FETCH_RELATIVES_REQUEST: 'FETCH_RELATIVES_REQUEST/tickets',
  FETCH_RESOURCES: 'FETCH_RESOURCES/tickets',
  FETCH_RESOURCES_SUCCESS: 'FETCH_RESOURCES_SUCCESS/tickets',
  FETCH_USERS_BY_SECTION: 'FETCH_USERS_BY_SECTION/tickets',
  FETCH_USERS_BY_SECTION_SUCCESS: 'FETCH_USERS_BY_SECTION_SUCCESS/tickets',
  FETCH_MULTIPLE_USERS: 'FETCH_MULTIPLE_USERS/tickets',
  COMPLETE_TICKET: 'COMPLETE/tickets',
  COMPLETE_TICKET_SUCCESS: 'COMPLETE_SUCCESS/tickets',
  OPEN_MODAL_COMPLETE: 'OPEN_COMPLETE_MODAL/tickets',
  TOGGLE_MODAL_COMPLETE: 'TOGGLE_COMPLETE_MODAL/tickets',
  CHANGE_STATUS_TICKET: 'CHANGE_STATUS_TICKET/tickets',
  CHANGE_STATUS_TICKET_SUCCESS: 'CHANGE_STATUS_TICKET_SUCCESS/tickets',
  OPEN_MODAL_START: 'OPEN_MODAL_START/tickets',
  TOGGLE_MODAL_START: 'TOGGLE_MODAL_START/tickets',
  TOGGLE_FINISH_MODAL: 'TOGGLE_FINISH_MODAL/tickets',
  GET: 'GET/tickets',
  SET: 'SET/tickets',
  SET_SUCCESS: 'SET_SUCCESS/tickets',
  CREATE: 'ADD_ITEM/tickets',
  CREATE_SUCCESS: 'CREATE_SUCCESS/tickets',
  OPEN_MODAL_NEW: 'OPEN_MODAL_NEW/tickets',
  TOGGLE_MODAL_NEW: 'TOGGLE_MODAL_NEW/tickets',
  OPEN_REPLY_MODAL: 'OPEN_REPLY_MODAL/tickets',
  TOGGLE_REPLY_MODAL: 'TOGGLE_REPLY_MODAL/tickets',
  CREATE_REPLY: 'ADD_ITEM/replies',
  CREATE_REPLY_SUCCESS: 'CREATE_SUCCESS/replies',
  TOGGLE_FORWARD_MODAL: 'TOGGLE_FORWARD_MODAL/tickets',
  OPEN_FORWARD_MODAL: 'OPEN_FORWARD_MODAL/tickets',
  FORWARD_TICKET: 'FORWARD_TICKET/tickets',
  FORWARD_TICKET_SUCCESS: 'FORWARD_TICKET_SUCCESS/tickets',
  OPEN_MODAL_FILTER: 'OPEN_MODAL_FILTER/tickets',
  TOGGLE_MODAL_FILTER: 'TOGGLE_MODAL_FILTER/tickets',
  SAVE_FILTER_FORM: 'SAVE_FILTER_FORM/tickets',
  OPEN_EDIT_MODAL: 'OPEN_EDIT_MODAL/tickets',
  TOGGLE_EDIT_MODAL: 'TOGGLE_EDIT_MODAL/tickets',
  TRANSFER: 'TRANSFER/tickets',
  TRANSFER_SUCCESS: 'TRANSFER_SUCCESS/tickets',
  ADD_ATTACHMENT: 'ADD_ATTACHMENT/tickets',
  ADD_ATTACHMENT_SUCCESS: 'ADD_ATTACHMENT_SUCCESS/tickets',
  TOGGLE_ATTACHMENT_MODAL: 'TOGGLE_ATTACHMENT_MODAL/tickets',
  TOGGLE_MODAL_EXPIRATION: 'TOGGLE_MODAL_EXPIRATION/tickets',
  CHANGE_EXPIRATION_REQUEST: 'CHANGE_EXPIRATION_REQUEST/tickets',
  CHANGE_EXPIRATION_SUCCESS: 'CHANGE_EXPIRATION_SUCCESS/tickets',
  CREATE_DEALINE_REQUEST: 'CREATE_DEALINE_REQUEST/tickets',
  CREATE_DEADLINE_SUCCESS: 'CREATE_DEADLINE_SUCCESS/tickets',
  ANSWER_DEADLINE_REQUEST: 'ANSWER_DEADLINE_REQUEST/tickets',
  ANSWER_DEADLINE_SUCCESS: 'ANSWER_DEADLINE_SUCCESS/tickets',
  TOGGLE_DEADLINE_MODAL: 'TOGGLE_DEADLINE_MODAL/tickets',
  TOGGLE_DEADLINE_ANSWER_MODAL: 'TOGGLE_DEADLINE_ANSWER_MODAL/tickets',
  DOWNLOAD_REPORT: 'DOWNLOAD_REPORT/tickets',
  DOWNLOAD_REPORT_SUCCESS: 'DOWNLOAD_REPORT_SUCCESS/tickets',
  REOPEN_TICKET: 'REOPEN_TICKET/tickets',
  REOPEN_TICKET_SUCCESS: 'REOPEN_TICKET_SUCCESS/tickets',
  TOGGLE_REOPEN_MODAL: 'TOGGLE_REOPEN_MODAL/tickets',
  TOGGLE_DESCRIPTION_MODAL: 'TOGGLE_DESCRIPTION_MODAL/tickets',
  RESET_LOADING: 'RESET_LOADING/tickets',
  EDIT_DESCRIPTION: 'EDIT_DESCRIPTION/tickets',
  TOGGLE_READ_REPLY: 'TOGGLE_READ_REPLY/tickets',
  ERROR: 'ERROR/tickets',
  fetchResources: institutionId => ({ type: actions.FETCH_RESOURCES, institutionId }),
  fetchRequest: params => ({ type: actions.FETCH_REQUEST, params }),
  fetchReceived: params => ({ type: actions.FETCH_RECEIVED_REQUEST, params }),
  fetchRelatives: params => ({ type: actions.FETCH_RELATIVES_REQUEST, params }),
  fetchUsersBySector: id => ({ type: actions.FETCH_USERS_BY_SECTION, id }),
  addTicket: (form, draftId) => ({ type: actions.CREATE, form, draftId }),
  fetchTicket: id => ({ type: actions.SET, id }),
  downloadPdfReport: (id, protocol) => ({ type: actions.DOWNLOAD_REPORT, id, protocol }),
  completeTicket: id => ({ type: actions.COMPLETE_TICKET, id }),
  changeStatus: (id, status) => ({
    type: actions.CHANGE_STATUS_TICKET,
    id,
    status,
  }),
  toggleStartComplete: () => ({ type: actions.TOGGLE_MODAL_START }),
  toggleModalFinish: () => ({ type: actions.TOGGLE_FINISH_MODAL }),
  openModalComplete: () => ({ type: actions.OPEN_MODAL_COMPLETE }),
  toggleModalComplete: () => ({ type: actions.TOGGLE_MODAL_COMPLETE }),
  openModalNew: () => ({ type: actions.OPEN_MODAL_NEW }),
  toggleModalNew: () => ({ type: actions.TOGGLE_MODAL_NEW }),
  toggleReopenModal: () => ({ type: actions.TOGGLE_REOPEN_MODAL }),
  openReplyModal: () => ({ type: actions.OPEN_REPLY_MODAL }),
  toggleReplyModal: () => ({ type: actions.TOGGLE_REPLY_MODAL }),
  addReply: form => ({ type: actions.CREATE_REPLY, form }),
  openForwardModal: () => ({ type: actions.OPEN_FORWARD_MODAL }),
  toggleForwardModal: () => ({ type: actions.TOGGLE_FORWARD_MODAL }),
  openModalFilter: () => ({ type: actions.OPEN_MODAL_FILTER }),
  toggleModalFilter: () => ({ type: actions.TOGGLE_MODAL_FILTER }),
  saveFilter: (params, formatedParams) => ({ type: actions.SAVE_FILTER_FORM, params, formatedParams }),
  forwardTicket: (id, form) => ({ type: actions.FORWARD_TICKET, id, form }),
  closeModalNew: () => ({ type: actions.CLOSE_MODAL_NEW }),
  openEditModal: () => ({ type: actions.OPEN_EDIT_MODAL }),
  toggleEditModal: () => ({ type: actions.TOGGLE_EDIT_MODAL }),
  toggleAttachmentModal: () => ({ type: actions.TOGGLE_ATTACHMENT_MODAL }),
  addAttachmentTicket: (id, form) => ({ type: actions.ADD_ATTACHMENT, id, form }),
  transferTicket: (id, form) => ({ type: actions.TRANSFER, id, form }),
  toggleModalExpiration: () => ({ type: actions.TOGGLE_MODAL_EXPIRATION }),
  changeExpiration: (id, form) => ({type: actions.CHANGE_EXPIRATION_REQUEST, id, form}),
  toggleDeadlineModal: () => ({ type: actions.TOGGLE_DEADLINE_MODAL }),
  toggleDeadlineAnswerModal: () => ({ type: actions.TOGGLE_DEADLINE_ANSWER_MODAL }),
  createDeadline: (form) => ({ type: actions.CREATE_DEALINE_REQUEST, form }),
  answerDeadline: (ticketId, id, form) => ({ type: actions.ANSWER_DEADLINE_REQUEST, ticketId, id, form }),
  reopenTicket: (id, form) => ({ type: actions.REOPEN_TICKET, id, form }),
  toggleDescriptionModal: () => ({ type: actions.TOGGLE_DESCRIPTION_MODAL }),
  editTicket: (id, form) => ({ type: actions.EDIT_DESCRIPTION, id, form }),
  fetchMultipleUsers: (ids) => ({ type: actions.FETCH_MULTIPLE_USERS, sector_ids: ids }),
  toggleReadReply: (id) => ({ type: actions.TOGGLE_READ_REPLY, id }),
};

export default actions;
