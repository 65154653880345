import { all, call, takeLatest, put, select, takeEvery } from 'redux-saga/effects';
import { reset } from 'redux-form';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi, postApi, putApi, stringifyQueryParams } from '../../helpers/api'
import { notification } from '../../components';

function* fetchSectors(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/sectors.json?${stringifyQueryParams(action.params)}`,
      {},
      { sector_type: localStorage.getItem('currentMode') },
    );

    yield put({ type: actions.FETCH_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* listSectors(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);
    const { data } = yield call(
      fetchApi,
      `${API.url}/resources/institutions/${institutionId}/sectors.json`,
      {},
      { sector_type: localStorage.getItem('currentMode') },
    );

    yield put({ type: actions.LIST_SECTORS_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* getSector(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);
    const { data, included } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/sectors/${action.id}`,
    );

    yield put({ type: actions.GET_SUCCESS, data, included });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* createSector(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/sectors/`,
      action.form,
    );

    notification('success', 'Setor cadastrado com sucesso');
    yield put(reset('SectorForm'));
    yield put({ type: actions.CREATE_SUCCESS, data });
  } catch (error) {
    notification('error', `${error.response.data.errors.full_messages}`);
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages
    });
  }
}

function* updateSector(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);

    const { data } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/sectors/${action.form.id}`,
      action.form,
    );

    notification('success', `Setor ${action.form.name} atualizado com sucesso`);
    yield put({ type: actions.UPDATED_SUCCESS, data });
  } catch (error) {
    notification('error', `${error.response.data.errors.full_messages}`);
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.FETCH_REQUEST, fetchSectors),
    yield takeLatest(actions.CREATE, createSector),
    yield takeLatest(actions.UPDATE, updateSector),
    yield takeEvery(actions.GET, getSector),
    yield takeEvery(actions.LIST_SECTORS, listSectors),
  ])
}
