const actions = {
  LIST_DRAFTS: 'LIST_DRAFTS/draft',
  LIST_DRAFTS_SUCCESS: 'LIST_DRAFTS_SUCCESS/draft',
  FETCH_DRAFT: 'FETCH_DRAFT/draft',
  FETCH_DRAFT_SUCCESS: 'FETCH_DRAFT_SUCCESS/draft',
  CREATE_DRAFT: 'CREATE_DRAFT/draft',
  UPDATE_DRAFT: 'UPDATE_DRAFT/draft',
  UPDATE_DRAFT_SUCCESS: 'UPDATE_DRAFT_SUCCESS/draft',
  REMOVE_DRAFT: 'REMOVE_DRAFT/draft',
  REMOTE_DRAFT_SUCCESS: 'REMOTE_DRAFT_SUCCESS/draft',
  RESET_CURRENT_DRAFT: 'RESET_CURRENT_DRAFT/draft',
  DRAFT_FINALLY: 'DRAFT_FINALLY/draft',
  DRAFT_ERROR: 'DRAFT_ERROR/draft',
  listDrafts: () => ({ type: actions.LIST_DRAFTS }),
  fetchDraft: (uuid) => ({ type: actions.FETCH_DRAFT, uuid }),
  updateDraft: (uuid, draft) => ({ type: actions.UPDATE_DRAFT, uuid, draft }),
  removeDraft: (uuid) => ({ type: actions.REMOVE_DRAFT, uuid }),
  createDraft: (draft) => ({ type: actions.CREATE_DRAFT, draft }),
  resetCurrentDraft: () => ({ type: actions.RESET_CURRENT_DRAFT }),
};

export default actions;
