import React from 'react';
import {
  FormControl,
  FormHelperText,
} from '../../uielements/form';
import Select from '../../uielements/selectWithSearch';

const RenderSelectFieldWithSearch = ({
  input,
  label,
  onChange,
  options,
  isDisabled,
  isSearchable,
  isLoading,
  isMulti,
  isGrouped,
  margin,
  defaultValue,
  selectAll = undefined,
  selectWithIcon,
  meta: { touched, error },
}) => {
  const elementSelected = (element) => {
    if (Array.isArray(element)) {
      return element.some(el => el.value === selectAll.value);
    }
    return element && element.value === selectAll.value;
  };

  const onUpdate = value => {
    if (onChange) {
      onChange(value)
    }

    if (isMulti && !!selectAll &&  elementSelected(value)) {
      const items = options.filter(opt => opt.value !== selectAll.value);
      input.onChange(items);
    } else {
      input.onChange(value)
    }
  };

  return (
    <FormControl value={input.value}>
      <Select
        label={label}
        margin={margin}
        value={input.value}
        isMulti={isMulti}
        isDisabled={isDisabled}
        isGrouped={isGrouped}
        isSearchable={isSearchable}
        isLoading={isLoading}
        placeholder={label}
        onChange={onUpdate}
        selectWithIcon={selectWithIcon}
        menuPortalTarget={document.body}
        options = {
          (!!selectAll
            && isMulti
            && options.length
            && [selectAll].concat(options)
          ) ||
          options
        }
        defaultValue={defaultValue}
      />
      {touched && error ? <FormHelperText>{error}</FormHelperText> : ''}
    </FormControl>
  );
};

export default RenderSelectFieldWithSearch;
