import { all, call, takeEvery, put } from 'redux-saga/effects';
import { sessionService } from 'redux-react-session';
import actions from './actions';
import { API } from '../../settings';
import { putApi } from '../../helpers/api'
import { notification } from '../../components';

function* updateProfile(action) {
  try {
    const data = yield call(putApi, `${API.url}/profile/${action.id}`, action.form);
    notification('success', `Seu perfil foi atualizado com sucesso`);
    yield sessionService.saveUser(data)
    yield put({ type: actions.UPDATED_SUCCESS, data });
  } catch (error) {
    notification('error', `${error.response.data.errors.full_messages}`);
    yield put({
      type: actions.ERROR, error
    });
  }
}

function* updateProfileExternalUser(action) {
  try {
    const data = yield call(putApi, `${API.url}/external_user_profile/${action.id}`, action.form);
    notification('success', `Seu perfil foi atualizado com sucesso`);

    yield sessionService.saveUser(data)
    yield put({ type: actions.UPDATED_SUCCESS_EXTERNAL_USER, data });
  } catch (error) {
    notification('error', `${error.response.data.errors.full_messages}`);
    yield put({
      type: actions.ERROR, error
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.UPDATE_REQUEST, updateProfile),
    yield takeEvery(actions.UPDATE_REQUEST_EXTERNAL_USER, updateProfileExternalUser),
  ])
}
