const API = {
  url: process.env.REACT_APP_BASE_API_URL,
  websocket: process.env.WEBSOCKET_URL,
};

const siteConfig = {
  siteName: 'CI Digital',
  siteIcon: 'ion-flash',
  footerText: `CI Digital © ${new Date().getFullYear()}`,
  enableAnimatedRoute: false,
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
  showBreadCrumb: true,
  fixedNavbar: true,
};

export {
  API,
  siteConfig,
  themeConfig,
};
