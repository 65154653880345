import actions from "./actions";

const initState = {
  current: null,
  loading: false,
  toogleModal: false,
};

export default function (state = initState, action) {
  switch (action.type) {
    case actions.FIND_REQUEST:
      return {
        ...state,
        loading: true,
        toogleModal: true
      };
    case actions.FIND_SUCCESS:
      return {
        ...state,
        current: action.data,
        loading: false,
      };
    case actions.TOGGLE_MODAL_PROTOCOL:
      return {
        ...state,
        toogleModal: !state.toogleModal,
      }
    case actions.ERROR:
      return {
        ...state,
        loading: false,
        toogleModal: false,
      };
    default:
      return state;
  }
}
