/* eslint-disable no-unneeded-ternary */
import React from 'react';
import { Link } from "react-router-dom";
import { Field, reduxForm } from 'redux-form';
import DebounceField from 'redux-form-debounce-field';
import { getFormValues } from 'redux-form/immutable'
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { subYears } from 'date-fns';

import { FullColumn, Row } from '../../../components/utility/rowColumn';
import RenderSelectFieldWithSearch from '../../../components/renderform/selectFieldWithSearch';
import RenderDateField from '../../../components/renderform/dateField';
import RenderCheckBoxField from '../../../components/renderform/checkBoxField';
import NumberMaskField from '../../../components/renderform/numberMaskField';
import Button from '../../../components/uielements/button';
import RenderTextField from '../../../components/renderform/textField';
import RenderTextMaskField from '../../../components/renderform/textMaskField';

import { 
  required, 
  validateDocument, 
  passwordsMatch, 
  phoneNumber, 
  email 
} from '../../../helpers/validate';

const styles = () => ({
  createButton: {
    marginTop: 10
  }
});

const OuvidoriaForm = ({
  handleSubmit,
  slug,
  hideBtnBack,
  hidePassword,
  classes,
  formState,
  documentType,
  toggleModalPrivacityTerm,
}) => {
  return (
      <form onSubmit={handleSubmit} className="mainFormsWrapper">
        <div className="mainFormsInfoWrapper">
          <div className="mainFormsInfoField">
            <DebounceField
              name="document"
              component={RenderTextMaskField}
              validate={[validateDocument]}
              wait={700}
              label="Identificação"
            />
          </div>
          <div className="mainFormsInfoField">
            <Field
              name="name"
              component={RenderTextField}
              label="Nome"
              validate={[required]}
            />
          </div>
          <div className="mainFormsInfoField">
            <Field
              name="phone"
              label="Celular"
              component={NumberMaskField}
              validate={[required, phoneNumber]}
            />
          </div>
          <div className="mainFormsInfoField">
            <Field
              name="email"
              component={RenderTextField}
              label="E-mail"
              validate={[required, email]}
            />
          </div>
          
          {documentType === 'CPF' && (
            <div className="multiple-inputss">
              <Row>
                <FullColumn sm={12} md={4} lg={4}>
                  <Field
                    name="birthday"
                    component={RenderDateField}
                    label="Nascimento"
                    validate={[required]}
                    defaultValue={subYears(new Date(), 18)}
                    maxDate={subYears(new Date(), 16)}
                    noMargin
                  />
                </FullColumn>
                <FullColumn sm={12} md={4} lg={4}>
                  <Field
                    name="sex"
                    component={RenderSelectFieldWithSearch}
                    label="Gênero"
                    validate={[required]}
                    props={{
                      options: [
                        { label: 'Feminino', value: 'female' },
                        { label: 'Masculino', value: 'male' },
                        { label: 'Não Informado', value: 'unknow' }
                      ],
                      isDisabled: false,
                      isSearchable: true,
                      isLoading: false,
                      isMulti: false,
                    }}
                  />
                </FullColumn>
                <FullColumn sm={12} md={4} lg={4}>
                  <Field
                    name="escolarity"
                    component={RenderSelectFieldWithSearch}
                    label="Escolaridade"
                    validate={[required]}
                    props={{
                      options: [
                        { value: 'not_literate', label: 'Não Alfabetizado' },
                        { value: 'incomplete_elementary', label: 'Fundamental Incompleto' },
                        { value: 'complete_elementary', label: 'Fundamental Completo' },
                        { value: 'incomplete_medium', label: 'Médio Incompleto' },
                        { value: 'full_medium', label: 'Médio Completo' },
                        { value: 'incomplete_technician', label: 'Técnico Incompleto' },
                        { value: 'full_technician', label: 'Técnico Completo' },
                        { value: 'incomplete_higher', label: 'Superior Incompleto' },
                        { value: 'graduated', label: 'Superior Completo' },
                        { value: 'not_informed', label: 'Não informado' },
                      ],
                      isDisabled: false,
                      isSearchable: true,
                      isLoading: false,
                      isMulti: false,
                    }}
                  />
                </FullColumn>
              </Row>
            </div>
          )}

          {!hidePassword && (
            <Row>
              <FullColumn sm={12} md={6} lg={6}>
                <div className="mainFormsInfoField columns">
                  <Field
                    name="password"
                    component={RenderTextField}
                    label="Senha"
                    validate={[required]}
                    type="password"
                  />
                </div>
              </FullColumn>
              <FullColumn sm={12} md={6} lg={6}>
                <div className="mainFormsInfoField columns">
                  <Field
                    name="password_confirmation"
                    component={RenderTextField}
                    label="Confirme a senha"
                    validate={[required, passwordsMatch]}
                    type="password"
                  />
                </div>
              </FullColumn>
            </Row>
          )}
        </div>
        <div className="mateFormsFooter">
          <Row>
            <FullColumn>
              {!hidePassword && ( 
                <div className="mainFormsInfoField" style={{ marginLeft: 10 }}>
                  <Field
                    name="term"
                    component={RenderCheckBoxField}
                    label={
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => toggleModalPrivacityTerm()}
                      >
                        Aceito os termos de privacidade
                      </Button>
                    }
                  />
                </div>
              )}
            </FullColumn>
          </Row>
          
          <div className="mateFormsSubmit">
            <Row>
              <FullColumn md={2} lg={2} xs={6} sm={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.createButton}
                  disabled={(formState && formState.term && formState.term) || hidePassword ? false : true}
                >
                  Cadastrar
                </Button>
              </FullColumn>
              {!hideBtnBack && (
                <FullColumn md={2} lg={2} xs={6} sm={6}>
                  <Link className="decoration-none" to={`/ouvidoria/${slug}`}>
                    <div className="login__button--register">
                      <Button color="primary" size="large">
                        Voltar
                      </Button>
                    </div>
                  </Link>
                </FullColumn>
              )}
            </Row>
          </div>
        </div>
      </form>
  );
};

const reduxOuvidoriaForm = reduxForm({
  form: 'OuvidoriaForm',
  enableReinitialize: true,
})(OuvidoriaForm);


const mapStateToProps = (state) => {
  const formState = getFormValues('OuvidoriaForm')(state);

  return {
    formState,
    documentType: state.UserOuvidoria.documentType,
  };
};

const Connect = connect(mapStateToProps)(reduxOuvidoriaForm);
export default withStyles(styles)(Connect);