const options = [
  {
    label: 'Dashboard',
    icon: 'dashboard',
    key: '',
    allow: (user) => {
      return ['admin','data_manager', 'master_one', 'master_two', 'sector_manager_one', 'sector_manager_two'].includes(user.attributes.role);
    }
  },
  // {
  //   label: 'Criar CI',
  //   icon: 'add',
  //   key: 'tickets-sent?action=create',
  //   allow: (user) => {
  //     return ['data_manager', 'master_one', 'master_two', 'sector_manager_one', 'sector_manager_two'].includes(user.attributes.role);
  //   }
  // },
  {
    label: 'Clientes',
    icon: 'face',
    key: 'institutions',
    allow: (user) => {
      return ['admin'].includes(user.attributes.role);
    }
  },
  {
    label: 'Comunicados Enviados',
    icon: 'call_made',
    key: 'tickets-sent',
    allow: (user) => {
      return ['data_manager', 'master_one', 'master_two', 'sector_manager_one', 'sector_manager_two'].includes(user.attributes.role);
    }
  },
  {
    label: 'Comunicados Recebidos',
    icon: 'call_received',
    key: 'tickets-received',
    allow: (user) => {
      return ['data_manager', 'master_one', 'master_two', 'sector_manager_one', 'sector_manager_two'].includes(user.attributes.role);
    }
  },
  {
    label: 'Todos os comunicados',
    icon: 'compare_arrows',
    key: 'tickets-relative-all',
    path: 'tickets-relative',
    allow: (user) => {
      return ['data_manager', 'master_one'].includes(user.attributes.role);
    }
  },
  {
    label: 'Comunicados do setor',
    icon: 'compare_arrows',
    key: 'tickets-relative-sector',
    path: 'tickets-relative',
    allow: (user) => {
      return ['sector_manager_one'].includes(user.attributes.role);
    }
  },
  {
    label: 'Agenda',
    icon: 'date_range',
    key: 'todo-lists',
    allow: (user) => {
      return ['data_manager', 'master_one', 'master_two', 'sector_manager_one', 'sector_manager_two'].includes(user.attributes.role);
    }
  },
  {
    label: 'Memorandos Circulares',
    icon: 'assignment',
    key: 'announcements',
    allow: (user) => {
      const {
        attributes: {
          role,
          announcementByInstitution,
          announcementBySector,
        }
      } = user;
      const allowedRole = ['data_manager', 'master_one', 'master_two', 'sector_manager_one'].includes(role);
      return allowedRole || (announcementByInstitution || announcementBySector);
    }
  },
  {
    label: 'Atualizações',
    icon: 'campaign',
    key: 'notices',
    allow: (user) => {
      const {
        attributes: {
          role,
        }
      } = user;
      const allowedRole = ['admin'].includes(role);
      return allowedRole;
    }
  },
  {
    label: 'Ícones',
    icon: 'category',
    key: 'icons',
    allow: (user) => {
      const {
        attributes: { role },
      } = user;
      return ['admin'].includes(role);
    },
  },
  {
    label: 'Cloud Box',
    icon: 'cloud',
    key: 'locker',
    allow: (user) => {
      return ['data_manager', 'master_one', 'master_two', 'sector_manager_one', 'sector_manager_two'].includes(user.attributes.role);
    }
  },
  {
    label: 'Setores',
    icon: 'auto_awesome_mosaic',
    key: 'sectors',
    allow: (user) => {
      return ['data_manager', 'admin'].includes(user.attributes.role);
    }
  },
  {
    label: 'Categorias',
    icon: 'view_carousel',
    key: 'categories',
    allow: (user) => {
      return ['data_manager', 'admin'].includes(user.attributes.role);
    }
  },
  {
    label: 'Assuntos',
    icon: 'subject',
    key: 'subjects',
    allow: (user) => {
      return ['data_manager', 'admin', 'master_one', 'sector_manager_one'].includes(user.attributes.role);
    }
  },
  {
    label: 'Usuários',
    icon: 'people',
    key: 'users',
    allow: (user) => {
      return ['data_manager', 'admin', 'master_one'].includes(user.attributes.role);
    }
  },
  // {
  //   label: 'Ouvidoria',
  //   icon: 'record_voice_over',
  //   key: 'users-ouvidoria',
  //   allow: (user) => {
  //     return ['data_manager', 'admin', 'master_one'].includes(user.attributes.role);
  //   }
  // },
  {
    label: 'Controle de Franquia',
    icon: 'storage',
    key: 'storage',
    allow: (user) => {
      return ['data_manager'].includes(user.attributes.role);
    },
  },
  {
    label: 'Cidades',
    icon: 'maps_home_work',
    key: 'cities',
    allow: (user) => {
      return ['admin'].includes(user.attributes.role);
    },
  },
  {
    label: 'Ramo de atividades',
    icon: 'account_tree',
    key: 'branch-activities',
    allow: (user) => {
      return ['admin'].includes(user.attributes.role);
    },
  },
  {
    label: 'Bairros',
    icon: 'location_on',
    key: 'neighborhoods',
    allow: (user) => {
      return ['admin'].includes(user.attributes.role);
    },
  },
  {
    label: 'Administradores',
    icon: 'admin_panel_settings',
    key: 'admins',
    allow: (user) => {
      return ['admin'].includes(user.attributes.role);
    }
  },
  {
    label: 'Backup',
    icon: 'settings_backup_restore',
    key: 'backups',
    allow: (user) => {
      return ['master_one'].includes(user.attributes.role);
    }
  },
  {
    label: 'Ouvidoria',
    icon: 'record_voice_over',
    key: '../ouvidoria/dashboard',
    allow: (user) => {
      return (user.attributes.modules.includes('ombudsman') || user.attributes.ombudsmanAccess);
      //  && user.attributes.role !== "admin";
    },
  },
];

export default options;
