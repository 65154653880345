import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';
import FontIcon from '../../../../components/uielements/icon';

// Actions
import userActions from '../../../../redux/userOuvidoria/actions';
import ombudsmanActions from '../../../../redux/ombudsman/actions';
import protocolActions from '../../../../redux/protocol/actions';

import {
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableHead,
  TableRow,
} from '../../../../components/uielements/table';
import { Row, FullColumn } from '../../../../components/utility/rowColumn';
import LayoutWrapper from '../../../../components/utility/layoutWrapper';
import Button from '../../../../components/uielements/button';
import Scrollbars from '../../../../components/utility/customScrollBar';

import UserOmbudsmanFilter from './UserOmbudsmanFilter';
import CardWrapper, {
  ActionButtons,
  Table,
} from '../../../../components/stylePages/index.style';

import columns from './columns';

// Styles
import '../style.scss';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

const EnhancedTableHead = () => (
  <TableHead>
    <TableRow>
      <TableCell />
      {columns.map(column => (
        <TableCell key={column.rowKey}>{column.title}</TableCell>
      ))}
    </TableRow>
  </TableHead>
);

class UsersOuvidoria extends Component {
  componentDidMount() {
    const {
      institutionId,
      currentUser,
      clearUsersOuvidoria,
    } = this.props;

    if (currentUser.data.attributes.institutionId || institutionId) {
      this.fetchAllRequests();
    } else {
      clearUsersOuvidoria();
    }
  }

  fetchAllRequests = () => {
    const {
      fetchRequest,
    } = this.props;
    const params = {
      per_page: 10,
      page: 1,
    };

    fetchRequest(params);
  }

  handleChangePage = (_event, page) => {
    const { fetchRequest, pagination } = this.props;

    const params = { per_page: pagination.itemsPerPage, page: page + 1 };

    fetchRequest(params);
  };

  handleChangeRowsPerPage = event => {
    const { fetchRequest } = this.props;

    const params = {
      per_page: event.target.value,
      page: 1,
    };

    fetchRequest(params);
  };

  onFilterSubmit = form => {
    const { fetchRequest, saveFilter } = this.props;
    const formValue = {
      document_eq: form && form.document_eq ? form.document_eq : undefined,
    };

    fetchRequest(formValue);
    saveFilter(form);
  };

  render() {
    const {
      classes,
      usersOuvidoria,
      pagination,
      filterFormValues,
      isAdmin,
      changeUserId,
      currentUser,
      toggleCreateUserNewProtocolModal,
      toggleNewProtocolModal,
    } = this.props;

    const openNewProtocolModal = (id) => {
      changeUserId(id);

      toggleNewProtocolModal();
    }

    const openCreateUserModal = () => {
      toggleCreateUserNewProtocolModal();
    }

    return (
      <LayoutWrapper className="UserIndex">
        <Row>
          <FullColumn>
            <CardWrapper>
              <div className={classes.root}>
                {/* <h3>Selecione o usuário:</h3>
 */}
                <UserOmbudsmanFilter
                  onSubmit={this.onFilterSubmit}
                  initialValues={filterFormValues}
                />

                <Scrollbars style={{ width: '100%' }}>
                  <Table className={classes.table}>
                    <EnhancedTableHead isAdmin={isAdmin} />
                    <TableBody>
                      {usersOuvidoria.length === 0 ? (
                        <TableRow>
                          <TableCell 
                            className="text-center" 
                            colSpan={columns.length}
                          >
                            {currentUser.data.attributes.ombudsmanPermission === 'full_access' && (
                              <FullColumn>
                                <span>Nenhum usuário corresponde a busca</span>

                                <ActionButtons>
                                  <div className="tableBtn">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className="mateAddBtn"
                                      onClick={() => openCreateUserModal()}
                                    >
                                      Criar usuário
                                    </Button>
                                  </div>
                                </ActionButtons>
                              </FullColumn>
                            )}
                          </TableCell>
                        </TableRow>
                      ) : (
                        usersOuvidoria.map(user => {
                          return (
                            <TableRow key={user.id}>
                              <TableCell>
                                <Button 
                                  variant="outlined" 
                                  color="primary"
                                  onClick={() => openNewProtocolModal(user.id)}
                                >
                                  <FontIcon style={{ fontSize: 15 }}>
                                    add
                                  </FontIcon>
                                </Button>
                              </TableCell>
                              
                              {columns.map(column => (
                                <TableCell key={`${user.id}${column.rowKey}`}>
                                  {column.render(
                                    user.attributes[column.dataIndex]
                                  ) || ''}
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={pagination.totalItems}
                          rowsPerPage={pagination.itemsPerPage}
                          page={pagination.currentPage - 1}
                          labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} de ${count}`
                          }
                          rowsPerPageOptions={[10, 15, 25, 50]}
                          labelRowsPerPage="Exibir por página:"
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Scrollbars>
              </div>
            </CardWrapper>
          </FullColumn>
        </Row>
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = state => {
  const isAdmin = state.session.user.data.attributes.role === 'admin';
  return {
    ...state.UserOuvidoria,
    isAdmin,
    currentUser: state.session.user,
  };
};

const Connect = connect(mapStateToProps, {
  ...userActions,
  createExternalUser: ombudsmanActions.createExternalUser,
  toggleCreateUserNewProtocolModal: protocolActions.toggleCreateUserNewProtocolModal,
  toggleNewProtocolModal: protocolActions.toggleNewProtocolModal,
})(UsersOuvidoria);

export default withStyles(styles)(Connect);
