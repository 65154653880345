import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Select, MenuItem } from '@material-ui/core';
import InputMask from "react-input-mask";
import {
  FormControl,
  FormHelperText,
  InputLabel,
} from '../../uielements/form'
import TextField from '../../uielements/textfield';
import { FullColumn, Row } from '../../utility/rowColumn';

import externalUserActions from '../../../redux/userOuvidoria/actions';

import './style.scss';

const RenderTextMaskField = ({
  input,
  label,
  documentDefault,

  meta: { touched, error },
  ...custom
}) => {
  const dispatch = useDispatch();

  const {
    getDocumentTypeExternalUser,
  } = externalUserActions;

  const [mask, setActiveMask] = useState('999.999.999-99');
  const [document, setDocument] = useState(0);

  const setDocumentType = useCallback((type) => {
    if (type === 0) {
      dispatch(getDocumentTypeExternalUser('CPF'));
    }

    if (type === 1) {
      dispatch(getDocumentTypeExternalUser('CNPJ'));
    }
  }, [dispatch, getDocumentTypeExternalUser]);

  useEffect(() => {
    if (documentDefault && documentDefault.length > 11) {
      setDocument(1);
      setActiveMask('99.999.999/9999-99');
    }
  }, [documentDefault]);

  return (
    <Row>
      <FullColumn xs={12} lg={3} style={{ marginBottom: 'auto' }}>
        <InputLabel id="document-type">Tipo</InputLabel>
        <Select
          className="selectDocument"
          id="document-type"
          value={document}
          onChange={e => {
              setDocument(e.target.value);
              setActiveMask(e.target.value === 0 ? '999.999.999-99': '99.999.999/9999-99');
              setDocumentType(e.target.value === 0 ? 0 : 1)
            }
          }
        >
          <MenuItem value={0}>CPF</MenuItem>
          <MenuItem value={1}>CNPJ</MenuItem>
        </Select>
      </FullColumn>
      <FullColumn xs={12} lg={9}>
        <FormControl value={input.value}>
          <InputMask
            mask={mask}
            label={label}
            {...input}
            {...custom}
          >
            <TextField />
          </InputMask>
          {touched && error ? <FormHelperText>{error}</FormHelperText> : ''}
        </FormControl>
      </FullColumn>
    </Row>
  );
};

export default RenderTextMaskField;
