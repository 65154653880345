import { PURGE } from "redux-persist";
import actions from "./actions";

const initState = {
  idToken: null
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.CHECK_SUCCESS:
      return {
        idToken: action.payload.token,
      };
    case actions.LOGIN_SUCCESS:
      return {
        idToken: action.payload.token,
      };
    case PURGE:
      return initState;
    case actions.LOGOUT:
      return initState;
    case actions.LOGOUT_OMBUDSMAN:
      return initState;
    default:
      return state;
  }
}
