import Auth from './auth/reducer';
import Confirmation from './confirmation/reducer';
import App from './app/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';

// Pages
import Institution from './institution/reducer';
import Sector from './sector/reducer';
import Grouping from './grouping/reducer';
import Subject from './subject/reducer';
import Category from './category/reducer';
import Question from './question/reducer';
import Ticket from './ticket/reducer';
import Trade from './trade/reducer';
import User from './user/reducer';
import UserOuvidoria from './userOuvidoria/reducer';
import Announcement from './announcement/reducer';
import Dashboard from './dashboard/reducer';
import Profile from './profile/reducer';
import SearchProtocol from './searchProtocol/reducer';
import TodoList from './todoList/reducer';
import UserNotification from './userNotification/reducer';
import Backup from './backup/reducer';
import Locker from './locker/reducer';
import Draft from './draft/reducer';
import Notice from './notice/reducer';
import Ombudsman from './ombudsman/reducer';
import State from './state/reducer';
import ExternalAuth from './externalAuth/reducer';
import City from './city/reducer';
import BranchActivity from './branchActivity/reducer';
import Protocol from './protocol/reducer';
import Icon from './icon/reducer';
import Slide from './slides/reducer';
import Address from './address/reducer';
import Neighborhood from './neighborhood/reducer';

export default {
  Auth,
  Confirmation,
  UserNotification,
  App,
  Dashboard,
	Institution,
	Sector,
  Grouping,
	Subject,
	Category,
	Ticket,
  Trade,
  User,
  UserOuvidoria,
  Announcement,
  Profile,
	ThemeSwitcher,
  SearchProtocol,
  TodoList,
  Backup,
  Folder: Locker,
  Draft,
  Notice,
  Ombudsman,
  Question,
  State,
  ExternalAuth,
  City,
  BranchActivity,
  Protocol,
  Icon,
  Slide,
  Address,
  Neighborhood,
};
