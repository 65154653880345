import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, browserHistory } from 'react-router';
import { push } from 'react-router-redux'
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import AddIcon from '@material-ui/icons/Add';
import { Fab } from '@material-ui/core';
import Draggable from 'react-draggable'; 
import AppRouter from './appRouter';
import Sidebar from '../Sidebar';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import themeActions from '../../redux/themeSwitcher/actions';
import MUIPProvider from '../../components/uielements/materialUiPicker/momentProvider';
import { rtl } from '../../settings/withDirection';
import Main, { Root, AppFrame } from './style';
import Navigation from './Navigation';
import './global.css';

const { logout } = authAction;
const { toggleAll } = appActions;
const { switchActivation } = themeActions;

const App = props => {
  const history = useHistory();

  const anchor = rtl === 'rtl' ? 'right' : 'left';
  localStorage.setItem('currentMode', 'ci_digital');

  const {
    classes,
    theme,
    toggleAll,
    match,
    scrollHeight,
    currentUser,
  } = props;

  const { url } = match;
  const options = { url, classes, theme };

  const [isDragging, setDragging] = useState(false)
  
  function redirectToCreateTicket() {
    props.history.push('/dashboard/tickets-sent?action=create')
  }

  return (
    <Root>
      <Debounce time="1000" handler="onResize">
        <WindowResizeListener
          onResize={windowSize =>
            toggleAll(windowSize.windowWidth, windowSize.windowHeight)
          }
        />
      </Debounce>
      <AppFrame>
        <Navigation {...options} />
        <Main>
          <MUIPProvider>
            <AppRouter
              style={{ height: scrollHeight, overflowY: 'auto' }}
              url={url}
            />
          </MUIPProvider>
        </Main>

        {anchor === 'right' ? <Sidebar {...options} anchor={anchor} /> : ''}

        {currentUser.attributes.role !== 'admin' && (
         
          <Fab 
            color="primary" 
            aria-label="add" 
            size="large"
            style={{
              position: 'absolute',
              right: '5%',
              top: '85%',
              zIndex: 1,
            }}

            disabled={isDragging}
            onClick={redirectToCreateTicket}
          >
            <AddIcon />
          </Fab>

        )}
      </AppFrame>
    </Root>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.Auth,
    scrollHeight: state.App.scrollHeight,
    view: state.App.view,
    currentUser: state.session.user.data,
  };
};
const appConect = connect(
  mapStateToProps,
  {
    logout,
    toggleAll,
    switchActivation,
  }
)(App);
export default appConect;
