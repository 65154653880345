import { PURGE } from 'redux-persist';
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initState = {
  branchActivities: [],
  branchList: [],
  editableBranchActivity: {},
  filterFormValues: {},
  loading: false,
  modalNewBranchActivity: false,
  modalEditBranchActivity: false,
  pagination: {
    itemsPerPage: 10,
  },
};

const getBranchActivitiesSuccess = (state, action) => ({
  ...state,
  branchActivities: action.branchActivities,
  pagination: action.pagination,
  modalNewBranchActivity: false,
  modalEditBranchActivity: false,
});

const getBranchActivitiesListSuccess = (state, action) => ({
  ...state,
  branchList: action.branchList,
});

const createBranchActivity = (state) => ({
  ...state,
  modalNewBranchActivity: true,
});

const openNewModal = (state) => ({
  ...state,
  modalNewBranchActivity: true,
});

const openEditModal = (state, action) => {
  return {
    ...state,
    modalEditBranchActivity: true,
    editableBranchActivity: {
      id: action.branchActivity.id,
      name: action.branchActivity.name,
    },
  };
};

const createSuccess = (state) => ({
  ...state,
  loading: false,
  modalNewBranchActivity: false,
});

const closeNewModal = (state) => ({
  ...state,
  modalNewBranchActivity: false,
});

const closeEditModal = (state) => ({
  ...state,
  modalEditBranchActivity: false,
});

const editBranchActivity = (state) => ({
  ...state,
  modalEditBranchActivity: true,
});

const editSuccess = (state) => ({
  ...state,
  loading: false,
  modalEditBranchActivity: false,
});

const saveFilter = (state, action) => ({
  ...state,
  filterFormValues: action.params,
})

const purgeState = () => initState;

const HANDLERS = {
  [actions.GET_BRANCH_ACTIVITIES_SUCCESS]: getBranchActivitiesSuccess,
  [actions.GET_BRANCH_ACTIVITIES_LIST_SUCCESS]: getBranchActivitiesListSuccess,
  [actions.CREATE_BRANCH_ACTIVITY]: createBranchActivity,
  [actions.CREATE_BRANCH_ACTIVITY_SUCCESS]: createSuccess,
  [actions.OPEN_MODAL_NEW]: openNewModal,
  [actions.OPEN_MODAL_EDIT]: openEditModal,
  [actions.EDIT_BRANCH_ACTIVITY]: editBranchActivity,
  [actions.EDIT_BRANCH_ACTIVITY_SUCCESS]: editSuccess,
  [actions.CLOSE_MODAL_EDIT]: closeEditModal,
  [actions.CLOSE_MODAL_NEW]: closeNewModal,
  [actions.SAVE_FILTER]: saveFilter,
  [PURGE]: purgeState,
};

export default createMyReducer(initState, HANDLERS);
