const actions = {
  GET_ICONS: 'GET_ICONS/icons',
  GET_ICONS_SUCCESS: 'GET_ICONS_SUCCESS/icons',
  LIST_ICONS: 'LIST_ICONS/icons',
  LIST_ICONS_SUCCESS: 'LIST_ICONS_SUCCESS/icons',
  OPEN_MODAL_NEW: 'OPEN_MODAL_NEW/icons',
  CLOSE_MODAL_NEW: 'CLOSE_MODAL_NEW/icons',
  CREATE_ICON: 'CREATE_ICON/icons',
  CREATE_ICON_SUCCESS: 'CREATE_ICON_SUCCESS/icons',
  EDIT_ICON: 'EDIT_ICON/icons',
  OPEN_MODAL_EDIT: 'OPEN_MODAL_EDIT/icons',
  CLOSE_MODAL_EDIT: 'CLOSE_MODAL_EDIT/icons',
  EDIT_ICON_SUCCESS: 'EDIT_ICON_SUCCESS/icons',
  UPDATE_ICON: 'UPDATE_ICON/icons',
  SAVE_FILTER: 'SAVE_FILTER/icons',
  ERROR: 'ERROR/STATES',
  getIcons: (params) => ({ type: actions.GET_ICONS, params }),
  listIcons: () => ({ type: actions.LIST_ICONS }),
  createIcon: form => ({ type: actions.CREATE_ICON, form }),
  editIcon: (id, form) => ({ type: actions.UPDATE_ICON, id, form }),
  openNewModal: () => ({ type: actions.OPEN_MODAL_NEW }),
  openEditModal: (icon) => ({ type: actions.OPEN_MODAL_EDIT, icon }),
  closeNewModal: () => ({ type: actions.CLOSE_MODAL_NEW }),
  closeEditModal: () => ({ type: actions.CLOSE_MODAL_EDIT }),
  saveFilter: params => ({ type: actions.SAVE_FILTER, params }),
};

export default actions;
