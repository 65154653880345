import { PURGE } from "redux-persist";
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initForm = {
  id: null,
  name: '',
  phone: null,
  email: null,
  role: null,
  sex: null,
  escolarity: null,
  documentNumber: '',
  photo: null,
};

const initState = {
  formValues: initForm,
  sending: false,
  errorStatus: null,
  errors: null,
};

const updateRequest = state => {
  return {
    ...state,
    sending: true,
    errors: null,
    errorStatus: null,
  }
};

const updateSuccess = (state) => {
  return {
    ...state,
    sending: false,
  }
};

const setProfile = (state, action) => {
  return {
    ...state,
    formValues: {
      ...action.user,
      sex: {value: action.user.sex},
      escolarity: {value: action.user.escolarity}
    },
    errors: null,
    errorStatus: null,
  }
};

const fetchError = (state, action) => ({
  ...state,
  loading: false,
  sending: false,
  errorStatus: action.errorStatus,
  errors: action.errors,
});

const purgeState = () => initState;

const HANDLERS = {
  [actions.UPDATE_REQUEST]: updateRequest,
  [actions.UPDATED_SUCCESS]: updateSuccess,
  [actions.SET_PROFILE]: setProfile,
  [actions.UPDATE_REQUEST_EXTERNAL_USER]: updateRequest,
  [actions.UPDATED_SUCCESS_EXTERNAL_USER]: updateSuccess,
  [actions.SET_PROFILE_EXTERNAL_USER]: setProfile,
  [actions.ERROR]: fetchError,
  [PURGE]: purgeState,
};

const profileRecucer = createMyReducer(initState, HANDLERS);

export default profileRecucer;
