import { PURGE } from "redux-persist";
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initForm = {
  id: null,
  name: '',
  email: null,
  phone: null,
  document: null,
  documentNumber: '',
  password: null,
  confirm_password: null,
  status: false,
  backupAllowed: false,
};

const initFilter = {};

const initState = {
  usersOuvidoria: [],
  formValues: initForm,
  filterFormValues: initFilter,
  currentExternalUser: {},
  userId: null,
  documentType: 'CPF',
  editable: {},
  pagination: {
    itemsPerPage: 10,
  },
  sending: false,
  loading: true,
  modalNew: false,
  modalEdit: false,
  modalCreateProtocol: false,
  modalFilter: false,
  modalChangeEmail: false,
  enableEdit: false,
  errorStatus: null,
  errors: null,
};

const fetchRequest = state => ({
  ...state,
  loading: true,
  errorStatus: null,
});

const fetchSuccess = (state, action) => ({
  ...state,
  usersOuvidoria: action.data,
  pagination: action.pagination,
  loading: false,
});

const setUser = (state, action) => {
  return {
    ...state,
    editable: {
      ...action.user.attributes,
    },
    modalEdit: true,
  }
};

const getUser = state => {
  return {
    ...state,
    currentExternalUser: {},
    loading: true,
    errorStatus: null,
  }
}

const getSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    currentExternalUser: {
      ...action.data,
    },
  }
}

const changeUserId = (state, action) => {
  return {
    ...state,
    userId: action.id,
  }
}

const clearUsersOuvidoria = state => ({
  ...state,
  usersOuvidoria: [],
  pagination: initState.pagination,
});

const toogleModalNew = state => ({
  ...state,
  modalNew: !state.modalNew,
});

const toogleModalEdit = state => ({
  ...state,
  modalEdit: !state.modalEdit,
});

const toggleModalChangeEmail = state => ({
  ...state,
  modalChangeEmail: !state.modalChangeEmail,
});

const toggleModalCreateProtocol = state => ({
  ...state,
  modalCreateProtocol: !state.modalCreateProtocol,
});

const createUser = state => ({
  ...state,
  sending: true,
});

const createUserSuccess = (state, action) => ({
  ...state,
  usersOuvidoria: [action.data, ...state.usersOuvidoria],
  formValues: initForm,
  modalNew: false,
  sending: false,
});

const updateUser = state => ({
  ...state,
  sending: true,
});

const updateUserSuccess = (state, action) => ({
  ...state,
  usersOuvidoria: state.usersOuvidoria.map(user =>
    user.id === action.data.id ? { ...action.data } : user
  ),
  sending: false,
});

const toggleModalFilter = state => ({
  ...state,
  modalFilter: !state.modalFilter,
});

const saveFilterForm = (state, action) => ({
  ...state,
  filterFormValues: action.params,
});

const activateSuccess = (state, action) => ({
  ...state,
  currentExternalUser: {
    ...action.data,
    included: action.included,
  },
});

const getDocumentTypeExternalUser = (state, action) => ({
  ...state,
  documentType: action.documentType,
});

const fetchError = (state, action) => ({
  ...state,
  loading: false,
  sending: false,
  errorStatus: action.errorStatus,
  errors: action.errors,
});

const purgeState = () => initState;

const HANDLERS = {
  [actions.FETCH_REQUEST]: fetchRequest,
  [actions.FETCH_SUCCESS]: fetchSuccess,
  [actions.SET]: setUser,
  [actions.GET]: getUser,
  [actions.GET_SUCCESS]: getSuccess,
  [actions.TOGGLE_MODAL_NEW]: toogleModalNew,
  [actions.TOGGLE_MODAL_EDIT]: toogleModalEdit,
  [actions.TOGGLE_MODAL_CREATE_PROTOCOL]: toggleModalCreateProtocol,
  [actions.CREATE]: createUser,
  [actions.CREATE_SUCCESS]: createUserSuccess,
  [actions.UPDATE]: updateUser,
  [actions.UPDATED_SUCCESS]: updateUserSuccess,
  [actions.UPDATE_EMAIL_EXTERNAL_USER_SUCCESS]: getSuccess,
  [actions.CLEAR_USERS]: clearUsersOuvidoria,
  [actions.TOGGLE_MODAL_FILTER]: toggleModalFilter,
  [actions.TOGGLE_MODAL_CHANGE_EMAIL]: toggleModalChangeEmail,
  [actions.SAVE_FILTER_FORM]: saveFilterForm,
  [actions.ACTIVATE_SUCCESS]: activateSuccess,
  [actions.CHANGE_USER_ID]: changeUserId,
  [actions.GET_DOCUMENT_TYPE_EXTERNAL_USER]: getDocumentTypeExternalUser,
  [actions.ERROR]: fetchError,
  [PURGE]: purgeState,
};

const userRecucer = createMyReducer(initState, HANDLERS);

export default userRecucer;
