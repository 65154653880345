import React from 'react'
import {
  Dialog,
  // DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  CloseIcon,
  Typography,
} from '../uielements/dialogs'

import './style.scss';

const SimpleDialog = (props) => {
  const {
    open,
    toggleModal,
    title,
    children,
    style,
    className,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={toggleModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      style={style}
      className={`NovoDialog ${className}`}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">{title}</Typography>
        {toggleModal ? (
          <IconButton aria-label="close" onClick={toggleModal} className="closeButton">
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default SimpleDialog;
