import React from 'react';
import Checkbox from '@material-ui/core/Checkbox'
import {
  FormControlLabel,
} from "../../uielements/form"

export default ({
  input, label,
}) => {

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value}
          onChange={input.onChange}
          color="primary"
        />
      }
      label={label}
    />
  );
}
