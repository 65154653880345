
import { select, all, call, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
import { API } from '../../settings';
import {
  fetchApi,
  postApi,
  putApi,
  deleteApi,
} from '../../helpers/api';
import { notification } from '../../components';


function* listDrafts() {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);

    const { data } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/drafts`,
    );
    yield put({ type: actions.LIST_DRAFTS_SUCCESS, data });
  } catch (error) {
    yield put({ type: actions.DRAFT_FINALLY });
    notification('error', 'Erro ao listar rascunhos, tente novamente mais tarde');
  } finally {
    yield put({ type: actions.DRAFT_FINALLY });
  }
}

function* fetchDraft(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);
    const { data } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/drafts/${action.uuid}`,
    );
    yield put({ type: actions.FETCH_DRAFT_SUCCESS, data, uuid: action.uuid });
  } catch (error) {
    yield put({ type: actions.DRAFT_FINALLY });
    notification('error', 'Erro ao carregar rascunho, tente recarregar a página');
  } finally {
    yield put({ type: actions.DRAFT_FINALLY });
  }
}

function* createDraft(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);
    const { attachments, ...ticket } = action.draft;

    const { data } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/drafts`,
      { ticket },
    );
    yield all([
      put({ type: actions.FETCH_DRAFT_SUCCESS, data: { ...data, attachments }, uuid: data.uuid }),
      put({ type: actions.LIST_DRAFTS }),
    ])
  } catch (error) {
    yield put({ type: actions.DRAFT_FINALLY });
    notification('error', 'Erro ao criar rascunho, tente recarregar a página');
  } finally {
    yield put({ type: actions.DRAFT_FINALLY });
  }
}

function* updateDraft(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);
    const { attachments, ...ticket } = action.draft;
   const { data } =  yield call(
     putApi,
     `${API.url}/institutions/${institutionId}/drafts/${action.uuid}`,
     { ticket },
     );
    if (data.update_list) {
      yield listDrafts();
    }

    yield put({ type: actions.UPDATE_DRAFT_SUCCESS });
  } catch (error) {
    yield put({ type: actions.DRAFT_FINALLY });
    notification('error', 'Erro ao carregar rascunho, tente recarregar a página');
  } finally {
    yield put({ type: actions.DRAFT_FINALLY });
  }
}

function* removeDraft(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);
    yield call(
      deleteApi,
      `${API.url}/institutions/${institutionId}/drafts/${action.uuid}`,
    );
    yield put({ type: actions.LIST_DRAFTS });
  } catch (error) {
    yield put({ type: actions.DRAFT_FINALLY });
    notification('error', 'Erro ao carregar rascunho, tente recarregar a página');
  } finally {
    yield put({ type: actions.DRAFT_FINALLY });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_DRAFTS, listDrafts),
    takeEvery(actions.FETCH_DRAFT, fetchDraft),
    takeEvery(actions.CREATE_DRAFT, createDraft),
    takeEvery(actions.UPDATE_DRAFT, updateDraft),
    takeEvery(actions.REMOVE_DRAFT, removeDraft),
  ]);
}
