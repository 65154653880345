import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { reset } from 'redux-form';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi, postApi, putApi, stringifyQueryParams } from '../../helpers/api'
import { notification } from '../../components';

function* fetchCategories(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/categories.json?${stringifyQueryParams(action.params)}`,
      {},
      { category_type: localStorage.getItem('currentMode') },
    );

    yield put({ type: actions.FETCH_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* fetchResources(action) {
  try {
    const { institutionId } = action;

    const sessionInstitutionId = yield select(
      state => state.session.user.data.attributes.institutionId
    );

    const id = institutionId || sessionInstitutionId;

    const { data } = yield call(
      fetchApi,
      `${API.url}/resources/institutions/${id}/sectors.json`,
      {},
      { sector_type: localStorage.getItem('currentMode') }
    );

    yield put({
      type: actions.FETCH_RESOURCES_SUCCESS,
      sectors: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  }
}

function* createCategory(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/categories/`,
      action.form,
    );

    notification('success', 'Categoria cadastrado com sucesso');
    yield put(reset('CategoryForm'));
    yield put({ type: actions.CREATE_SUCCESS, data });
  } catch (error) {
    notification('error', `${error.response.data.errors.full_messages}`);
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages
    });
  }
}

function* updateCategory(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/categories/${action.form.id}`,
      action.form,
    );
    notification('success', `Categoria ${action.form.name} atualizada com sucesso`);
    yield put({ type: actions.UPDATED_SUCCESS, data });
  } catch (error) {
    notification('error', `${error.response.data.errors.full_messages}`);
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_REQUEST, fetchCategories),
    takeEvery(actions.CREATE, createCategory),
    takeEvery(actions.UPDATE, updateCategory),
    takeEvery(actions.FETCH_RESOURCES, fetchResources),
  ])
}
