import { PURGE } from 'redux-persist';
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initState = {
  cities: [],
  editableCity: {},
  filterFormValues: {},
  loading: false,
  modalNewCity: false,
  modalEditCity: false,
  pagination: {
    itemsPerPage: 10,
  },
};

const getCitiesSuccess = (state, action) => ({
  ...state,
  cities: action.cities,
  pagination: action.pagination,
  modalNewCity: false,
  modalEditCity: false,
});

const createCity = (state) => ({
  ...state,
  modalNewCity: true,
});

const openNewModal = (state) => ({
  ...state,
  modalNewCity: true,
});

const openEditModal = (state, action) => {
  return {
    ...state,
    modalEditCity: true,
    editableCity: {
      id: action.city.id,
      name: action.city.name,
      state_id: { value: action.city.stateId.toString() },
    },
  };
};

const createSuccess = (state) => ({
  ...state,
  loading: false,
  modalNewCity: false,
});

const closeNewModal = (state) => ({
  ...state,
  modalNewCity: false,
});

const closeEditModal = (state) => ({
  ...state,
  modalEditCity: false,
});

const editCity = (state) => ({
  ...state,
  modalEditCity: true,
});

const editSuccess = (state) => ({
  ...state,
  loading: false,
  modalEditCity: false,
});

const saveFilter = (state, action) => ({
  ...state,
  filterFormValues: action.params,
})

const purgeState = () => initState;

const HANDLERS = {
  [actions.GET_CITIES_SUCCESS]: getCitiesSuccess,
  [actions.CREATE_CITY]: createCity,
  [actions.CREATE_CITY_SUCCESS]: createSuccess,
  [actions.OPEN_MODAL_NEW]: openNewModal,
  [actions.OPEN_MODAL_EDIT]: openEditModal,
  [actions.EDIT_CITY]: editCity,
  [actions.EDIT_CITY_SUCCESS]: editSuccess,
  [actions.CLOSE_MODAL_EDIT]: closeEditModal,
  [actions.CLOSE_MODAL_NEW]: closeNewModal,
  [actions.SAVE_FILTER]: saveFilter,
  [PURGE]: purgeState,
};

export default createMyReducer(initState, HANDLERS);
