import { call, all, takeLatest, put } from 'redux-saga/effects';
import { API } from '../../settings';
import { fetchApi } from '../../helpers/api'
import actions from './actions';

function* findProtocol({form}) {
  try {
    const { data } = yield call(fetchApi, `${API.url}/search-protocol?protocol=${form.protocol}&token=${form.token}`);

    yield put({
      type: actions.FIND_SUCCESS,
      data
    });

  } catch (error) {
    yield put({ type: actions.ERROR });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.FIND_REQUEST, findProtocol),
  ]);
}
