import { PURGE } from 'redux-persist';
import createMyReducer from '../createMyReducer';
import actions from './actions';

const initState = {
  states: [],
  cities: [],
};

const getStatesSuccess = (state, action) => ({
  ...state,
  states: action.states,
});

const getCitiesSuccess = (state, action) => ({
  ...state,
  cities: action.cities,
});

const purgeState = () => initState;

const HANDLERS = {
  [actions.GET_STATES_SUCCESS]: getStatesSuccess,
  [actions.GET_STATE_CITIES_SUCCESS]: getCitiesSuccess,
  [PURGE]: purgeState,
};

export default createMyReducer(initState, HANDLERS);
