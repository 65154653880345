import { all, call, takeEvery, put, select } from 'redux-saga/effects';
import actions from './actions';
import { API } from '../../settings';
import { downloadApi } from '../../helpers/api';

function* fetchUsersRequest(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const blob = yield call(
      downloadApi,
      `${API.url}/institutions/${institutionId}/backup/users.${action.filetype}`
    );
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `usuarios.${action.filetype}`);
    document.body.appendChild(link);
    link.click();

    yield put({ type: actions.FETCH_USERS_SUCCESS });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  }
}

function* fetchCis(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const blob = yield call(
      downloadApi,
      `${API.url}/institutions/${institutionId}/backup/tickets.${action.filetype}`
    );
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `comunicados.${action.filetype}`);
    document.body.appendChild(link);
    link.click();
    yield put({ type: actions.CI_SUCCESS });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  }
}

function* fetchReplies(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const blob = yield call(
      downloadApi,
      `${API.url}/institutions/${institutionId}/backup/replies.${action.filetype}`
    );
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `respostas.${action.filetype}`);
    document.body.appendChild(link);
    link.click();

    yield put({ type: actions.REPLIES_SUCCESS });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.FETCH_USERS_REQUEST, fetchUsersRequest),
    yield takeEvery(actions.CI_REQUEST, fetchCis),
    yield takeEvery(actions.REPLIES_REQUEST, fetchReplies),
  ]);
}
