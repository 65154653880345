const actions = {
  FETCH_REQUEST: 'FETCH_REQUEST/grouping',
  FETCH_SUCCESS: 'FETCH_SUCCESS/grouping',
  GET_SUCCESS: 'GET_SUCCESS/grouping',
  GET: 'GET/grouping',
  SET: 'SET/grouping',
  CREATE: 'ADD_ITEM/grouping',
  CREATE_SUCCESS: 'CREATE_SUCCESS/grouping',
  UPDATE: 'UPDATE/grouping',
  UPDATED_SUCCESS: 'UPDATED_SUCCESS/grouping',
  SELECT_CURRENT: 'SELECT_CURRENT/grouping',
  TOGGLE_VIEW: 'TOGGLE_VIEW/grouping',
  EDIT: 'EDIT/grouping',
  CHANGE_STATUS: 'CHANGE_STATUS/grouping',
  OPEN_MODAL_NEW: 'OPEN_MODAL_NEW/grouping',
  TOGGLE_MODAL_NEW: 'TOGGLE_MODAL_NEW/grouping',
  TOGGLE_MODAL_EDIT: 'TOGGLE_MODAL_EDIT/grouping',
  OPEN_MODAL_FILTER: 'OPEN_MODAL_FILTER/grouping',
  TOGGLE_MODAL_FILTER: 'TOGGLE_MODAL_FILTER/grouping',
  SAVE_FILTER_FORM: 'SAVE_FILTER_FORM/grouping',
  FETCH_SECTION_BY_GROUPING: 'FETCH_SECTION_BY_GROUPING/grouping',
  FETCH_SECTION_BY_GROUPING_SUCCESS: 'FETCH_SECTION_BY_GROUPING_SUCCESS/grouping',
  ERROR: 'ERROR/grouping',
  fetchRequest: (params) => ({ type: actions.FETCH_REQUEST, params }),
  openModalNew: () => ({ type: actions.OPEN_MODAL_NEW }),
  closeModalNew: () => ({ type: actions.CLOSE_MODAL_NEW }),
  toggleModalNew: () => ({ type: actions.TOGGLE_MODAL_NEW }),
  closeModalEdit: () => ({ type: actions.CLOSE_MODAL_EDIT }),
  toggleModalEdit: () => ({ type: actions.TOGGLE_MODAL_EDIT }),
  openModalFilter: () => ({ type: actions.OPEN_MODAL_FILTER }),
  toggleModalFilter: () => ({ type: actions.TOGGLE_MODAL_FILTER }),
  saveFilter: params => ({ type: actions.SAVE_FILTER_FORM, params }),
  addGrouping: form => ({ type: actions.CREATE, form }),
  setGrouping: grouping => ({ type: actions.SET, grouping }), // Open modal edit
  changeGrouping: form => ({ type: actions.UPDATE, form }),
  getGrouping: id => ({ type: actions.GET, id }),
  fetchSectionsByGrouping: id => ({ type: actions.FETCH_SECTION_BY_GROUPING, id }),
};

export default actions;
