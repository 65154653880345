import createMyReducer from '../createMyReducer';
import actions from './actions';

const initState = {
  ombudsman: JSON.parse(localStorage.getItem('ombudsman') || null),
  groupings: [],
  sectors: [],
  categories: [],
  subjects: [],
  slug: localStorage.getItem('slug'),
  loading: false,
  graphs: {},
  survey: {},
  currentExternalUser: {},
  toggleGraph: false,
  toggleLaw: false,
  toggleInfos: false,
  toggleLogin: false,
  modalPrivacityTerm: false,
  statusError: null,
};

const getOmbudsman = (state) => ({
  ...state,
  loading: true,
});

const toggleModal = (state) => ({
  ...state,
  toggleGraph: !state.toggleGraph,
});

const toggleLawModal = (state) => ({
  ...state,
  toggleLaw: !state.toggleLaw,
});

const toggleModalPrivacityTerm = state => ({
  ...state,
  modalPrivacityTerm: !state.modalPrivacityTerm,
});

const toggleModalInfos = state => ({
  ...state,
  toggleInfos: !state.toggleInfos,
});

const toggleModalLogin = state => ({
  ...state,
  toggleLogin: !state.toggleLogin,
});

const getOmbudsmanSuccess = (state, action) => {
  localStorage.setItem('ombudsman', JSON.stringify(action.data));
  localStorage.setItem('slug', action.data.attributes.slug);

  return {
    ...state,
    ombudsman: JSON.parse(localStorage.getItem('ombudsman') || null),
    slug: localStorage.getItem('slug'),
    loading: false,
  }
};

const getGroupingsSuccess = (state, action) => {
  return {
    ...state,
    groupings: action.data,
    sectors: [],
  };
}

const getSectorsSuccess = (state, action) => {
  return {
    ...state,
    sectors: action.data,
    subjects: [],
  };
}

const getCategorySuccess = (state, action) => {
  return {
    ...state,
    categories: action.data,
  };
}

const getSubjectSuccess = (state, action) => {
  return {
    ...state,
    subjects: action.data,
  };
}

const onError = (state, action) => ({
  ...state,
  loading: false,
  statusError: action.error,
});

const getGraphsSuccess = (state, action) => ({
  ...state,
  graphs: action.data,
});

const createExternalUserSuccess = (state, action) => {
  return {
    ...state,
    currentExternalUser: action.data
  }
};

const getSurveySuccess = (state, action) => ({
  ...state,
  survey: action.data,
});

const HANDLERS = {
  [actions.GET_OMBUDSMAN]: getOmbudsman,
  [actions.GET_OMBUDSMAN_SUCCESS]: getOmbudsmanSuccess,
  [actions.GET_SECTORS_SUCCESS]: getSectorsSuccess,
  [actions.GET_GROUPINGS_SUCCESS]: getGroupingsSuccess,
  [actions.GET_CATEGORIES_SUCCESS]: getCategorySuccess,
  [actions.GET_SUBJECTS_SUCCESS]: getSubjectSuccess,
  [actions.TOGGLE_GRAPH_MODAL]: toggleModal,
  [actions.TOGGLE_LAW_MODAL]: toggleLawModal,
  [actions.TOGGLE_MODAL_PRIVACITY_TERM]: toggleModalPrivacityTerm,
  [actions.TOGGLE_MODAL_INFOS]: toggleModalInfos,
  [actions.TOGGLE_MODAL_LOGIN]: toggleModalLogin,
  [actions.GET_GRAPHS_SUCCESS]: getGraphsSuccess,
  [actions.CREATE_EXTERNAL_USER_SUCCESS]: createExternalUserSuccess,
  [actions.GET_SATISFACTION_SURVEY_SUCCESS]: getSurveySuccess,
  [actions.ERROR]: onError,
};

export default createMyReducer(initState, HANDLERS);
