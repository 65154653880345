const actions = {
  FETCH_REQUEST: 'FETCH_REQUEST/notices',
  FETCH_SUCCESS: 'FETCH_SUCCESS/notices',
  FETCH_UNCONFIRMED_NOTICES: 'FETCH_UNCONFIRMED_NOTICES/notices',
  FETCH_UNCONFIRMED_NOTICES_SUCCESS: 'FETCH_UNCONFIRMED_NOTICES_SUCCESS/notices',
  CONFIRM_NOTICE: 'CONFIRM_NOTICE/notices',
  CONFIRM_NOTICE_SUCCESS: 'CONFIRM_NOTICE/notices',
  NOTICE_SEEN: 'NOTICE_SEEN/notices',
  GET_NOTICE: 'GET_NOTICE/notices',
  GET_NOTICE_SUCCESS: 'GET_NOTICE_SUCCESS/notices',
  GET: 'GET/notices',
  SET: 'SET/notices',
  CREATE: 'ADD_ITEM/notices',
  CREATE_SUCCESS: 'CREATE_SUCCESS/notices',
  UPDATE: 'UPDATE/notices',
  UPDATED_SUCCESS: 'UPDATED_SUCCESS/notices',
  SELECT_CURRENT: 'SELECT_CURRENT/notices',
  EDIT: 'EDIT/notices',
  OPEN_MODAL_NEW: 'OPEN_MODAL_NEW/notices',
  TOGGLE_MODAL_NEW: 'TOGGLE_MODAL_NEW/notices',
  TOGGLE_MODAL_EDIT: 'TOGGLE_MODAL_EDIT/notices',
  TOGGLE_VIEW: 'TOGGLE_VIEW/notices',
  DISMISS_NOTICE: 'DISMISS_NOTICE/notices',
  ERROR: 'ERROR/notices',
  getNotices: params => ({ type: actions.FETCH_REQUEST, params }),
  fetchUnconfirmedNotice: () => ({ type: actions.FETCH_UNCONFIRMED_NOTICES }),
  getNotice: id => ({ type: actions.GET_NOTICE, id }),
  confirmNotice: id => ({ type: actions.CONFIRM_NOTICE, id }),
  openModalNew: () => ({ type: actions.OPEN_MODAL_NEW }),
  closeModalNew: () => ({ type: actions.CLOSE_MODAL_NEW }),
  toggleModalNew: () => ({ type: actions.TOGGLE_MODAL_NEW }),
  closeModalEdit: () => ({ type: actions.CLOSE_MODAL_EDIT }),
  toggleModalEdit: () => ({ type: actions.TOGGLE_MODAL_EDIT }),
  addNotice: form => ({ type: actions.CREATE, form }),
  setNotice: notice => ({ type: actions.SET, notice }), // Open modal edit
  changeNotice: form => ({ type: actions.UPDATE, form }),
  markAsSeen: (id) => ({ type: actions.NOTICE_SEEN, id }),
  dismissNotice: () => ({ type: actions.DISMISS_NOTICE }),
};

export default actions;
