/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Debounce } from 'react-throttle';
import Draggable from 'react-draggable'; 

import WindowResizeListener from 'react-window-size-listener';
import AddIcon from '@material-ui/icons/Add';
import { Fab } from '@material-ui/core';
import SimpleDialog from '../../../components/simpleDialog';
import AppRouter from './ombudsmanRouter';
import Sidebar from '../../Sidebar';
import MUIPProvider from '../../../components/uielements/materialUiPicker/momentProvider';
import { rtl } from '../../../settings/withDirection';
import Navigation from '../Navigation';
import FilterUserModal from './modals/filterUserModal';
import CreateProtocol from '../../User/Ouvidoria/Show/CreateProtocol';
import NewForm from '../../Page/ouvidoria/ouvidoriaForm';

import authAction from '../../../redux/auth/actions';
import appActions from '../../../redux/app/actions';
import themeActions from '../../../redux/themeSwitcher/actions';
import protocolActions from '../../../redux/protocol/actions';
import ombudsmanActions from '../../../redux/ombudsman/actions';

import Main, { Root, AppFrame } from '../style';
import '../global.css';

const { logout } = authAction;
const { toggleAll } = appActions;
const { switchActivation } = themeActions;

const App = props => {
  const anchor = rtl === 'rtl' ? 'right' : 'left';
  localStorage.setItem('currentMode', 'ombudsman');
  const {
    classes,
    theme,
    match,
    scrollHeight,
    currentUser,
    toggleModalNewProtocol,
    userId,
    createProtocolExternalUser,
    currentExternalUser,
    createExternalUser,
    modalCreateProtocolAndUser,
    toggleModals,
    toggleModalsState,
    positionCreateProtocol,
  } = props;

  const { url } = match;
  const options = { url, classes, theme };

  const [isDragging, setDragging] = useState(false)

  useEffect(() => {
    toggleModalNewProtocol();
  }, [currentExternalUser]);

  const onSubmit = (form) => {
    const formData = new FormData();

    if (form.attachments !== undefined) {
      form.attachments.forEach((file, index) => {
        formData.append(`protocol[attachments][${index}]`, file);
      });
    }

    formData.append('protocol[sector_id]', form.sector_id.value);
    formData.append('protocol[category_id]', form.category_id.value);
    formData.append('protocol[subject_id]', form.subject_id.value);
    formData.append('protocol[description]', form.description);
    formData.append('protocol[relative_protocol]', form.relative_protocol);
    formData.append('protocol[secret]', form.secret || false);

    formData.append('protocol[kind_response]', form.kind_response.value);
    if (form.kind_response && form.kind_response === 'mail') {
      formData.append('protocol[address_id]', form.address_id.value);
    }
    if (form.state_id) {
      formData.append('protocol[state_id]', form.state_id.value);
    }

    if (form.city_id) {
      formData.append('protocol[city_id]', form.city_id.value);
    }

    if (form.neighborhood_id) {
      formData.append('protocol[neighborhood_id]', form.neighborhood_id.value);
    }

    formData.append('protocol[latitude]', positionCreateProtocol.lat);
    formData.append('protocol[longitude]', positionCreateProtocol.lng);

    createProtocolExternalUser(formData, currentExternalUser.id ? currentExternalUser.id : userId);
    toggleModalNewProtocol();
  }

  const createExternalUserCallback = useCallback((value) => {
    createExternalUser(value);
  }, [createExternalUser, toggleModalNewProtocol]);
  
  const handleSubmitCreateExternalUser = (form) => {
    const value = Object.assign({}, form);
    
    value.sex = (form.sex && form.sex.value) || 'unknow';
    value.escolarity = (form.escolarity && form.escolarity.value) || 'not_informed';
    
    createExternalUserCallback(value);
  }

  const onSubmitExternalUser = (value) => {
    handleSubmitCreateExternalUser(value);
  }

  return (
    <Root>
      <Debounce time="1000" handler="onResize">
        <WindowResizeListener
          onResize={windowSize =>
            toggleAll(windowSize.windowWidth, windowSize.windowHeight)
          }
        />
      </Debounce>
      <AppFrame>
        <Navigation {...options} />
        <Main>
          <MUIPProvider>
            <AppRouter
              style={{ height: scrollHeight, overflowY: 'auto' }}
              url={url}
            />
          </MUIPProvider>
        </Main>

        {anchor === 'right' ? <Sidebar {...options} anchor={anchor} /> : ''}

        {currentUser.attributes.ombudsmanAccess && (
          <Fab
            color="primary"
            aria-label="add"
            size="large"
            style={{
              position: 'absolute',
              right: '5%',
              top: '85%',
              zIndex: 1,
            }}
            disabled={isDragging}
            onClick={toggleModals}
          >
            <AddIcon />
          </Fab>
        )}
        
        <SimpleDialog
          title="Novo protocolo"
          className={
            !modalCreateProtocolAndUser.modalCreateProtocol && 
            !modalCreateProtocolAndUser.modalFilter  ? 
            'UserDialog' : 
            'NewProtocolDialog'
          }
          toggleModal={toggleModals}
          open={toggleModalsState}
          onClose={toggleModals}
        >
          {modalCreateProtocolAndUser.modalFilter && (
            <FilterUserModal />
          )}

          {modalCreateProtocolAndUser.modalCreateUser && (
            <NewForm 
             hideBtnBack 
             hidePassword
             onSubmit={onSubmitExternalUser}
           />
          )}

          {modalCreateProtocolAndUser.modalCreateProtocol && (
            <CreateProtocol 
              current={currentExternalUser.id ? currentExternalUser.id : userId} 
              onSubmit={onSubmit}
              onClose={toggleModals}
            />
          )}
        </SimpleDialog>
      </AppFrame>
    </Root>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.Auth,
    scrollHeight: state.App.scrollHeight,
    view: state.App.view,
    currentUser: state.session.user.data,
    modalNewProtocol: state.Protocol.modalNewProtocol,
    modalCreateProtocol: state.UserOuvidoria.modalCreateProtocol,
    userId: state.UserOuvidoria.userId,
    modalCreateUserNewProtocol: state.Protocol.modalCreateUserNewProtocol,
    currentExternalUser: state.Ombudsman.currentExternalUser,
    modalCreateProtocolAndUser: state.Protocol.modalCreateProtocolAndUser,
    toggleModalsState: state.Protocol.toggleModalsState,
    positionCreateProtocol: state.Protocol.position,
  };
};
const appConect = connect(
  mapStateToProps,
  {
    logout,
    toggleAll,
    switchActivation,
    toggleModalNewProtocol: protocolActions.toggleModalNewProtocol,
    createProtocolExternalUser: protocolActions.createProtocolExternalUser,
    toggleCreateUserNewProtocolModal: protocolActions.toggleCreateUserNewProtocolModal,
    createExternalUser: ombudsmanActions.createExternalUser,
    toggleModals: protocolActions.toggleModals,
  }
)(App);
export default appConect;
