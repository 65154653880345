import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import { API } from '../../settings';
import { downloadApi, fetchApi, postApi, putApi, stringifyQueryParams } from '../../helpers/api';
import { notification } from '../../components';
import { reset } from 'redux-form';


const defaultMessages = {
  400: 'Há erros no formulário',
  404: 'Não encontrado',
  500: 'Erro interno. Tente novamente mais tarde',
  403: 'Você não possui acesso',
};

function *fetchProtocols(action) {
  try {
    const userType = yield select(state => state.session.user.data.type);
    const institutionId = userType === 'externalUser' ?
      yield select(state => state.Ombudsman.ombudsman.id) :
      yield select(state => state.Dashboard.currentInstitution);

    const url = userType === 'externalUser' ?
      `${API.url}/institutions/${institutionId}/protocols.json?${stringifyQueryParams(action.params)}` :
      `${API.url}/institutions/${institutionId}/admin/protocols.json?${stringifyQueryParams(action.params)}`;

    const { data, pagination } = yield call(
      fetchApi,
      url,
      {},
      { institution_id: action.institutionId },
    );

    yield put({ type: actions.GET_PROTOCOLS_SUCCESS, data, pagination });
  } catch(error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  }
}

function *answerProtocol(action) {
  try {
    const userType = yield select(state => state.session.user.data.type);
    const institutionId = yield select(state => state.Ombudsman.ombudsman.id);
    const { data, included } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/protocols/${action.id}/answer`,
      action.form,
    );

    yield put({ type: actions.GET_PROTOCOL_SUCCESS, data, included, userType });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response && error.response.status,
      errors: error.response && error.response.data && error.response.data.error,
    });
  }
}

function *dispatchProtocol(action) {
  try {
    const userType = yield select(state => state.session.user.data.type);
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data, included } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/admin/protocols/${action.id}/dispatch_protocol`,
    );
    yield put({ type: actions.GET_PROTOCOL_SUCCESS, data, included, userType });
    notification('success', 'Setor notificado!');
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response && error.response.status,
      errors: error.response && error.response.data && error.response.data.error,
    });
  } finally {
    yield put({ type: actions.FINISHED_LOADING });
  }
}

function* downloadReport(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const blob = yield call(
      downloadApi,
      `${API.url}/institutions/${institutionId}/admin/protocols/${action.id}/report`
    );
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${action.identifier}.pdf`);
    document.body.appendChild(link);
    link.click();

  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  } finally {
    yield put({ type: actions.DOWNLOAD_REPORT_SUCCESS });
  }
}

function* downloadPdfReportFields(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);
    const params = new URLSearchParams(action.form);
    const blob = yield call(
      downloadApi,
      `${API.url}/institutions/${institutionId}/admin/protocols/status_report.pdf?${params}`
    );
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${action.label}.pdf`);
    document.body.appendChild(link);
    link.click();

  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  } finally {
    yield put({ type: actions.DOWNLOAD_PDF_REPORT_FIELD_SUCCESS });
  }
}

function* downloadXlsReportFields(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const params = new URLSearchParams(action.form);
    const blob = yield call(
      downloadApi,
      `${API.url}/institutions/${institutionId}/admin/protocols/status_report.xlsx?${params}`
    );
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${action.label}.xlsx`);
    document.body.appendChild(link);
    link.click();

  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  } finally {
    yield put({ type: actions.DOWNLOAD_XLS_REPORT_FIELD_SUCCESS });
  }
}

function* forwardProtocol(action) {
  try {
    const institutionId = yield select(
      state => state.Dashboard.currentInstitution
    );
    yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/admin/protocols/${action.id}/forward`,
      action.form
    );

    notification('success', 'Comunicado encaminhado com sucesso');
    yield put({ type: actions.GET_PROTOCOL, id: action.id });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  } finally {
    yield put({ type: actions.FINISHED_LOADING });
  }
}

function* downloadBasicReport(action) {
  try {
    // const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const userType = yield select(state => state.session.user.data.type);
    const institutionId = userType === 'externalUser' ?
      yield select(state => state.Ombudsman.ombudsman.id) :
      yield select(state => state.Dashboard.currentInstitution);
    const reportUrl = userType === 'externalUser' ?
      `${API.url}/institutions/${institutionId}/protocols/${action.id}/report` :
      `${API.url}/institutions/${institutionId}/admin/protocols/${action.id}/basic_report`;
    const blob = yield call(
      downloadApi,
      reportUrl,
    );
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${action.identifier}.pdf`);
    document.body.appendChild(link);
    link.click();

  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  } finally {
    yield put({ type: actions.DOWNLOAD_REPORT_SUCCESS });
  }
}

function *getProtocol(action) {
  try {
    const userType = yield select(state => state.session.user.data.type);
    const institutionId = userType === 'externalUser' ?
      yield select(state => state.Ombudsman.ombudsman.id) :
      yield select(state => state.Dashboard.currentInstitution);

    const url = userType === 'externalUser' ?
      `${API.url}/institutions/${institutionId}/protocols/${action.id}` :
      `${API.url}/institutions/${institutionId}/admin/protocols/${action.id}`;

    const { data, included } = yield call(
      fetchApi,
      url,
    );

    yield put({ type: actions.GET_PROTOCOL_SUCCESS, data, included, userType });
  } catch(error) {
    yield put({
      type: actions.ERROR,
      status: error.response && error.response.status,
      errors: error.response && error.response.data && error.response.data.error,
    });
  }
}

function* createProtocol(action) {
  try {
    const institutionId = yield select(state => state.Ombudsman.ombudsman.id);

    const { data } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/protocols`,
      action.form,
    );

    notification('success', 'Protocolo criado com sucesso!');
    yield put({ type: actions.PROTOCOL_DETAIL, institutionId, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  }
}

function* createProtocolExternalUser(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);

    const { data } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/admin/protocols/${action.id}/create`,
      action.form,
    )
    notification('success', 'Protocolo criado com sucesso!');
    yield put(push(`/ouvidoria/dashboard/protocols/${data.id}/detalhes`));
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      errors: error.response.data.errors,
    });
  }
}

function* fecthMultipleUsersBySector(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);
    const { data } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/sectors/users.json`,
      { sector_ids: action.sector_ids },
    )
    yield put({ type: actions.FETCH_MULTIPLE_USERS_SUCCESS, data });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  }
}

function* listResponsibles() {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);
    const { data } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/users/ombudsman_responsibles.json`,
    )
    yield put({ type: actions.LIST_RESPONSIBLES_SUCCESS, data });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  }
}

function* changeTicket(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);

    const { data, included } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/admin/protocols/${action.id}/change_status`,
      { status: action.status }
    );

    notification('success', 'Status do protocolo alterado com sucesso');
    yield put({ type: actions.CHANGE_STATUS_PROTOCOL_SUCCESS, data, included });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  }
}

function* createReply(action) {
  try {
    const protocolId = action.form.get('reply[protocol_id]');

    const userType = yield select(state => state.session.user.data.type);
    const institutionId = userType === 'externalUser' ?
      yield select(state => state.Ombudsman.ombudsman.id) :
      yield select(state => state.Dashboard.currentInstitution);

    const url = userType === 'externalUser' ?
      `${API.url}/institutions/${institutionId}/external_user/replies` :
      `${API.url}/institutions/${institutionId}/replies`;

    yield call(postApi, url, action.form);

    notification('success', 'Comentário feito com sucesso');

    // yield getProtocol({ id: protocolId });
    yield put({ type: actions.GET_PROTOCOL, id: protocolId });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  // } finally {
    // yield put({ type: actions.RESET_LOADING });
  }
}

function* transferProtocol(action) {
  try {
    const protocolId = action.id;
    const institutionId = yield select(state => state.Dashboard.currentInstitution);
    const url = `${API.url}/institutions/${institutionId}/admin/protocols/${protocolId}/transfer`;

    yield call(postApi, url, action.form);
    yield put({ type: actions.GET_PROTOCOL, id: protocolId });
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  }
}

function* completeProtocol(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);

    const url = `${API.url}/institutions/${institutionId}/admin/protocols/${action.id}/${action.status}`

    yield call(fetchApi, url);

    yield put({ type: actions.GET_PROTOCOL, id: action.id });

    notification('success', 'Protocolo concluído com sucesso');
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  }
}

function* changePriority(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);

    const { data, included } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/admin/protocols/${action.id}/priority`,
      {priority: action.form}
    );

    yield put({ type: actions.GET_PROTOCOL_SUCCESS, data, included });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors: [],
    });
  }
}

function* changeResponsible(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);

    const { data, included } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/admin/protocols/${action.id}/responsible`,
      {responsible_id: action.form}
    );

    yield put({ type: actions.GET_PROTOCOL_SUCCESS, data, included });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors: [],
    });
  }
}

function* statusReport(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution);
    yield put({ type: actions.REPORT_FIELD_SUCCESS, data: [] });

    const { data } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/admin/protocols/status_report.json`,
      action.form,
    );
    yield put({ type: actions.REPORT_FIELD_SUCCESS, data });
  } catch (error) {
    notification('error', 'Erro ao gerar relatório');

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors: [],
    });
  }
}

function* toggleReadReply(action) {
  try {
    const userType = yield select(state => state.session.user.data.type);
    const institutionId = userType === 'externalUser' ?
    yield select(state => state.Ombudsman.ombudsman.id) :
    yield select(state => state.Dashboard.currentInstitution);

    const { data } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/replies/${action.id}/read`,
    );

    yield put({ type: actions.GET_PROTOCOL, id: data.attributes.protocolId });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* extendProtocol(action) {
  try {
    const userType = yield select(state => state.session.user.data.type);
    const institutionId = userType === 'externalUser' ?
      yield select(state => state.Ombudsman.ombudsman.id) :
      yield select(state => state.Dashboard.currentInstitution);

    const url =
      `${API.url}/institutions/${institutionId}/admin/protocols/${action.id}/extend`;

    const { data, included } = yield call(
      fetchApi,
      url,
    );

    notification('success', 'Protocolo prorrogado com sucesso');
    yield put({ type: actions.GET_PROTOCOL_SUCCESS, data, included });
  } catch (error) {
    const {
      response: { data, status },
    } = error;

    const errors =
      (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PROTOCOLS, fetchProtocols),
    takeEvery(actions.GET_PROTOCOL, getProtocol),
    takeEvery(actions.CREATE_PROTOCOL, createProtocol),
    takeEvery(actions.CREATE_PROTOCOL_EXTERNAL_USER, createProtocolExternalUser),
    takeEvery(actions.CREATE_REPLY, createReply),
    takeEvery(actions.CHANGE_STATUS_PROTOCOL, changeTicket),
    takeEvery(actions.FETCH_MULTIPLE_USERS, fecthMultipleUsersBySector),
    takeEvery(actions.TRANSFER_PROTOCOL, transferProtocol),
    takeEvery(actions.COMPLETE_PROTOCOL, completeProtocol),
    takeEvery(actions.EXTEND_PROTOCOL, extendProtocol),
    takeEvery(actions.ANSWER_PROTOCOL, answerProtocol),
    takeEvery(actions.FORWARD_PROTOCOL, forwardProtocol),
    takeEvery(actions.DOWNLOAD_REPORT, downloadReport),
    takeEvery(actions.DOWNLOAD_PDF_REPORT_FIELDS, downloadPdfReportFields),
    takeEvery(actions.DOWNLOAD_XLS_REPORT_FIELDS, downloadXlsReportFields),
    takeEvery(actions.DOWNLOAD_BASIC_REPORT, downloadBasicReport),
    takeEvery(actions.REPORT_FIELDS, statusReport),
    takeEvery(actions.CHANGE_PRIORITY, changePriority),
    takeEvery(actions.CHANGE_RESPONSIBLE, changeResponsible),
    takeEvery(actions.LIST_RESPONSIBLES, listResponsibles),
    takeEvery(actions.DISPATCH_PROTOCOL, dispatchProtocol),
    takeEvery(actions.TOGGLE_READ_REPLY, toggleReadReply),
  ]);
}
