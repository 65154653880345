import { all, call, takeEvery, takeLatest, put } from 'redux-saga/effects';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi, postApi, putApi, stringifyQueryParams } from '../../helpers/api'
import { notification } from '../../components';

const defaultMessages = {
  400: 'Há erros no formulário',
  404: 'Não encontrado',
  500: 'Erro interno. Tente novamente mais tarde',
  403: 'Você não possui acesso',
};

function* fetchNotices(action) {
  try {
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/notices.json?${stringifyQueryParams(action.params)}`,
    );

    yield put({ type: actions.FETCH_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: (error.response && error.response.status) || 400,
      errors: (error.response && error.response.data
        && error.response.data.errors) || ''
    });
  }
}

function* getNotice(action) {
  try {
    const { data, included } = yield call(
      fetchApi,
      `${API.url}/notices/${action.id}`,
    );
      
    yield put({ type: actions.GET_NOTICE_SUCCESS, data, included });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* fetchUnconfirmedNotices() {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/notices/unconfirmed.json`,
    );
    yield put({ type: actions.FETCH_UNCONFIRMED_NOTICES_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: (error.response && error.response.status) || 401,
      errors: (error.response && error.response.data && error.response.data.errors)
        || 'Erro ao carregar memorandos',
    });
  }
}

function* createNotice(action) {
  try {
    const { data } = yield call(
      postApi,
      `${API.url}/notices/`,
      action.form,
    );

    notification('success', 'Comunicado cadastrado com sucesso');
    yield put({ type: actions.CREATE_SUCCESS, data });
  } catch (error) {
    const { response: { data, status } } = error;
    const errors = (data.errors && data.errors.full_messages) || defaultMessages[status];

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  }
}

function* updateNotice(action) {
  try {
    const { data } = yield call(
      putApi,
      `${API.url}/notices/${action.form.id}`,
      action.form,
    );
    notification('success', `Comunicado ${action.form.title} atualizado com sucesso`);
    yield put({ type: actions.GET_NOTICE, id: data.id });
  } catch (error) {
    const { response: { data, status } } = error;
    const errors = (data.errors && data.errors.full_messages) || defaultMessages[status];

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  }
}

function* confirmNotice(action) {
  try {
    yield call(
      fetchApi,
      `${API.url}/notices/${action.id}/confirm`,
    );

    yield put({ type: actions.DISMISS_NOTICE });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* markAsSeen(action) {
  try {
    yield call(
      fetchApi,
      `${API.url}/notices/${action.id}/seen`,
    );

    yield put({ type: actions.DISMISS_NOTICE });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.FETCH_REQUEST, fetchNotices),
    yield takeEvery(actions.GET_NOTICE, getNotice),
    yield takeEvery(actions.CONFIRM_NOTICE, confirmNotice),
    yield takeEvery(actions.NOTICE_SEEN, markAsSeen),
    yield takeEvery(actions.FETCH_UNCONFIRMED_NOTICES, fetchUnconfirmedNotices),
    yield takeEvery(actions.CREATE, createNotice),
    yield takeLatest(actions.UPDATE, updateNotice),
  ])
}
