import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { reset } from 'redux-form';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi, postApi, putApi, stringifyQueryParams } from '../../helpers/api'
import { notification } from '../../components';

function* getQuestionsList() {
  try {
    const institutionId = yield select(state => state.Ombudsman.ombudsman.id)

    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/questions/list`,
    );

    yield put({ type: actions.GET_QUESTIONS_LIST_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* fetchQuestions(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/questions.json?${stringifyQueryParams(action.params)}`,
    );
    yield put({ type: actions.FETCH_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* createQuestion(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/questions/`,
      action.form,
    );
    notification('success', 'Questão cadastrada com sucesso');
    yield put(reset('QuestionForm'));
    yield put({ type: actions.CREATE_SUCCESS, data });
  } catch (error) {
    notification('error', `${error.response.data.errors.full_messages}`);
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages
    });
  }
}

function* updateQuestion(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/questions/${action.form.id}`,
      { ...action.form, institution_id: institutionId },
    );
    notification('success', `Questão ${action.form.title} atualizada com sucesso`);
    yield put({ type: actions.UPDATED_SUCCESS, data });
  } catch (error) {
    notification('error', `${error.response.data.errors.full_messages}`);
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors.full_messages
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_QUESTIONS_LIST, getQuestionsList),
    takeEvery(actions.FETCH_REQUEST, fetchQuestions),
    takeEvery(actions.CREATE, createQuestion),
    takeEvery(actions.UPDATE, updateQuestion),
  ])
}
