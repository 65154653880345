const actions = {
  FETCH_REQUEST: 'FETCH_REQUEST/sectors',
  FETCH_SUCCESS: 'FETCH_SUCCESS/sectors',
  LIST_SECTORS: 'LIST_SECTORS/sectors',
  LIST_SECTORS_SUCCESS: 'LIST_SECTORS_SUCCESS/sectors',
  GET_SUCCESS: 'GET_SUCCESS/sectors',
  GET: 'GET/sectors',
  SET: 'SET/sectors',
  CREATE: 'ADD_ITEM/sectors',
  CREATE_SUCCESS: 'CREATE_SUCCESS/sectors',
  UPDATE: 'UPDATE/sectors',
  UPDATED_SUCCESS: 'UPDATED_SUCCESS/sectors',
  SELECT_CURRENT: 'SELECT_CURRENT/sectors',
  TOGGLE_VIEW: 'TOGGLE_VIEW/sectors',
  EDIT: 'EDIT/sectors',
  CHANGE_STATUS: 'CHANGE_STATUS/sectors',
  OPEN_MODAL_NEW: 'OPEN_MODAL_NEW/sectors',
  TOGGLE_MODAL_NEW: 'TOGGLE_MODAL_NEW/sectors',
  TOGGLE_MODAL_EDIT: 'TOGGLE_MODAL_EDIT/sectors',
  OPEN_MODAL_FILTER: 'OPEN_MODAL_FILTER/sectors',
  TOGGLE_MODAL_FILTER: 'TOGGLE_MODAL_FILTER/sectors',
  SAVE_FILTER_FORM: 'SAVE_FILTER_FORM/sectors',
  ERROR: 'ERROR/sectors',
  fetchRequest: (params) => ({ type: actions.FETCH_REQUEST, params }),
  openModalNew: () => ({ type: actions.OPEN_MODAL_NEW }),
  closeModalNew: () => ({ type: actions.CLOSE_MODAL_NEW }),
  toggleModalNew: () => ({ type: actions.TOGGLE_MODAL_NEW }),
  closeModalEdit: () => ({ type: actions.CLOSE_MODAL_EDIT }),
  toggleModalEdit: () => ({ type: actions.TOGGLE_MODAL_EDIT }),
  openModalFilter: () => ({ type: actions.OPEN_MODAL_FILTER }),
  toggleModalFilter: () => ({ type: actions.TOGGLE_MODAL_FILTER }),
  saveFilter: params => ({ type: actions.SAVE_FILTER_FORM, params }),
  addSector: form => ({ type: actions.CREATE, form }),
  setSector: sector => ({ type: actions.SET, sector }), // Open modal edit
  changeSector: form => ({ type: actions.UPDATE, form }),
  getSector: id => ({ type: actions.GET, id }),
  listSectors: () => ({ type: actions.LIST_SECTORS }),
};

export default actions;
