import { PURGE } from "redux-persist";
import actions from './actions';

const initForm = {
  id: null,
  name: '',
  status: true,
}

const filterForm = {
  name_cont: '',
  status_eq: undefined
};

const initState = {
  subjects: [],
  subjectsList: [],
  hasAssociation: localStorage.getItem('currentMode') !== 'ci_digital',
  sectors: [],
  formValues: initForm,
  filterFormValues: filterForm,
  current: {},
  editable: {},
  pagination: {
    itemsPerPage: 10,
  },
  loading: true,
  modalNew: false,
  modalEdit: false,
  modalFilter: false,
  enableEdit: false,
  errors: null,
};

export default function (state = initState, action) {
  switch (action.type) {
    case actions.FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.FETCH_SUCCESS:
      return {
        ...state,
        subjects: action.data,
        pagination: action.pagination,
        loading: false
      }
    case actions.FETCH_LIST_SUCCESS:
      return {
        ...state,
        subjectsList: action.data,
        loading: false
      }
    case actions.SET:
      return {
        ...state,
        editable: {
          ...action.subject.attributes,
          sector_ids: action.subject.attributes.sectors.map(sector => ({ value: String(sector.id) })),
        },
        modalEdit: true
      }
    case actions.SAVE_FILTER_FORM:
      return {
        ...state,
        filterFormValues: action.params,
      }
    case actions.OPEN_MODAL_NEW:
      return {
        ...state,
        modalNew: true,
      }
    case actions.CLOSE_MODAL_NEW:
      return {
        ...state,
        modalNew: false,
      }
    case actions.TOGGLE_MODAL_NEW:
      return {
        ...state,
        modalNew: !state.modalNew,
      }
    case actions.OPEN_MODAL_FILTER:
      return {
        ...state,
        modalFilter: true,
      }
    case actions.TOGGLE_MODAL_FILTER:
      return {
        ...state,
        modalFilter: !state.modalFilter,
      }
    case actions.TOGGLE_MODAL_EDIT:
      return {
        ...state,
        modalEdit: !state.modalEdit,
      }
    case actions.CLOSE_MODAL_EDIT:
      return {
        ...state,
        editable: {},
        modalEdit: false,
      }
    case actions.CREATE_SUCCESS:
      return {
        ...state,
        subjects: [
          action.data,
          ...state.subjects,
        ],
        formValues: initForm,
      }
    case actions.UPDATED_SUCCESS:
      return {
        ...state,
        subjects: state.subjects.map(subject => subject.id === action.data.id ?
          { ...action.data } :
          subject
        )
      }
    case actions.FETCH_RESOURCES_SUCCESS:
      return {
        ...state,
        sectors: action.sectors,
      };
    case PURGE:
      return initState;
    case actions.FETCH_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      }
    default:
      return state;
  }
}
