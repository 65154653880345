import React, { useCallback } from 'react'
import {
  FormControl,
  FormHelperText,
} from '../../uielements/form'
import DateField from '../../uielements/datePicker';

const RenderDateField = ({
  input,
  label,
  children,
  defaultValue,
  minDate,
  maxDate,

  meta: { touched, error },
  ...custom
}) => {
  const onChange = useCallback(value => input.onChange(value), [input]);

  return (
    <FormControl value={input.value}>
      <DateField
        error={!!(touched && error)}
        label={label}
        onChange={onChange}
        defaultValue={defaultValue}
        minDate={minDate}
        maxDate={maxDate}
        {...input}
        {...custom}
      />
      {touched && error ? <FormHelperText>{error}</FormHelperText> : ''}
    </FormControl>
  );
}

export default RenderDateField;
