/* eslint-disable no-new */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core';
import Cable from 'actioncable';
import SettingsList from '../../../components/utility/customScrollBar';
import {
  IconButtons,
  TopbarDropdown,
  TopbarDropdownWrapper,
} from '../../Topbar/topbarDropdown.style';

import Icon from '../../../components/uielements/icon';
import authAction from '../../../redux/externalAuth/actions';
import dashboardActions from '../../../redux/dashboard/actions';
import notificationActions from '../../../redux/userNotification/actions';

import { SidebarContent, NotificationButton } from '../../Topbar/sidebarNotification.style';
import '../../Topbar/style.scss';

const CableApp = {};

CableApp.cable = Cable.createConsumer(process.env.REACT_APP_WEBSOCKET_URL);

const theme = createMuiTheme({
  overrides: {
    MuiModal: {
      root: {
        zIndex: 1800,
      },
    },
    MuiPopover: {
      paper: {
        maxWidth: 350,
      },
    },
  },
});

const actions = {
  new_ticket: 'Nova CI',
  new_trade: 'Novo Ofício',
  reply: 'Nova resposta',
  change_status: 'Atualização de status',
  finish_ticket: 'Comunicado finalizado',
  confirmed_user: 'Usuário solicitou confirmação',
  transfer_sector: 'Comunicado transferido',
  todo_delayed: 'Tarefa atrasada',
  todo_today: 'Tarefa para hoje!',
  ticket_delayed: 'Comunicado atrasado',
  change_expiration: 'Alteração de vencimento',
  todo_reminder: 'Agenda',
  deadline_created: 'Prorrogação de prazo',
  deadline_answered: 'Prorrogação de prazo',
  ticket_analyzing: 'Análise iniciada',
  protocol_analyzing: 'Análise iniciada',
  first_alarm: '1º Lembrete',
  second_alarm: '2º Lembrete',
  new_protocol: 'Novo Atendimento',
  finish_protocol: 'Atendimento finalizado',
  protocol_delayed: 'Atendimento atrasado',
  import_protocols: 'Atendimentos importados',
  import_users: 'Usuários importados',
  import_sectors: 'Setores importados',
  protocol_extended: 'Atendimento prorrogado',
};

class TopbarNotification extends Component {
  state = {
    visible: false,
    anchorEl: null,
  };

  componentDidMount() {
    const cableApp = CableApp;
    const {
      user,
      updateNotificationWebsocket,
      updateNotificationsWebsocket
    } = this.props;
    cableApp.cable.subscriptions.create({
      channel: 'NotificationChannel',
      user_id: user.id,
      user_type: user.type,
    }, {
      received: ({ notification, notifications }) => {
        if (notification) {
          updateNotificationWebsocket(notification.data);
          this.showNotification(notification.data);
        }

        if (notifications) {
          updateNotificationsWebsocket(notifications.data);
        }
      },
    });
    
  }

  showNotification = (notification) => {
    new Notification(actions[notification.attributes.action], {
      body: notification.attributes.message,
      icon: '/favicon.png',
      vibrate: [200, 100, 200],
    });
  }

  hide = () => {
    this.setState({ visible: false });
  };

  handleVisibleChange = () => {
    const { visible } = this.state;
  
    this.setState({
      visible: !visible,
      anchorEl: findDOMNode(this.button),
    });
  };

  handleClick = (notification) => {

    const { readNotification, slug } = this.props;
    const path = `/ouvidoria/${slug}/protocolo/${notification.attributes.protocolId}/detalhes`;

    readNotification(notification.id, path);
  }

  render() {
    const {
      isLoggedIn,
      user,
      countNotification,
      unReadNotifications,
    } = this.props;
    const { visible } = this.state;

    const content = (
      <TopbarDropdown width="350px">
        { isLoggedIn && user && user.attributes.role !== 'admin' && (
          <SettingsList className="notifications">
            <div className="wrapperIcon">
              <Icon>notifications</Icon>
              {isLoggedIn && user && (
                <span 
                  className="countNotification"
                >
                  {countNotification}
                </span>
              )}
            </div>
          </SettingsList>
        )}
        <SidebarContent className="topbarNotification">
          <SettingsList style={{ height: '100%' }} className="dropdownBody">
            {
              countNotification > 0
              ? (
                unReadNotifications.map(notification => (
                  <NotificationButton
                    disableRipple
                    className={`dropdownListItem status-${notification.attributes.action}`} 
                    key={notification.id}
                    onClick={() => this.handleClick(notification)}
                  >
                    {console.log(notification)}
                    <h5>{actions[notification.attributes.action]}</h5>
                    <p>{notification.attributes.message}</p>
                    <span className="notificationDate">{format(
                      new Date(notification.attributes.createdAt),
                      'dd/MM/yyyy HH:mm',
                    )}</span>
                  </NotificationButton>
                ))
              )
              : (
                <p className="dropdownListItem">
                  <h5>Você não possui nenhuma notificação</h5>
                </p>
              )
            }
          </SettingsList>
        </SidebarContent>
      </TopbarDropdown>
    );

    return (
      <div id="topbarNotificationIcon">
        <IconButtons
          disableRipple
          ref={node => {
            this.button = node;
          }}
          onClick={this.handleVisibleChange}
        >
          <div className="wrapperIcon">
            <Icon style={{ marginTop: 5 }}>
              notifications
            </Icon>
            <span className="countNotification">
              {countNotification}
            </span>
          </div>
        </IconButtons>

        <MuiThemeProvider theme={theme}>
          <TopbarDropdownWrapper
            open={visible}
            anchorEl={this.state.anchorEl}
            onClose={this.hide}
            // marginThreshold={66}
            className="userPopover"
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
          >
            {content}
          </TopbarDropdownWrapper>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default connect(
  state => ({
    countNotification: state.UserNotification.countNotification,
    unReadNotifications: state.UserNotification.unReadNotifications,
    customizedTheme: state.ThemeSwitcher.topbarTheme,
    user: state.session.user.data,
    slug: state.Ombudsman.slug, 
  }),
  (dispatch) => ({
    dispatch,
    logout: authAction.logout,
    clearInstitution: dashboardActions.clearInstitution,
    readNotification: (id, path) => dispatch(notificationActions.readNotification(id, path)),
    updateNotificationWebsocket: data => dispatch(notificationActions.updateNotificationWebsocket(data)),
    updateNotificationsWebsocket: data => dispatch(notificationActions.updateNotificationsWebsocket(data)),
  }),
)(withRouter(TopbarNotification));
