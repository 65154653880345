import styled from 'styled-components';
import { palette } from 'styled-theme';
import Tables from '../uielements/table';
import Papersheet from '../utility/papersheet';
import WithDirection from '../../settings/withDirection';
import Icons from '../uielements/icon';
import Buttons from '../uielements/button';
import Iconbuttons from '../uielements/iconbutton';
import Toolbars from '../uielements/toolbar';

const RestoreBtn = styled(Buttons)``;
const Iconbutton = styled(Iconbuttons)`
  ${'' /* opacity: 0;
  transition: all 0.35s; */};
`;

const AddButton = styled(Buttons)`
  background-color: ${palette('indigo', 5)};
  position: absolute;
  bottom: -28px;
  right: 30px;
  font-weight: 700;

  > span {
    &:last-child {
      span {
        background-color: #ffffff;
      }
    }
  }

  &:hover {
    background-color: ${palette('indigo', 7)};
  }
`;

const Icon = styled(Icons)`
  color: #ffffff;
`;

const DeleteIcon = styled(Icons)`
  font-size: 19px;
  color: ${palette('grey', 6)};
  transition: all 0.25s;

  &:hover {
    color: ${palette('red', 5)};
  }
`;

const Toolbar = styled(Toolbars)`
  min-height: 0;
  min-width: 100%;
  background-color: ${palette('grey', 2)};
  box-sizing: border-box;

  .toolbarContent {
    display: flex;
    width: 100%;
    padding: 10px 0;
    align-items: center;
    justify-content: space-between;
  }
`;

const CardWrapper = styled(Papersheet)`
  width: auto;
  overflow: inherit;
  position: relative;
  h3 {
    font-weight: 700;
  }
`;

const Table = styled(Tables)`

.truncate p {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th {
    font-size: 16px;
    color: ${palette('grey', 8)};
    font-weight: 700;
    white-space: nowrap;
    background: #f1f3f6;

    &:last-child {
      padding: 4px 10px;
    }
    &:first-child {
      padding: 20px;
    }
  }

  .MuiTableCell-paddingCheckbox {
      padding: 20px;
  }

  .todoCompleted {
    text-decoration: line-through;
  }

  tr {
    td {
      color: ${palette('grey', 6)};
      padding-right: 24px;
      white-space: break-spaces;
      &:last-child {
        padding: 4px 10px;
      }
      &:first-child {
        padding: 4px 10px;
      }
      a {
        text-decoration: none;
      }
      ${'' /* .mateInvoiceView,
      .mateInvoiceDlt {
        opacity: 0;
        @media (max-width: 767px) {
          opacity: 1;
        }
      } */};
    }
    ${'' /* &:hover {
      .mateInvoiceView,
      .mateInvoiceDlt {
        opacity: 1;
      }
    } */};
  }
`;

const ActionButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${'' /* margin-top: 20px; */} position: relative;

  .tableBtn {
    display: flex;
    margin-top: 28px;
    margin-bottom: 20px;
    width: 100%;
    a {
      margin-left: auto;
      text-decoration: none;
    }
  }
`;

export {
  Table,
  Icon,
  AddButton,
  RestoreBtn,
  DeleteIcon,
  Iconbutton,
  Toolbar,
  ActionButtons,
};
export default WithDirection(CardWrapper);
