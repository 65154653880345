import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi, postApi, putApi, stringifyQueryParams } from '../../helpers/api'

function* fetchBranchActivities(action) {
  try {
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/branch_activities.json?${stringifyQueryParams(action.params)}`,
      );

    yield put({ type: actions.GET_BRANCH_ACTIVITIES_SUCCESS, branchActivities: data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* fetchBranchActivitiesList() {
  try {
    const { data } = yield call(
      fetchApi,
      `${API.url}/branch_activities/list.json`,
      );

    yield put({ type: actions.GET_BRANCH_ACTIVITIES_LIST_SUCCESS, branchList: data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* createBranchActivity(action) {
  try {
    yield call(postApi, `${API.url}/branch_activities.json`, action.form);
    yield put({ type: actions.GET_BRANCH_ACTIVITIES });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error && error.response && error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

function* editBranchActivity(action) {
  try {
    yield call(putApi, `${API.url}/branch_activities/${action.id}.json`, action.form);
    yield put({ type: actions.GET_BRANCH_ACTIVITIES });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error && error.response && error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_BRANCH_ACTIVITIES, fetchBranchActivities),
    takeEvery(actions.GET_BRANCH_ACTIVITIES_LIST, fetchBranchActivitiesList),
    takeEvery(actions.CREATE_BRANCH_ACTIVITY, createBranchActivity),
    takeEvery(actions.UPDATE_BRANCH_ACTIVITY, editBranchActivity),
  ]);
}
