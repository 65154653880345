const actions = {
  FETCH_USERS_REQUEST: 'FETCH_USERS_REQUEST/backup',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS/backup',
  CI_REQUEST: 'CI_REQUEST/backup',
  CI_SUCCESS: 'CI_SUCCESS/backup',
  REPLIES_REQUEST: 'REPLIES_REQUEST/backup',
  REPLIES_SUCCESS: 'REPLIES_SUCCESS/backup',
  ERROR: 'ERROR/backup',
  fetchUserRequest: filetype => ({ type: actions.FETCH_USERS_REQUEST, filetype }),
  ticketsRequest: filetype => ({ type: actions.CI_REQUEST, filetype }),
  repliesRequest: filetype => ({ type: actions.REPLIES_REQUEST, filetype }),
};

export default actions;
