import { all, call, takeEvery, takeLatest, put, select } from 'redux-saga/effects';
import actions from './actions';
import { API } from '../../settings';
import { fetchApi, postApi, putApi, stringifyQueryParams } from '../../helpers/api'
import { notification } from '../../components';

const defaultMessages = {
  400: 'Há erros no formulário',
  404: 'Não encontrado',
  500: 'Erro interno. Tente novamente mais tarde',
  403: 'Você não possui acesso',
};

function* fetchAnnouncements(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data, pagination } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/announcements.json?${stringifyQueryParams(action.params)}`,
    );

    yield put({ type: actions.FETCH_SUCCESS, data, pagination });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: (error.response && error.response.status) || 400,
      errors: (error.response && error.response.data
        && error.response.data.errors) || ''
    });
  }
}

function* listAnnouncements() {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/announcements/received`,
    );

    yield put({ type: actions.FETCH_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: (error.response && error.response.status) || 400,
      errors: (error.response && error.response.data
        && error.response.data.errors) || ''
    });
  }
}

function* getAnnouncement(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data, included } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/announcements/${action.id}`,
    );

    yield put({ type: actions.GET_ANNOUNCEMENT_SUCCESS, data, included });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}


function* announcementDetail(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/announcements/${action.id}/detail`,
    );

    yield put({ type: actions.OPEN_MODAL_DETAIL_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* announcementComment(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/announcements/${action.id}/comment`,
      action.form,
    );

    yield put({ type: actions.OPEN_MODAL_DETAIL_SUCCESS, data });
    yield put({ type: actions.LIST });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* commentLike(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/announcements/${action.id}/like`,
      action.form,
    );

    yield put({ type: actions.OPEN_MODAL_DETAIL_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* announcementLike(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/announcements/${action.id}/like`,
    );

    yield put({ type: actions.LIST });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* fetchUnconfirmedAnnouncements() {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/announcements/unconfirmed.json`,
    );

    yield put({ type: actions.FETCH_UNCONFIRMED_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: (error.response && error.response.status) || 401,
      errors: (error.response && error.response.data && error.response.data.errors)
        || 'Erro ao carregar memorandos',
    });
  }
}

function* createAnnouncement(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      postApi,
      `${API.url}/institutions/${institutionId}/announcements/`,
      action.form,
    );

    notification('success', 'Comunicado cadastrado com sucesso');
    yield put({ type: actions.CREATE_SUCCESS, data });
  } catch (error) {
    const { response: { data, status } } = error;
    const errors = (data.errors && data.errors.full_messages) || defaultMessages[status];

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  }
}

function* updateAnnouncement(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/announcements/${action.form.id}`,
      action.form,
    );
    notification('success', `Comunicado ${action.form.title} atualizado com sucesso`);
    yield put({ type: actions.GET_ANNOUNCEMENT, id: data.id });
  } catch (error) {
    const { response: { data, status } } = error;
    const errors = (data.errors && data.errors.full_messages) || defaultMessages[status];

    yield put({
      type: actions.ERROR,
      errorStatus: error.response.status,
      errors,
    });
  }
}

function* confirmAnnouncement(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    const { data } = yield call(
      putApi,
      `${API.url}/institutions/${institutionId}/announcements/${action.id}/confirm`,
    );

    yield put({ type: actions.DISMISS_ANNOUNCEMENT, data });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors
    });
  }
}

function* markAsSeen(action) {
  try {
    const institutionId = yield select(state => state.Dashboard.currentInstitution)
    yield call(
      fetchApi,
      `${API.url}/institutions/${institutionId}/announcements/${action.id}/seen`,
    );

    yield put({ type: actions.DISMISS_ANNOUNCEMENT });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      status: error.response.status,
      error_message: error.response.data.errors,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.FETCH_REQUEST, fetchAnnouncements),
    yield takeEvery(actions.GET_ANNOUNCEMENT, getAnnouncement),
    yield takeEvery(actions.CONFIRM_ANNOUNCEMENT, confirmAnnouncement),
    yield takeEvery(actions.ANNOUNCEMENT_SEEN, markAsSeen),
    yield takeEvery(actions.FETCH_UNCONFIRMED_REQUEST, fetchUnconfirmedAnnouncements),
    yield takeEvery(actions.CREATE, createAnnouncement),
    yield takeLatest(actions.UPDATE, updateAnnouncement),
    yield takeEvery(actions.OPEN_MODAL_DETAIL, announcementDetail),
    yield takeEvery(actions.CREATE_COMMENT, announcementComment),
    yield takeEvery(actions.LIKE_COMMENT, commentLike),
    yield takeEvery(actions.LIKE_ANNOUNCEMENT, announcementLike),
    yield takeEvery(actions.LIST, listAnnouncements),
  ])
}
