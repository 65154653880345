const actions = {
  FIND_REQUEST: 'FIND_REQUEST/search',
  FIND_SUCCESS: 'FIND_SUCCESS/search',
  TOGGLE_MODAL_PROTOCOL: 'TOGGLE_MODAL_PROTOCOL/search',
  ERROR: 'ERROR/searchProtocol',
  findProtocol: form => ({
    type: actions.FIND_REQUEST,
    form
  }),
  toogleSearchModal: () => ({type: actions.TOGGLE_MODAL_PROTOCOL})
};
export default actions;
