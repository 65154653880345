const roleOptions = {
  admin: 'Administrador',
  data_manager: 'Gestor RH',
  master_one: 'Acesso Master',
  sector_manager_one: 'Chefe de setor',
  sector_manager_two: 'Auxiliar',
  auditor: 'Auditor',
};

export default roleOptions;
