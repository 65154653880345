import actions from '../../auth/actions';
import { notification } from '../../../components';

const errorInterceptor = ({ dispatch }) => next => action => {
  if (action.status === 401) {
    notification('error', 'Faça o login');
    dispatch(actions.logout());
    localStorage.removeItem('institutionId');
    setTimeout(() => window.location.reload(), 500);
    // window.location.reload();
  } else if (action.status === 403) {
    dispatch({
      type: actions.USER_UNAUTHORIZED,
    });
    notification(
      'error',
      'Você não possui autorização para acessar esta página'
    );
  } else if (action.status === 404) {
    dispatch({
      type: actions.USER_UNAUTHORIZED,
    });
  } else if (action.status === 500) {
    notification('error', 'Erro no servidor');
  }

  next(action);
};

export default errorInterceptor;
