const actions = {
  GET_QUESTIONS_LIST: 'GET_QUESTIONS_LIST/questions',
  GET_QUESTIONS_LIST_SUCCESS: 'GET_QUESTIONS_LIST_SUCCESS/questions',
  FETCH_REQUEST: 'FETCH_REQUEST/questions',
  FETCH_SUCCESS: 'FETCH_SUCCESS/questions',
  FETCH_RESOURCES: 'FETCH_RESOURCES/questions',
  FETCH_RESOURCES_SUCCESS: 'FETCH_RESOURCES_SUCCESS/questions',
  GET: 'GET/questions',
  SET: 'SET/questions',
  CREATE: 'ADD_ITEM/questions',
  CREATE_SUCCESS: 'CREATE_SUCCESS/questions',
  UPDATE: 'UPDATE/questions',
  UPDATED_SUCCESS: 'UPDATED_SUCCESS/questions',
  SELECT_CURRENT: 'SELECT_CURRENT/questions',
  TOGGLE_VIEW: 'TOGGLE_VIEW/questions',
  EDIT: 'EDIT/questions',
  CHANGE_STATUS: 'CHANGE_STATUS/questions',
  TOGGLE_MODAL_NEW: 'TOGGLE_MODAL_NEW/questions',
  TOGGLE_MODAL_EDIT: 'TOGGLE_MODAL_EDIT/questions',
  TOGGLE_MODAL_FILTER: 'TOGGLE_MODAL_FILTER/questions',
  SAVE_FILTER_FORM: 'SAVE_FILTER_FORM/questions',
  ERROR: 'ERROR/questions',
  getQuestions: () => ({ type: actions.GET_QUESTIONS_LIST }),
  fetchRequest: params => ({ type: actions.FETCH_REQUEST, params }),
  fetchResources: institutionId => ({ type: actions.FETCH_RESOURCES, institutionId }),
  openModalNew: () => ({ type: actions.OPEN_MODAL_NEW }),
  closeModalNew: () => ({ type: actions.CLOSE_MODAL_NEW }),
  toggleModalNew: () => ({ type: actions.TOGGLE_MODAL_NEW }),
  openModalFilter: () => ({ type: actions.OPEN_MODAL_FILTER }),
  toggleModalFilter: () => ({ type: actions.TOGGLE_MODAL_FILTER }),
  saveFilter: params => ({ type: actions.SAVE_FILTER_FORM, params }),
  closeModalEdit: () => ({ type: actions.CLOSE_MODAL_EDIT }),
  toggleModalEdit: () => ({ type: actions.TOGGLE_MODAL_EDIT }),
  addQuestion: form => ({ type: actions.CREATE, form }),
  setQuestion: question => ({ type: actions.SET, question }), // Open modal edit
  changeQuestion: form => ({ type: actions.UPDATE, form }),
};

export default actions;
