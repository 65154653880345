import React, { useEffect } from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  useTheme,
  Drawer,
  AppBar,
  Toolbar,
  CssBaseline,
  Divider,
  IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { TopbarComponents } from '../Topbar/style';
import notificationActions from '../../redux/userNotification/actions';
import Logo from '../../images/ci-logo.png';
import themeActions from "../../redux/themeSwitcher/actions";
import TopbarUserNotification from '../Topbar/topbarNotification';
import TopbarUser from "../Topbar/topbarUser";
import Sidebar from '../Sidebar';

const { switchActivation } = themeActions;

const drawerWidth = 62;
  
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - 62px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 15,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
    },
  },
  header: {
    backgroundColor: 'white',
    boxShadow: '0 1px 2px rgba(0,0,0,0.35)',
    transition: 'all 0.2s ease-in-out',
    height: '65px',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    backgroundColor: '#2B2B2B',
    color: 'white',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarTitle: {
    textAlign: 'center',
    width: '-webkit-fill-available',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Navigation = props => {
  const isMobile = window.innerWidth <= 767;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const {
    isLoggedIn,
    user,
    fetchUnReadRequest,
    url,
  } = props;

  const options = { url, classes, theme };

  const handleDrawer = () => {
    if (isMobile && !open){
      setOpen(true)
    } else if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  const propsTopbar = { url };
  useEffect(() => {
    fetchUnReadRequest()
  },[fetchUnReadRequest])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.header}>
          { !open && isMobile && (
          <IconButton
            color="inherit"
            aria-label="Abrir sidebar"
            onClick={handleDrawer}
            edge="start"
          >
            <MenuIcon color="primary" />
          </IconButton>
          )}
          {isLoggedIn && user && (
          <Link to="/dashboard">
            <img 
              height={isMobile ? 40 : 65 }
              src={user.attributes.institution.data
                  ? user.attributes.institution.data.attributes.logo.url
                  : Logo} 
              alt="Voltar para dashboard"
            />
          </Link>
        )}
        <TopbarComponents>
          <ul className="topbarItems">
            {
              isLoggedIn && user && user.attributes.role !== 'admin' && (
                <li className="topbarNotification">
                  <TopbarUserNotification { ...propsTopbar } />
                </li>
              )
            }
            <li className="topbarUser">
              <TopbarUser {...propsTopbar} />
            </li>
          </ul>
        </TopbarComponents>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawer}>
            { isMobile && (
              <ChevronLeftIcon style={{ color: 'white' }} />
            )}
          </IconButton>
        </div>
        <Divider />
        <Sidebar {...options} />
      </Drawer>
    </div>
  );
}

export default connect(
  state => ({
    ...state.App,
    isLoggedIn: state.session.authenticated,
    user: state.session.user.data,
    countNotification: state.UserNotification.countNotification,
  }),
  {
    switchActivation,
    fetchUnReadRequest: notificationActions.fetchUnReadRequest,
  }
)(Navigation);

