const actions = {
  FETCH_REQUEST: 'FETCH_REQUEST/announcements',
  FETCH_SUCCESS: 'FETCH_SUCCESS/announcements',
  FETCH_UNCONFIRMED_REQUEST: 'FETCH_UNCONFIRMED_REQUEST/announcements',
  FETCH_UNCONFIRMED_SUCCESS: 'FETCH_UNCONFIRMED_SUCCESS/announcements',
  CONFIRM_ANNOUNCEMENT: 'CONFIRM_ANNOUNCEMENT/announcements',
  CONFIRM_ANNOUNCEMENT_SUCCESS: 'CONFIRM_ANNOUNCEMENT/announcements',
  ANNOUNCEMENT_SEEN: 'ANNOUNCEMENT_SEEN/announcements',
  GET_ANNOUNCEMENT: 'GET_ANNOUNCEMENT/announcements',
  GET_ANNOUNCEMENT_SUCCESS: 'GET_ANNOUNCEMENT_SUCCESS/announcements',
  DISMISS_ANNOUNCEMENT: 'DISMISS_ANNOUNCEMENT/announcements',
  GET: 'GET/announcements',
  SET: 'SET/announcements',
  CREATE: 'ADD_ITEM/announcements',
  CREATE_SUCCESS: 'CREATE_SUCCESS/announcements',
  UPDATE: 'UPDATE/announcements',
  UPDATED_SUCCESS: 'UPDATED_SUCCESS/announcements',
  SELECT_CURRENT: 'SELECT_CURRENT/announcements',
  TOGGLE_VIEW: 'TOGGLE_VIEW/announcements',
  EDIT: 'EDIT/announcements',
  CHANGE_STATUS: 'CHANGE_STATUS/announcements',
  OPEN_MODAL_NEW: 'OPEN_MODAL_NEW/announcements',
  TOGGLE_MODAL_NEW: 'TOGGLE_MODAL_NEW/announcements',
  TOGGLE_MODAL_EDIT: 'TOGGLE_MODAL_EDIT/announcements',
  TOGGLE_MODAL_FILTER: 'TOGGLE_MODAL_FILTER/announcements',
  TOGGLE_MODAL_WHO_LIKED: 'TOGGLE_MODAL_WHO_LIKED/announcements',
  SAVE_FILTER_FORM: 'SAVE_FILTER_FORM/announcements',
  OPEN_MODAL_DETAIL: 'OPEN_MODAL_DETAIL/announcements',
  OPEN_MODAL_DETAIL_SUCCESS: 'OPEN_MODAL_DETAIL_SUCCESS/announcements',
  CLOSE_MODAL_DETAIL: 'CLOSE_MODAL_DETAIL/announcements',
  CREATE_COMMENT: 'CREATE_COMMENT/announcements',
  LIKE_COMMENT: 'LIKE_COMMENT/announcements',
  LIKE_ANNOUNCEMENT: 'LIKE_ANNOUNCEMENT/announcements',
  LIST: 'LIST/announcements',
  ERROR: 'ERROR/announcements',
  fetchRequest: params => ({ type: actions.FETCH_REQUEST, params }),
  getAnnouncement: id => ({ type: actions.GET_ANNOUNCEMENT, id }),
  fetchAnnouncementList: () => ({ type: actions.LIST }),
  fetchUnconfirmedRequest: () => ({ type: actions.FETCH_UNCONFIRMED_REQUEST }),
  confirmAnnouncement: id => ({ type: actions.CONFIRM_ANNOUNCEMENT, id }),
  dismissAnnouncement: () => ({ type: actions.DISMISS_ANNOUNCEMENT }),
  toggleModalFilter: () => ({ type: actions.TOGGLE_MODAL_FILTER }),
  saveFilter: params => ({ type: actions.SAVE_FILTER_FORM, params }),
  openModalNew: () => ({ type: actions.OPEN_MODAL_NEW }),
  closeModalNew: () => ({ type: actions.CLOSE_MODAL_NEW }),
  toggleModalNew: () => ({ type: actions.TOGGLE_MODAL_NEW }),
  closeModalEdit: () => ({ type: actions.CLOSE_MODAL_EDIT }),
  toggleModalEdit: () => ({ type: actions.TOGGLE_MODAL_EDIT }),
  addAnnouncement: form => ({ type: actions.CREATE, form }),
  setAnnouncement: announcement => ({ type: actions.SET, announcement }), // Open modal edit
  changeAnnouncement: form => ({ type: actions.UPDATE, form }),
  markAsSeen: (id) => ({ type: actions.ANNOUNCEMENT_SEEN, id }),
  openModalDetail: id => ({ type: actions.OPEN_MODAL_DETAIL, id }),
  closeModalDetail: () => ({ type: actions.CLOSE_MODAL_DETAIL }),
  createComment: (id, form) => ({ type: actions.CREATE_COMMENT, id, form }),
  likeComment: (id, form) => ({ type: actions.LIKE_COMMENT, id, form }),
  likeAnnouncement: (id) => ({ type: actions.LIKE_ANNOUNCEMENT, id }),
  toggleModalWhoLiked: (announcement) => ({ type: actions.TOGGLE_MODAL_WHO_LIKED, announcement }),
};

export default actions;
