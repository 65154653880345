const actions = {
  FETCH_UNREAD_REQUEST: 'FETCH_UNREAD_REQUEST/notifications',
  FETCH_UNREAD_REQUEST_SUCCESS: 'FETCH_UNREAD_REQUEST_SUCCESS/notifications',
  FETCH_REQUEST: 'FETCH_REQUEST/notifications',
  FETCH_SUCCESS: 'FETCH_SUCCESS/notifications',
  READ_NOTIFICATION: 'READ_NOTIFICATION',
  READ_NOTIFICATION_SUCCESS: 'READ_NOTIFICATION_SUCCESS/notifications',
  CHECK_READ_REQUEST: 'CHECK_READ_REQUEST/notifications/notifications',
  CHECK_READ_REQUEST_SUCCESS: 'CHECK_READ_REQUEST_SUCCESS/notifications',
  UPDATED_NOTIFICATION_SUCCESS: 'UPDATED_NOTIFICATION_SUCCESS/notifications',
  UPDATE_NOTIFICATION_WEBSOCKET: 'UPDATE_NOTIFICATION_WEBSOCKET/notifications',
  UPDATE_NOTIFICATIONS_WEBSOCKET: 'UPDATE_NOTIFICATIONS_WEBSOCKET/notifications',
  ERROR_NOTIFICATION: 'ERROR/notifications',
  fetchUnReadRequest: () => ({ type: actions.FETCH_UNREAD_REQUEST }),
  fetchRequest: params => ({ type: actions.FETCH_REQUEST, params }),
  readNotification: (id, path) => ({ type: actions.READ_NOTIFICATION, id, path }),
  checkReadNotification: (id, form) => ({ type: actions.CHECK_READ_REQUEST, id, form }),
  updateNotificationWebsocket: (notification) => ({ type: actions.UPDATE_NOTIFICATION_WEBSOCKET, notification }),
  updateNotificationsWebsocket: (notifications) => ({ type: actions.UPDATE_NOTIFICATIONS_WEBSOCKET, notifications }),
};

export default actions;
