import { call, all, takeEvery, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { sessionService } from 'redux-react-session';
import { reset } from 'redux-form';
// import Cookies from "js-cookie";
import { API } from '../../settings';
import { postApi, putApi, fetchApi } from '../../helpers/api';
import { notification } from '../../components';
import { getToken, clearToken } from '../../helpers/utility';
import setAuthorizationToken from '../../helpers/setAuthorizationToken';
import actions from './actions';

const defaultMessages = {
  400: 'Há erros no formulário',
  404: 'Não encontrado',
  500: 'Erro interno. Tente novamente mais tarde',
  403: 'Você não possui acesso',
};

export function* loginRequest({ payload }) {
  try {
    const { data } = yield call(
      postApi,
      `${API.url}/external_users/sign_in`,
      payload,
      {},
      { slug: localStorage.getItem('slug') }
    );

    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: {
        token: data.attributes.authToken,
        redirectTo: payload.redirectTo,
      },
      profile: data.attributes.user,
    });
  } catch (error) {
    const {
      response: {
        data: {
          errors = { default: 'Não foi possível realizar login. Tente atualizar a página' },
        } = {},
      } = {},
    } = error;
    const messages = Object.values(errors)
    notification('error', messages[0]);
    yield put({ type: actions.LOGIN_ERROR });
  }
}

export function* loginSuccess({ payload, profile }) {
  yield localStorage.setItem('id_token', payload.token);
  yield setAuthorizationToken(payload.token);
  yield sessionService.saveSession(payload.token);
  yield sessionService.saveUser(profile);
  if (payload.redirectTo) {
    yield put(push(payload.redirectTo));
  } else {
    yield put(push(`/ouvidoria/${localStorage.getItem('slug')}/protocolo`));
  }
}

export function* signUp(action) {
  try {
    const { data } = yield call(
      postApi,
      `${API.url}/external_users`,
      { sign_up: action.form },
      undefined,
      { slug: localStorage.getItem('slug') }
    );

    const message = (data && data.message) || 'E-mail de confirmação enviado'
    notification('success', message);
    yield put(reset('OuvidoriaForm'));
  } catch (error) {
    const { response: { data } = {} } = error;
    const messages = Object.entries(data);
    notification('error', messages[0].join(' '));
    yield put({ type: actions.LOGIN_ERROR });
  }
}

export function* logout(action) {
  yield clearToken();
  yield sessionService.invalidateSession()
  yield sessionService.deleteSession();
  yield sessionService.deleteUser();
  localStorage.removeItem('institutionId');
  if (action.redirectTo) {
    yield put(push(action.redirectTo));
  } else {
    yield put(push(`/ouvidoria/${localStorage.getItem('slug')}`));
  }
}

export function* checkAuthorization() {
  const token = getToken();
  if (token) {
    yield setAuthorizationToken(token);
  }
}

export function* forgotPassword({ payload }) {
  try {
    const { data } = yield call(postApi, `${API.url}/external_users/password`, payload);

    yield put({
      type: actions.FORGOT_PASSWORD_SUCCESS,
      message: data,
    });
    notification('success', data.message);
  } catch (error) {
    notification('error', error.response.data.data.message);

    yield put({ type: actions.LOGIN_ERROR });
  }
}

export function* resetPassword(action) {
  try {
    const { data } = yield call(
      putApi,
      `${API.url}/external_users/password?token=${action.token}`,
      action.form
    );

    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: { token: data.attributes.authToken },
      profile: data.attributes.user,
    });
  } catch (error) {
    const { response: { data, status } } = error;
    const errors = (data.errors && data.errors.full_messages) || defaultMessages[status];
    notification('error', errors);

    yield put({ type: actions.LOGIN_ERROR });
  }
}

function* confirmAccount(action) {
  try {
    yield call(
      fetchApi,
      `${API.url}/external_users/confirmation?confirmation_token=${action.token}`,
    );
    yield put(push(`/ouvidoria/${localStorage.getItem('slug')}?confirmed=true`));
  } catch (error) {
    notification('error', error.response.data.errors.full_messages);
    yield put({ type: actions.ERROR });
  }
}

function* userUnauthorized() {
  yield put(push(`/ouvidoria/${localStorage.getItem('slug')}`));
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHECK_AUTHORIZATION, checkAuthorization),
    takeEvery(actions.USER_UNAUTHORIZED, userUnauthorized),
    takeEvery(actions.LOGIN_REQUEST, loginRequest),
    takeEvery(actions.LOGIN_SUCCESS, loginSuccess),
    takeEvery(actions.LOGOUT, logout),
    takeEvery(actions.FORGOT_PASSWORD_REQUEST, forgotPassword),
    takeEvery(actions.RESET_PASSWORD_REQUEST, resetPassword),
    takeEvery(actions.SIGN_UP_REQUEST, signUp),
    takeEvery(actions.CONFIRMATION_ACCOUNT_REQUEST, confirmAccount),
  ]);
}
